import { ChevronDownIcon } from '@heroicons/react/outline'
import React, { useEffect, useRef, useState } from 'react'
import { useOutsideClick } from '../../firebase'

function SelectionMenu({ options, selectedOption, handleSelect, placeholder, optionsVisible, setOptionsVisible }) {
    
    // const [optionsVisible, setOptionsVisible] = useState(false)
    
    const dropdownRef = useRef()
    useOutsideClick(dropdownRef, () => setOptionsVisible(false))
    
    return (
        <div ref={dropdownRef}>
            <button className='border py-1 px-2 rounded-lg flex justify-between items-center' onClick={() => setOptionsVisible(prev => !prev)}>      
                <span className='w-[150px]'>{selectedOption ? selectedOption.id : placeholder}</span>
                <ChevronDownIcon className='h-6 w-6 inline mx-1 shrink-0 grow-0' />
            </button>
            <div className={optionsVisible ? 'block' : 'hidden'}>
                <div className='absolute bg-white rounded-b-lg shadow-lg w-[100%] px-1 max-h-96 overflow-y-auto'>
                {options.map((option, index) => (
                    <button key={option.id} onClick={() => handleSelect(option)} className={"doc-btn " + (selectedOption?.id === option?.id ? 'bg-neutral-600 text-white' : '')}>
                        <span className='w-[150px]'>{option?.id}</span> 
                    </button>
                ))}
                </div>
            </div>
        </div>
    )
}

export default SelectionMenu