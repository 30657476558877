import { DocumentIcon, PlusSmIcon } from '@heroicons/react/outline';
import { initializeApp } from 'firebase/app';
import { createUserWithEmailAndPassword, getAuth, signOut } from 'firebase/auth';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useRef, useState } from 'react'
import { animateButton, db, useOutsideClick } from '../../firebase';

import axios from 'axios'
import AutomaticAdding from './AutomaticAdding';



function AddUser({ selectedCompany }) {

    const [title, setTitle] = useState("")
    const [password, setPassword] = useState("")
    const [email, setEmail] = useState("")
    
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    // const [name, setName] = useState("")

    const [userAdded, setUserAdded] = useState()

    const [mode, setMode] = useState("manual")

    const [progress, setProgress] = useState(0)

    const [autoUsers, setAutoUsers] = useState([])
    const [rolesVisible, setRolesVisible] = useState(false)

    const rolesRef = useRef()
    useOutsideClick(rolesRef, () => setRolesVisible(false))

    
    /// Adding new User to exisiting Client ///

    async function addUserManual() {
        var config = {
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        };

        var secondaryApp = initializeApp(config, "Secondary-Documentation-Tool");

        const newUserAuth = getAuth(secondaryApp)
        const userCredential = await createUserWithEmailAndPassword(newUserAuth, email, password)
        const newAuth = await getAuth(secondaryApp)
        await signOut(newAuth)

        await updateDoc(doc(db, "user_data", selectedCompany.id), {
            ["roles." + userCredential.user.uid] : "editor"
        })

        await setDoc(doc(db, "user_data", selectedCompany.id, "users", userCredential.user.uid), {
            firstName,
            lastName,
            title, 
            email
        })

        axios.post("https://tax-mind-tools.de/registration-email", {
            users: [{
                firstName,
                lastName,
                email,
                password
            }]
        })

        animateButton(setUserAdded, 2000)
    }


    // Auto Adding



    async function addUsersAuto() {

        setProgress(0)

        // Init secondary app for registering new users

        var config = {
            apiKey: process.env.REACT_APP_API_KEY,
            authDomain: process.env.REACT_APP_AUTH_DOMAIN,
        };

        var secondaryApp = initializeApp(config, "Secondary-Documentation-Tool");
        const newAuth = getAuth(secondaryApp)

        // Get years for subsidiary classification
        console.log("Getting Document Types")
        const years = await getDocs(collection(db, "user_data", selectedCompany.id, "years"))
        var counter = 0

        for (const user of autoUsers) {

            // Creating User
            console.log("Creating User...")
            console.log(user["Email"])
            console.log(user["Passwort"])
            const userCredential = await createUserWithEmailAndPassword(newAuth, user["Email"], user["Passwort"])
            

            // Creating User Document
            console.log("Creating User Document...")
            await setDoc(doc(db, "user_data", selectedCompany.id, "users", userCredential.user.uid), {
                firstName: user["Vorname"],
                lastName: user["Nachname"],
                title: user["Rolle"],
                email: user["Email"]
            })
            

            // Client Classification
            console.log("Setting Up Client Classification...")
            if (user["Rolle"] === "Super-editor") {
                await updateDoc(doc(db, "user_data", selectedCompany.id), {
                    ["roles." + userCredential.user.uid] : "super-user"
                })

                await updateDoc(doc(db, "user_data", selectedCompany.id), {
                    ["superEditing." + userCredential.user.uid] : true
                })
            } else if (user["Rolle"] === "Super-user") {
                await updateDoc(doc(db, "user_data", selectedCompany.id), {
                    ["roles." + userCredential.user.uid] : "super-user"
                })
            } else {
                await updateDoc(doc(db, "user_data", selectedCompany.id), {
                    ["roles." + userCredential.user.uid] : "editor"
                })

                // Document Classification
                
                for (const subsidiary of user["Zuweisung"].split(",")) {
                    for (const year of years.docs.map(yearDoc => yearDoc.id)) {
                        try {
                            await updateDoc(doc(db, "user_data", selectedCompany.id, "years", year, "subsidaries", subsidiary), {
                                ["roles." + userCredential.user.uid] : "editor"
                            })
                        } catch {
                            console.log("Error: " + year + "/" + subsidiary + " not found")
                        }

                    }                
                }
            }

            counter++;
            setProgress(Math.round(counter / autoUsers.length * 100))
        }

        await signOut(newAuth)

        axios.post("https://tax-mind-tools.de/registration-email", {
            users: autoUsers.map((user, index) => ({
                firstName: user["Vorname"],
                lastName: user["Nachname"],
                email: user["Email"],
                password: user["Passwort"]
            }))
        })
    }





    /// HTML Component ///

    return (
        <div className='component-container w-[100%] lg:w-[75%]'>
            <div className='component-icon-container bg-indigo-400'>
                <PlusSmIcon className='w-8 h-8' /> 
            </div>
            <p className='text-2xl p-1 m-1'>Create User</p>
            <div className='mt-3'>
                <div className='flex px-2 mb-1'>
                    <button onClick={() => setMode("manual")} className={'w-[50%] transition-all border border-indigo-400 rounded-lg p-1 mr-1 ' + (mode === "manual" ? "bg-indigo-400 text-white" : " text-indigo-400")}>Manual</button>
                    <button onClick={() => setMode("auto")} className={'w-[50%] transition-all border border-indigo-400 rounded-lg p-1 ' + (mode === "auto" ? "bg-indigo-400 text-white" : " text-indigo-400")}>Automatic</button>
                </div>
                {mode === "manual" &&
                    <div className='mb-2 px-2'>
                        <div className='flex'>
                            <input value={firstName} onChange={(e) => setFirstName(e.target.value)} className='add-user-input w-[50%] mr-1' placeholder='First Name ' />
                            <input value={lastName} onChange={(e) => setLastName(e.target.value)} className='add-user-input w-[50%]' placeholder='Last Name' />
                        </div>
                        {/* <input className='add-user-input' onChange={((e) => setName(e.target.value))} value={name} placeholder="First Name Last Name" /> */}
                        <div ref={rolesRef} className='relative'>
                            <button className={'border rounded-lg p-1 w-[100%] ' + (title !== "" ? "text-black" : "text-neutral-400")} onClick={() => setRolesVisible(prev => !prev)}>{title !== "" ? title : "Authorization"}</button> 
                            <div className={'absolute z-10 flex flex-col w-[100%] shadow-basic bg-white rounded-lg mt-1 p-1 pb-0 ' + (rolesVisible ? "" : "hidden")}>
                                <button onClick={() => {setTitle("single-user"); setRolesVisible(false)}} className='p-1 mb-1 hover:bg-indigo-400 hover:text-white transition-all rounded-lg'>Single User</button>
                                <button onClick={() => {setTitle("group-user"); setRolesVisible(false)}} className='p-1 mb-1 hover:bg-indigo-400 hover:text-white transition-all rounded-lg'>Group User</button>
                                <button onClick={() => {setTitle("super-user"); setRolesVisible(false)}} className='p-1 mb-1 hover:bg-indigo-400 hover:text-white transition-all rounded-lg'>Super User</button>
                                <button onClick={() => {setTitle("super-editor"); setRolesVisible(false)}} className='p-1 mb-1 hover:bg-indigo-400 hover:text-white transition-all rounded-lg'>Super Editor</button>

                            </div>
                        </div>
                        {/* <input className='add-user-input' onChange={((e) => setTitle(e.target.value))} value={title} placeholder="Authorization - e.g. super-user" /> */}

                        <input className='add-user-input' onChange={((e) => setEmail(e.target.value))} value={email} placeholder="Email" />
                        <input className='add-user-input' onChange={((e) => setPassword(e.target.value))} value={password} placeholder="Password" />
                    </div> 
                }
                {mode === "auto" && 
                    <AutomaticAdding 
                        progress={progress} 
                        setAutoUsers={setAutoUsers}
                    />
                }

                <div className='mt-4 mx-2 relative overflow-hidden'>
                    <button className='w-[100%] p-2 bg-indigo-400 text-white rounded-lg hover:shadow-md transition-all' onClick={mode === "manual" ? addUserManual : addUsersAuto}>
                        Create User
                    </button>
                    <div className={'w-[100%] top-0 p-2 absolute rounded-lg bg-green-400 text-white transition-all text-center hover:shadow-md ' + (userAdded ? " left-0" : " left-[100%]")}>
                        + User added
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddUser