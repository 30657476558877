import React from 'react'

const AddCategories = React.memo(({ addCategoryVisible, setFormData, setAddCategoryVisible, newCategoryIndex }) =>  {

    function addCategory(categoryIndex, categoryName, categoryType) {
        setFormData(prev => {
            const updatedFormData = {... prev}

            var newId = Math.floor(100000 + Math.random() * 900000)
    
            const usedIds = updatedFormData.categories.map(category => category.fields.map(field => field.id))
            usedIds.flat()
    
            while (usedIds.includes(usedIds)) {
                newId = Math.floor(100000 + Math.random() * 900000)
            }
    
            updatedFormData.categories.splice(categoryIndex + 1, 0, {
                name : categoryName,
                type : categoryType,
                fields : []
            })
    
            // addRow(categoryIndex + 1, 0)
    
            return updatedFormData
        })

        setAddCategoryVisible(false)
    } 


    return (
        <div className={addCategoryVisible ? "block" : "hidden"}>
            <div className='w-[100%] mx-auto px-2 border-b flex justify-center'>
                <div className='w-[85%] flex'>
                    <button className='new-category-btn' onClick={() => addCategory(newCategoryIndex, "General Information", "information")}>General Information</button>
                    <button className='new-category-btn' onClick={() => addCategory(newCategoryIndex, "Standard Transactions", "standard-transactions")}>Standard Transactions</button>
                    <button className='new-category-btn' onClick={() => addCategory(newCategoryIndex, "Individual Transactions", "individual-transactions")}>Individual Transactions</button>
                    <button className='new-category-btn' onClick={() => addCategory(newCategoryIndex, "Numbers & Figures", "numbers")}>Numbers & Figures</button>
                </div>
            </div>
        </div>
    )
})

export default AddCategories