import { CalendarIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, setDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useOutsideClick } from '../../firebase'
import IdDropdown from '../../components/IdDropdown'

function AddYear({ selectedCompany }) {

    const yearRef = useRef()

    const [companiesVisible, setCompaniesVisible] = useState(false)
    const [companies, setCompanies] = useState([])
    const [selectedCopyCompany, setSelectedCopyCompany] = useState(selectedCompany)

    const [selectedYear, setSelectedYear] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()

    const [years, setYears] = useState()
    const [templates, setTemplates] = useState()

    const [fromYearTrue, setFromYearTrue] = useState()
    const [fromTemplateTrue, setFromTemplateTrue] = useState()
    const [subsVisible, setSubsVisible] = useState()

    const [availableSubs, setAvailableSubs] = useState()
    const [selectedSubs, setSelectedSubs] = useState([])

    const [subQuery, setSubQuery] = useState("")

    const subsRef = useRef()
    useOutsideClick(subsRef, () => setSubsVisible(false))


    /// Loading available Document Types ///

    useEffect(() => {
        if (selectedCompany) {
            async function fetchYears() {
                const newYears = await getDocs(collection(db, "user_data", selectedCompany?.id, "years"))
                setYears(newYears?.docs)
            }

            async function fetchCompanies() {
                const fetchedCompanies = await getDocs(collection(db, "user_data"))
                setCompanies(fetchedCompanies?.docs)
            }
            
            fetchCompanies()
            fetchYears()
        }

    }, [selectedCompany])


    /// Copy Client Selection ///

    async function selectCopyCompany(company) {
        setSelectedCopyCompany(company)

        if (fromYearTrue) {
            const fetchedYears = await getDocs(collection(db, "user_data", company?.id, "years"))
            setYears(fetchedYears.docs)
        }

        setCompaniesVisible(false)
    }


    /// Draft Type Selection ///
    
    async function selectFromYear() {
        setFromTemplateTrue(false)
        setFromYearTrue(true)

        const newYears = await getDocs(collection(db, "user_data", selectedCopyCompany?.id, "years"))

        setYears(newYears?.docs)
    }

    async function selectFromTemplate() {
        setFromYearTrue(false)
        setFromTemplateTrue(true)

        const newTemplates = await getDocs(collection(db, "forms"))
        setTemplates(newTemplates?.docs)
    }


    /// Draft Selection ///

    async function selectYear(year) {
        setSelectedYear(year)

        const subsidiaries = await getDocs(collection(db, "user_data", selectedCopyCompany.id, "years", year.id, "subsidaries"))
        setAvailableSubs(subsidiaries.docs)
        setSelectedSubs(subsidiaries.docs.map(sub => sub.id))
    }

    async function selectTemplate(template) {
        setSelectedTemplate(template)
    }


    /// Creating new Document Type ///

    async function addYear() {

        const newYear = yearRef.current.value

        if (years.map(year => year.id).includes(newYear)) {
            return
        }

        await setDoc(doc(db, "user_data", selectedCompany.id, "years", newYear), {})


        if (fromYearTrue) {

            // Duplicating year form

            const newDoc = await getDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "forms", "standard_form"))
            await setDoc(doc(db, "user_data", selectedCompany.id, "years", newYear, "forms", "standard_form"), newDoc.data())


            // Fetching all field-ids from the form

            const allFieldsIds = newDoc.data().categories.map(category => category.fields.map(field => field.id)).flat()
            //allFieldsIds.flat()

            console.log(allFieldsIds)

            
            // Filtering out subsidiaries 

            const subsidiaries = availableSubs.filter(sub => selectedSubs.includes(sub.id))
            
            // Less Calls necessary to get subsidiaries
            // await getDocs(collection(db, "user_data", selectedCompany.id, "years", selectedYear.id, "subsidaries"))

            
            for (const subsidiary of subsidiaries) {
                console.log(subsidiary.id)
                const subsidiaryData = subsidiary.data()
                console.log(subsidiaryData)

                const docData = {
                    fieldValues : subsidiaryData.fieldValues,
                    roles : subsidiaryData.roles
                }
    
                // done set to false, comments are cleared

                // for (const fieldValue of subsidiaryData.fieldValues) {
                //     if (allFieldsIds.includes(fieldValue.id)) {

                //         const cleanedFieldValue = fieldValue
                //         cleanedFieldValue.done = false
                //         cleanedFieldValue.comment = ""
                //         docData.fieldValues.push(fieldValue)
                //     }
                // }
    
                await setDoc(doc(db, "user_data", selectedCompany.id, "years", newYear, "subsidaries", subsidiary.id), docData)
            }    

        } else if (fromTemplateTrue) {
            await setDoc(doc(db, "user_data", selectedCompany.id, "years", newYear, "forms", "standard_form"), selectedTemplate.data())
        }

        // Add Folder to /folders
        await setDoc(doc(db, "user_data", selectedCompany.id, "folders", newYear), {
            name: newYear
        })
    }


    /// HTML Component ///

    return (
        <div className='component-container w-[100%] lg:w-[75%] block relative z-20 '>
            <div className='component-icon-container bg-cyan-400'>
                <CalendarIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Add Document Type</p>
            <div className='mt-3'>
                <div className='mx-1 mb-2'>
                    <div className='px-1 mb-1'>
                        <button className={'w-[100%] p-1 rounded-lg border border-cyan-400 hover:bg-cyan-400 hover:text-white transition-all ' + (fromTemplateTrue ? "bg-cyan-400 text-white" : "bg-white text-cyan-400")} onClick={selectFromTemplate}>From Template</button>

                    </div>
                    <div className='text-center text-sm my-1'>
                        or select from Client <span className='underline'>and</span> Document Type
                    </div>
                    <div className='flex mb-1'>
                        <div className='ml-1 w-[50%]'>
                        <IdDropdown 
                            placeholder="Client"
                            selectFunction={selectCopyCompany}
                            width={"100%"}
                            color="cyan"
                            dropdownVisible={companiesVisible}
                            setDropdownVisible={setCompaniesVisible}
                            options={companies}
                            selectedOption={selectedCopyCompany}
                        />                            
                        </div>

                        <button className={'w-1/2 p-1 rounded-lg border border-cyan-400 hover:bg-cyan-400 hover:text-white mx-1 transition-all ' + (fromYearTrue ? "bg-cyan-400 text-white" : "bg-white text-cyan-400")} onClick={selectFromYear}>From Document Type</button>
                    </div>
                    <div className={'rounded-lg border p-1 mx-1 mb-2' + (fromYearTrue || fromTemplateTrue ? "" : " hidden")}>

                        {fromYearTrue &&
                            <div className='overflow-y-auto h-28 scrl-none '>
                            {years?.map((year, index) => (
                                <button key={index} className={'w-[100%] hover:bg-cyan-400 hover:text-white p-1 rounded-lg mb-0.5 ' + (year.id === selectedYear?.id ? 'bg-cyan-400 text-white' : '')} onClick={() => selectYear(year)}>{year?.id}</button>
                            ))}
                            </div>
                        }
                        {fromTemplateTrue &&
                            <div className='overflow-y-auto h-28 scrl-none '>
                            {templates?.map((template, index) => (
                                <button key={index} className={'w-[100%] hover:bg-cyan-400 hover:text-white p-1 rounded-lg mb-0.5 ' + (template.id === selectedTemplate?.id ? 'bg-cyan-400 text-white' : '')} onClick={() => selectTemplate(template)}>{template.id}</button>
                            ))}
                            </div>
                        }

                    </div>
                    <div className='flex'>
                        <div ref={subsRef} className='w-1/3 mx-1 relative'>
                            <button onClick={() => setSubsVisible(prev => !prev)} className='w-[100%] border rounded-lg h-[100%] text-neutral-500'>
                                {(selectedYear && selectedSubs?.length !== availableSubs?.length) ? "All - " + (availableSubs?.length - selectedSubs?.length) : "Enter Documents"}
                            </button>
                            <div className={'absolute bg-white w-60 px-1 drop-shadow-lg rounded-lg rounded-tl-none z-[100] ' + ((subsVisible && selectedYear !== undefined) ? "" : " hidden")}>
                                <input onChange={(e) => setSubQuery(e.target.value)} value={subQuery} className='focus:outline-none border rounded-lg w-[100%] text-center p-1 my-1' placeholder='Search Document' />
                                <button 
                                    onClick={() => setSelectedSubs(prev => prev.sort().join(',') === availableSubs?.map(sub => sub.id).sort().join(",") ? [] : availableSubs?.map(sub => sub.id))} 
                                    className={'w-[100%] p-1 my-[1px] hover:bg-cyan-500 hover:text-white rounded-md transition-all mb-1' + (selectedSubs.sort().join(',') === availableSubs?.map(sub => sub.id).sort().join(",") ? " bg-cyan-400 text-white" : "")}
                                >
                                        Select All
                                </button>
                                <div className='no-scrollbar max-h-40 overflow-auto'>
                                    {availableSubs?.map((sub, index) => (
                                        (subQuery === "" || sub.id.toLowerCase().includes(subQuery.toLowerCase())) && 
                                        <button 
                                            onClick={() => setSelectedSubs(prev => prev.includes(sub.id) ? prev.filter(prevVal => prevVal !== sub.id) : [...prev, sub.id])} key={index} 
                                            className={'w-[100%] p-1 my-[1px] hover:bg-cyan-500 hover:text-white rounded-md transition-all ' + (selectedSubs.includes(sub.id) ? " bg-cyan-400 text-white" : "")}
                                        >
                                            {sub.id}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className='w-1/3 mx-1'>
                            <input className='p-1 border rounded-lg w-[100%] focus:outline-none focus:border-slate-400' ref={yearRef} placeholder='Insert new Document Type' />
                        </div>
                        <div className='w-1/3 mx-1'>
                            <button className='p-1 border border-cyan-400 rounded-lg bg-cyan-400 text-white w-[100%] transition-all hover:shadow-lg' onClick={addYear}>Add</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AddYear