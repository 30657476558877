import React from 'react'

const TopicInput = React.memo(({ setFormData, setSavePossible, topicCategoryIndex, topicRowIndex, topic }) => {

    function changeTopic(e, categoryIndex, rowNumber) {
        setFormData(prev => {
            const updatedFormData = {... prev}

            updatedFormData.categories[categoryIndex].fields[rowNumber].topic = e.target.value
    
            const checkArray = updatedFormData.categories.map(category => category.fields.filter(field => field.topic === e.target.value))
            const cleanCheckArray = checkArray.filter(checkElement => checkElement.length > 0)
    
            if (cleanCheckArray.length > 1) {
                setSavePossible(false)
            } else {
                setSavePossible(true)
            }
    
            return updatedFormData
        })
    }

    return (
        <div className='w-1/2 float-left flex justify-center'>
            <input className='border rounded-lg p-1 w-[90%]' value={topic} onChange={(e) => changeTopic(e, topicCategoryIndex, topicRowIndex)} />
        </div>
    )
})

export default TopicInput