import { DocumentAddIcon, FolderAddIcon, InformationCircleIcon, SaveIcon, SwitchVerticalIcon, TrashIcon } from '@heroicons/react/outline'
import React from 'react'
import Tool from './Tool'


const Toolbar = React.memo(({ saveFunction, handleChangeVisibility, setAddCategoryVisible, setAddRowVisible, handleDeleteVisibility, deletedRows }) => {
    
    return (
        <div className='flex justify-end fixed z-[30] right-2'>
            <div className='relative py-2 px-1 rounded-lg w-max mr-5 flex'>
                <Tool  
                    desc={"Save"}
                    icon={<SaveIcon className='w-8 h-8' />}
                    toolFunction={() => saveFunction(deletedRows)}
                    infoRight={true}
                />
                <Tool  
                    desc={"Change Row / Category Order"}
                    icon={<SwitchVerticalIcon className='w-8 h-8' />}
                    toolFunction={handleChangeVisibility}
                    infoRight={true}
                />
                <Tool  
                    desc={"Add Rows"}
                    icon={<DocumentAddIcon className='w-8 h-8' />}
                    toolFunction={() => setAddRowVisible(prev => !prev)}
                    infoRight={true}
                />
                <Tool  
                    desc={"Add Categories"}
                    icon={<FolderAddIcon className='w-8 h-8' />}
                    toolFunction={() => setAddCategoryVisible(prev => !prev)}
                    infoRight={false}
                />
                <Tool  
                    desc={"Delete Categories / Rows"}
                    icon={<TrashIcon className='w-8 h-8' />}
                    toolFunction={handleDeleteVisibility}
                    infoRight={false}
                />
            </div>
        </div>
    )
})

export default Toolbar