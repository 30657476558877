import React, { useEffect, useRef } from 'react'
import { useOutsideClick } from '../firebase'

function IdDropdown({ width, color, options, selectFunction, setDropdownVisible, dropdownVisible, placeholder, selectedOption }) {

    const dropdownRef = useRef()
    useOutsideClick(dropdownRef, () => setDropdownVisible(false))


    // Loading Colors

    const colors = [
        "bg-red-400",
        "bg-blue-400",
        "bg-indigo-400",
        "bg-amber-400",
        "bg-sky-400",
        "bg-emerald-400",
        "bg-green-400",
        "bg-rose-400",
        "bg-purple-400",
        "bg-pink-400",
        "bg-teal-400",
        "bg-cyan-400",
        "bg-yellow-400",
        "hover:bg-emerald-400"
    ]


    return (
        <div ref={dropdownRef} style={{ width }} className='relative'>
            <button className='w-[100%] border rounded-lg p-1' onClick={() => setDropdownVisible(prev => !prev)}>{selectedOption ? selectedOption.id : placeholder}</button>
            <div className={dropdownVisible ? "" : "hidden"}>
                <div ref={dropdownRef} className='absolute bg-white w-[100%] px-1 pt-1 shadow-basic rounded-lg z-20 mt-1 max-h-32 overflow-auto'>
                {options.map((option, index) => (
                    <button key={index} className={`select-btn hover:bg-${color}-400` + (selectedOption?.id === option.id ? ` bg-${color}-400 text-white` : '')} onClick={() => selectFunction(option)}>{option?.id}</button>
                ))}
                </div>
            </div>
        </div>
    )
}

export default IdDropdown