import { FolderAddIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, onSnapshot, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, shortify, useOutsideClick } from '../../firebase'
import FileAuthButton from './FileAuthButton'
import NameDropdown from '../../components/NameDropdown'

function ManageFileAuthorization({ selectedCompany }) {

    const [users, setUsers] = useState([])
    const [usersVisible, setUsersVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    const [folders, setFolders] = useState([])
    const [foldersVisible, setFoldersVisible] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState()

    const [subfolders, setSubfolders] = useState([])

    const [saveCall, setSaveCall] = useState(false)
    const [switchCall, setSwitchCall] = useState(false)

    const usersRef = useRef()
    useOutsideClick(usersRef, () => setUsersVisible(false))



    useEffect(() => {
        async function fetchFoldersAndUsers() {
            const fetchedFolders = await getDocs(collection(db, "user_data", selectedCompany.id, "folders"))
            setFolders(fetchedFolders.docs)

            const unsub = onSnapshot(collection(db, "user_data", selectedCompany.id, "users"), (querySnapshot) => {
                setUsers(querySnapshot.docs)
            })
        }   

        fetchFoldersAndUsers()
    }, [selectedCompany])

    async function selectFolder(folder) {

        setSelectedFolder(folder)
        const newSubfolders = await getDocs(collection(db, "user_data", selectedCompany.id, "folders", folder.id, "subfolders"))
        setSubfolders(newSubfolders.docs)
        setFoldersVisible(false)

    }


    function saveFileAuthorization() {
        setSaveCall(prev => !prev)
    }

    function switchAllAuth() {
        if (switchCall !== "switchPos") {
            setSwitchCall("switchPos")
        } else {
            setSwitchCall("switchNeg")
        }
    }

    return (
        <div className='component-container mt-16  w-[100%] lg:w-[75%] relative z-10'>
            <div className='component-icon-container bg-cyan-400'>
                <FolderAddIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Manage File Authorization</p>
            <div className='mt-3'>
                <div className='flex px-2 gap-x-2'>
                    <NameDropdown 
                        width={"30%"}
                        color={"cyan"}
                        dropdownVisible={foldersVisible}
                        setDropdownVisible={setFoldersVisible}
                        options={folders}
                        selectFunction={selectFolder}
                        selectedOption={selectedFolder}
                        placeholder={"Folder"}
                    />

                    <div ref={usersRef} className='w-[70%] relative'>
                        <button className='w-[100%] border rounded-lg p-1' onClick={() => setUsersVisible(prev => !prev)}>{selectedUser?.data().firstName ? (selectedUser.data().firstName + " " + selectedUser.data().lastName) : "User"}</button>
                        <div className='absolute bg-white w-[100%] px-1 drop-shadow-lg rounded-b-lg z-20 max-h-36 overflow-y-auto'>
                            <div className={usersVisible ? "" : "hidden"}>
                            {users?.map((user, index) => (
                                <button key={index} className='select-btn hover:bg-cyan-400' onClick={() => {setSelectedUser(user); setUsersVisible(false)}}>
                                    {(user.data().firstName + " " + user.data().lastName + " - " + user.data().title)}
                                </button>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'border rounded-lg p-2 flex justify-center flex-wrap mt-2 mx-2' + (selectedUser ? "" : " hidden")}>
                    <button onClick={switchAllAuth} className={'auth-button ' + (switchCall === "switchPos" ? "bg-green-400" : "bg-red-400")}>All</button>
                    {subfolders?.map((subfolder, index) => (
                        <FileAuthButton 
                            selectedCompany={selectedCompany}
                            subfolder={subfolder} 
                            mainfolder={selectedFolder} 
                            user={selectedUser} 
                            saveCall={saveCall} 
                            setSaveCall={setSaveCall} 
                            switchCall={switchCall}
                        />
                    ))}
                </div>
                <div className='mt-4 mx-1'>
                    <button onClick={saveFileAuthorization} className='w-[100%] p-2 bg-cyan-400 text-white rounded-lg hover:shadow-md transition-all'>Save</button>
                </div>
            </div>
        </div>
    )
}

export default ManageFileAuthorization