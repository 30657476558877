import { DotsHorizontalIcon } from '@heroicons/react/outline'
import React, { useEffect, useRef, useState } from 'react'

function FileButton({ index, loadSubFolders, folder, selectedFolder, setDeleteFolderVisible, setEditFolderVisible, setDeleteFolderRef, setDeleteFolderName, editFunction, masterUser }) {

    const [optionsVisible, setOptionsVisible] = useState(false)
    const [optionsMenuVisible, setOptionsMenuVisible] = useState(false)
    const optionsMenuRef = useRef()


    function handleShowOptions(e) {
        e.preventDefault()
        setOptionsMenuVisible(prev => !prev)
    }

    function handleClickOutside(event) {
        if (optionsMenuRef.current && !optionsMenuRef.current.contains(event.target)) {
            
            setOptionsMenuVisible(false)
        }
    }

    useEffect(() => {

        // This uses the 'mousedown' event in order to prevent a collison with the remove answer option

        document.addEventListener('mousedown', (e) => handleClickOutside(e));

        return () => {
            document.removeEventListener('mousedown', (e) => handleClickOutside(e));
        };

    }, []);

    /// Check if Folder is Document Type (Numeric) ///

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    useEffect(() => {
        console.log(selectedFolder?.data()?.name)
    }, [selectedFolder])



    return (
        <div className='relative w-[100%]'>
            <div onMouseLeave={() => setOptionsVisible(false)} onMouseEnter={() => setOptionsVisible(true)} key={index}  className={'relative px-1 py-2 w-[100%] hover:bg-blue-400 rounded-lg hover:text-white my-1 ' + (folder.id === selectedFolder?.id ? " bg-blue-400 text-white" : "")}>
                <button onContextMenu={handleShowOptions} onClick={() => loadSubFolders(folder)} className='w-[100%]'>
                    {folder?.data().name}
                    {/* <input className={'focus:outline-none ' +  + (folder.id === selectedFolder?.id ? " bg-blue-400 text-white" : "")} value={folder?.data().name} /> */}
                </button>
            
                <button onClick={() => setOptionsMenuVisible(prev => !prev)} className={'absolute right-2 top-0 bottom-0 my-auto hover:bg-blue-300 rounded-lg p-0.5 h-max transition-all ' + ((optionsVisible && masterUser && !isNumeric(folder?.data()?.name) && !isNumeric(selectedFolder?.data()?.name)) ? "" : "hidden")}>
                    <DotsHorizontalIcon className='w-6 h-6' />
                </button>
            </div>
            <div ref={optionsMenuRef} className={'rounded-lg bg-white text-sm shadow-basic  absolute z-10 min-w-[200px] w-[80%] flex flex-col px-1' + ((optionsMenuVisible && masterUser && !isNumeric(folder?.data()?.name) && !isNumeric(selectedFolder?.data()?.name)) ? "" : " hidden") + (index > 10 ? " bottom-[100%]" : " top-[100%]")}>
                <div className='text-center py-2 bg-neutral-200 mt-1 rounded-lg'>
                    {folder.data().name}
                </div>
                <button onClick={() => {setEditFolderVisible(true); editFunction(); setOptionsMenuVisible(false)}} className='p-1.5 hover:bg-blue-400 hover:text-white transition-all rounded-lg my-1'>
                    Rename
                </button>
                <button onClick={() => {setDeleteFolderVisible(true); setDeleteFolderRef(); setOptionsMenuVisible(false); setDeleteFolderName()}} className='p-1.5 text-red-500 hover:bg-red-400 hover:text-white transition-all rounded-lg my-1'>
                    Delete
                </button>
            </div>
        </div>
    )
}

export default FileButton