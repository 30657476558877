import React from 'react'

const AddRows = React.memo(({ addRowVisible, addRow, categoryIndex, rowIndex}) => {
    
    return (
        <div className={addRowVisible ? "block" : "hidden"}>
            <div className='w-[100%] mx-auto px-2 border-b flex justify-center'>
                <button className='w-[85%] border-dashed border-2 p-2 rounded-xl my-4 border-green-800 hover:border-solid bg-green-200' onClick={() => addRow(categoryIndex, rowIndex)}>Add Row here</button>
            </div>
        </div>
    )
})

export default AddRows