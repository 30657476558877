import { ArrowLeftIcon, ClockIcon, ExclamationIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, limit, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase'

function EmailDetails({ selectedCompany, email, convertToDate, convertToTime, setShowMode, selectedEmail }) {

    const [emailTemplate, setEmailTemplate] = useState()
    const [emailContent, setEmailContent] = useState()
    const [prevNotifications, setPrevNotifications] = useState([])


    useEffect(() => {
        async function fetchEmailDetails() {
            console.log(email.data())

            // Fetching the Mail Template
            const fetchedTemplate = await getDoc(doc(db, "user_data", selectedCompany.id, "email-templates-log", email.data().template))
            var content = fetchedTemplate.data().content

            // Setting {{missingFields}}

            var missingGapsText = ""
            if (email.data().missingGaps) {
                for (const missingGap of email.data().missingGaps) {
                    missingGapsText += "<div>" + missingGap + ",</div>"
                }
                
                missingGapsText += "<hr style='margin-top: 16px;'>"
            }
            content = content.replace("{{missingFields}}", missingGapsText)
            content = content.replace("{{missingGaps}}", missingGapsText)  

            // Setting {{name}}
            const userQuery = query(collection(db, "user_data", selectedCompany.id, "users"), where("email", "==", email.data().user), limit(1))
            const fetchedUsers = await getDocs(userQuery)
            content = content.replace("{{firstName}}", fetchedUsers.docs[0].data().firstName)
            content = content.replace("{{lastName}}", fetchedUsers.docs[0].data().lastName)
            content = content.replace("{{year}}", email.data().year)
            content = content.replace("{{subsidiary}}", email.data().subsidiary)


            setEmailTemplate(fetchedTemplate)
            setEmailContent(content)

            // Fetching previous Notifications
            const fetchedPrevNotifications = await calcNotifcationNumber(email)
            setPrevNotifications(fetchedPrevNotifications)
        }

        fetchEmailDetails()
    }, [email])


    async function calcNotifcationNumber(email) {

        const utcMilliseconds = email.data().createdAt.seconds * 1000 + email.data().createdAt.nanoseconds / 1e6
        const utcDate = new Date(utcMilliseconds)
        const utcIsoDate = utcDate.toISOString()

        const notificationQuery = query(
            collection(db, "user_data", selectedCompany.id, "notification-log"), 
            where("user", "==", email.data().user), 
            where("year", "==", email.data().year),
            where("subsidiary", "==", email.data().subsidiary),
            where("createdAt", "<", utcDate)
        )

        const fetchedNotifications = await getDocs(notificationQuery)
        return fetchedNotifications.docs
    }


    return (
        <div className='mt-3'>
            <div className='mb-2 border rounded-lg p-1'>
                {emailTemplate?.data()?.subject}
            </div>
            <div dangerouslySetInnerHTML={{ __html: emailContent}} className='text-sm h-[32rem] overflow-y-auto border rounded-lg p-1' />
            <div className='px-1.5 py-3 flex justify-between'>
                <div className='w-1/3 flex items-center'>
                    <button onClick={() => setShowMode("standard")} className='p-2 bg-rose-400 hover:bg-rose-500 text-white rounded-full transition-all w-max'>
                        <ArrowLeftIcon className='w-6 h-6' />
                    </button>
                </div>
                <div className='px-4'>
                    <div className='flex items-center text-neutral-600 text-xs mb-1.5'>
                        <ClockIcon className='w-[18px] h-[18px] mr-1.5' />{convertToDate(selectedEmail.data().createdAt) + ", " + convertToTime(selectedEmail.data().createdAt) + " (GMT)"}
                    </div>
                    <div className='flex items-center text-neutral-600 text-xs'>
                        <ExclamationIcon className='w-[18px] h-[18px] mr-1.5' />{(prevNotifications.length + 1) + ". Notification sent"}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EmailDetails