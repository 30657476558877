import React, { useEffect } from 'react'
import CreateDocument from './CreateDocument'

function DocumentPanel({ selectedCompany }) {

    
    /// Title Specification ///

    useEffect(() => {
        document.title = 'Create Document - Get your documents ready to go'; 
    }, []);

    
    /// HTML Component ///

    return (
        <div className='font-body flex flex-col items-center h-[100%] pt-20'>

            <div className='h-12'></div>
            <CreateDocument selectedCompany={selectedCompany} />
        </div>
    )
}

export default DocumentPanel