import { DocumentAddIcon, DocumentTextIcon, FolderAddIcon, InformationCircleIcon, MinusSmIcon, PlusCircleIcon, PlusSmIcon, SwitchVerticalIcon, TrashIcon } from '@heroicons/react/outline'
import { ChevronDownIcon, ChevronUpIcon, DuplicateIcon, PlusIcon, TrashIcon as TrashIcon2 } from '@heroicons/react/solid'
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useAuth, shortify, useOutsideClick } from '../../firebase'
import FormHolder from './FormHolder'

function EditTemplate() {

    const [selectableForms, setSelectableForms] = useState()
    const [formsVisible, setFormsVisible] = useState(false)
    const [selectedFormName, setSelectedFormName] = useState("")
    const [formData, setFormData] = useState()

    const formsRef = useRef()
    useOutsideClick(formsRef, () => setFormsVisible(false))


    /// Title Specification ///

    useEffect(() => {
        document.title = 'Edit Form Template - Change Form-Templates'; 
    }, []);


    /// Loading available Templates /// 

    useEffect(() => {
        async function fetchForm() {
            const newSelectableForms = await getDocs(collection(db, "forms"))
            setSelectableForms(newSelectableForms.docs)
        } 

        fetchForm()
    }, [])

    function selectForm(formDoc) {
        setFormData(formDoc.data())
        setSelectedFormName(formDoc.id)
        setFormsVisible(false)
    }
    

    /// Cloning Function ///

    const templateRef = useRef()

    async function cloneTemplate() {
        if (formData) {
            await setDoc(doc(db, "forms", templateRef.current.value), formData)  
        } else {
            await setDoc(doc(db, "forms", templateRef.current.value), {categories: []})  

        }                
    }


    /// Saving Callback Function /// 

    async function saveFormData() { 
        
        const updateFormData = {... formData}

        console.log(updateFormData)

        for (const [index, category] of formData.categories.entries()) {
            updateFormData.categories[index].fields = updateFormData.categories[index].fields.filter(function( element ) {
                return element !== undefined;
            });
        }
   
        await updateDoc(doc(db, "forms", selectedFormName), updateFormData)
    }


    /// HTML Component ///

    return ( 
        <div className={' w-[100%] font-body p-0 my-0 mx-auto bg-white flex flex-col' + (formData ? " " : "")} >        

            <div className='bg-white text-lg px-2 py-1 flex items-center fixed w-[100%] z-30 border-b'>
                <div className='flex items-center p-2'>
                    <div className='p-2 bg-blue-500 text-white rounded-full mr-4'>
                        <DocumentTextIcon className='w-7 h-7' />
                    </div>
                    <span className='text-xl'>Edit Form Template</span>
                </div>
                <div ref={formsRef} className='ml-8 mr-4 relative'>
                    <button className='border py-1.5 rounded-lg flex justify-between items-center px-8' onClick={() => setFormsVisible(!formsVisible)}>      
                        {selectedFormName ? selectedFormName : "Form Template"}
                        <ChevronDownIcon className='inline w-6 h-6 ml-2 shrink-0 grow-0' />
                    </button>
                    <div className={formsVisible ? 'block' : 'hidden'}>
                        <div className='absolute bg-white w-72 rounded-lg shadow-basic mt-1 px-1'>
                        {selectableForms?.map((selectableForm, index) => (
                            <button key={index} onClick={() => selectForm(selectableForm)} className={"doc-btn transition-all " + (selectedFormName === selectableForm.id ? ' bg-neutral-800 text-white' : '')}>
                                <span className=''>{selectableForm.id}</span> 
                            </button>
                        ))}
                        </div>
                    </div>
                </div>
                <div className='flex items-center border rounded-lg'>
                    <input className='text-l text-center g p-1 rounded-lg focus:outline-none focus:border-slate-300 text-black' placeholder='New Template' ref={templateRef} />
                    <button className='p-1 rounded-lg hover:bg-neutral-600 hover:text-white ml-1 transition-all' onClick={cloneTemplate}>
                        <PlusSmIcon className='w-8 h-8 inline' />
                    </button>
                </div>
            </div>

            {formData && 
                <>
                    <FormHolder formData={formData} setFormData={setFormData} saveFunction={saveFormData} />
                </>
            } 
        </div>


    )


}

export default EditTemplate