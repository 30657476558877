import { InformationCircleIcon, PencilIcon, PlusSmIcon, TrashIcon } from '@heroicons/react/outline'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useOutsideClick } from '../../firebase'

function EditEmailTemplate({ selectedCompany }) {

    // States

    const [newTemplateName, setNewTemplateName] = useState("")

    const [informationVisible, setInformationVisible] = useState(false)
    const [templatesVisible, setTemplatesVisible] = useState(false)
    const [selectedTemplateName, setSelectedTemplateName] = useState()


    const [emailTemplates, setEmailTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState()

    const [emailSubject, setEmailSubject] = useState("")
    const [emailContent, setEmailContent] = useState(``)

    const [initEmailContent, setInitEmailContent] = useState(``)

    const contentEditableRef = useRef(null);

    const templatesRef = useRef()
    useOutsideClick(templatesRef, () => setTemplatesVisible(false))

    const infoRef = useRef()
    useOutsideClick(infoRef, () => setInformationVisible(false))

    // Loading

    useEffect(() => {
        async function fetchEmailTemplate() {
            const fetchedEmailTemplates = await getDocs(collection(db, "user_data", selectedCompany.id, "email-templates"))
            setEmailTemplates(fetchedEmailTemplates.docs)
            if (fetchedEmailTemplates.docs.length > 0) {
                selectTemplate(fetchedEmailTemplates.docs[0])

            }
        }

        fetchEmailTemplate()
    }, [])


    // Selection

    async function selectTemplate(template) {
        setSelectedTemplate(template)
        setSelectedTemplateName(template.data().name)
        setTemplatesVisible(false)
        setEmailSubject(template.data().subject)
        setInitEmailContent(template.data().content)
    }


    // Create / Save

    async function createNewTemplate() {

        if (newTemplateName === "") {
            return
        }

        const newTemplate = await addDoc(collection(db, "user_data", selectedCompany.id, "email-templates"), {
            name: newTemplateName,
            subject: "",
            content: ``
        })

        setNewTemplateName("")
        setSelectedTemplate(newTemplate)
        setSelectedTemplateName(newTemplateName)
        setEmailSubject("")
        setInitEmailContent(``)
        setEmailContent(``)
    }

    async function saveTemplate() {

        await updateDoc(doc(db, "user_data", selectedCompany.id, "email-templates", selectedTemplate.id), {
            subject: emailSubject,
            content: emailContent
        })
    }

    async function deleteTemplate() {
        await deleteDoc(doc(db, "user_data", selectedCompany.id, "email-templates", selectedTemplate.id))
        setSelectedTemplate()
        setSelectedTemplateName()
    }


    return (
        <div className='py-4 md:py-8 lg:py-14 h-[95vh] bg-white font-body'>
            <div className='bg-white w-[80%] md:w-[70%] mx-auto'>
                <div className='component-icon-container bg-indigo-400'>
                    <PencilIcon className='w-7 h-7' />
                </div>
                <p className='text-2xl p-1 m-1'>Edit Email Template</p>
                <div className='mt-3 px-2'>
                    <div className='flex justify-between pb-3 mb-3 border-b '>
                        <div className='flex'>
                            <div ref={templatesRef} className='relative'>
                                <button onClick={() => setTemplatesVisible(prev => !prev)} className='border rounded-lg py-1.5 px-20'>{selectedTemplateName ? selectedTemplateName : "Template"}</button>
                                <div className={'absolute mt-1 bg-white shadow-basic rounded-lg w-[100%] px-1 pt-1 ' + (templatesVisible ? "" : "hidden")}>
                                {emailTemplates.map((emailTemplate, i) => (
                                    <button onClick={() => selectTemplate(emailTemplate)} className='select-btn hover:bg-indigo-400'>{emailTemplate.data().name}</button>
                                ))}
                                </div>
                            </div>
                            {console.log(selectedTemplate)}
                            {selectedTemplate !== undefined &&
                                <button onClick={deleteTemplate} className={'border-2 border-red-500 text-red-500 px-2.5 rounded-lg ml-1 hover:bg-red-500 hover:text-white transition-all'}>
                                    <TrashIcon className='w-5 h-5' />
                                </button>
                            }
                        </div>


                        <div className='flex items-center'>
                            <input onChange={(e) => setNewTemplateName(e.target.value)} placeholder='New Template' className='py-1.5 px-1 border rounded-lg focus:outline-none' />
                            <button onClick={createNewTemplate} className='rounded-lg p-1.5 bg-indigo-400 hover:bg-indigo-500 transition-all text-white ml-1'><PlusSmIcon className='w-6 h-6' /></button>
                        </div>
                    </div>
                    <div className='flex justify-between mb-1.5'>
                        <input value={emailSubject} onChange={(e) => setEmailSubject(e.target.value)} placeholder='Betreff' className='border focus:outline-none rounded-lg p-1 w-[60%] font-sans' />
                        

                        <div ref={infoRef} className='relative flex'>

                            <button onClick={() => setInformationVisible(prev => !prev)} className='ml-1 flex items-center px-1.5 bg-indigo-400 rounded-lg text-white'><InformationCircleIcon className='w-6 h-6' /></button>
                            {informationVisible &&
                            <div className='absolute bg-white shadow-basic rounded-lg p-3 mt-1 top-[100%] right-0 text-sm w-[18rem]'>
                                <p className='p-1 rounded-lg bg-neutral-100 mb-1.5 text-center'>Variables</p>
                                <p className='whitespace-nowrap my-1.5 mr-2 flex justify-between'>
                                    <span>{"{{firstName}}"}</span><span>Vorname</span>
                                </p>
                                <p className='whitespace-nowrap mb-1 mr-2 flex justify-between'>
                                    <span>{"{{lastName}}"}</span><span>Nachname</span>
                                </p>
                                <p className='whitespace-nowrap mb-1 mr-2 flex justify-between'>
                                    <span>{"{{missingFields}}"}</span><span>Fehlende Felder</span>
                                </p>
                                <p className='whitespace-nowrap mb-1 mr-2 flex justify-between'>
                                    <span>{"{{year}}"}</span><span>Jahr</span>
                                </p>
                                <p className='whitespace-nowrap mb-1 mr-2 flex justify-between'>
                                    <span>{"{{subsidiary}}"}</span><span>Tochtergesellschaft</span>
                                </p>
                            </div>
                            }
                        </div>

                        {/* missingGaps -> ["2021 / AT01: Document Name, Status, etc.", "Document Type / Sub: fields"] */}
                        {/* An dieser Stelle übernimmt das Backend und generiert eine Übersicht über die fehlenden Felder, 
                        die sich über mehrere Zeilen zieht*/}
                    
                    </div>
                    <div 
                        className='focus:outline-none border rounded-lg p-2 font-sans h-[28rem]' 
                        ref={contentEditableRef}
                        dangerouslySetInnerHTML={{ __html: initEmailContent }} 
                        contentEditable={true}
                        onInput={(e) => setEmailContent(e.target.innerHTML)}
                    />
                    <div className='w-[100%] mt-2'>
                        <button onClick={saveTemplate} className='w-[100%] rounded-lg bg-indigo-400 text-white p-1.5 px-4'>Save</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditEmailTemplate