import React, { useEffect, useState } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import Select from "react-select";
import { db } from "../../firebase";
import { UserAddIcon } from "@heroicons/react/outline";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

function SetClientManager({ selectedCompany }) {
  const [users, setUsers] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetching Users from Firebase Auth and populating client managers
  useEffect(() => {
    async function fetchUsersAndClientManagers() {
      setIsLoading(true);
      try {
        // Fetch all users
        const response = await httpsCallable(functions, "list_all_users")();

        if (response.data && Array.isArray(response.data)) {
          const usersData = response.data.map((user) => ({
            label: user.email,
            value: user.uid,
          }));
          setUsers(usersData);

          // Fetch current client managers if a company is selected
          if (selectedCompany && selectedCompany.id) {
            const userDocRef = doc(db, "user_data", selectedCompany.id);
            const userData = await getDoc(userDocRef);
            if (userData.exists()) {
              const roles = userData.data().roles || {};
              const currentManagers = usersData.filter((user) => roles[user.value] === "client-manager");
              setSelectedManagers(currentManagers);
            }
          }
        } else {
          console.log("No users data returned from the function");
        }
      } catch (error) {
        console.error("Error fetching users or client managers:", error);
        toast.error("Failed to fetch users or client managers.");
      }
      setIsLoading(false);
    }

    fetchUsersAndClientManagers();
  }, [selectedCompany]);

  // Handling selection of client managers
  function handleManagerSelection(selectedOptions) {
    setSelectedManagers(selectedOptions);
  }

  // Handling save action
  async function handleSave() {
    if (selectedCompany && selectedCompany.id) {
      try {
        const userDocRef = doc(db, "user_data", selectedCompany.id);
        const userData = await getDoc(userDocRef);
        let roles = userData.exists() ? userData.data().roles || {} : {};

        // Reset all client-manager roles => set it to 'editor'
        Object.keys(roles).forEach((key) => {
          if (roles[key] === "client-manager") {
            roles[key] = "editor";
          }
        });

        // Set new client-manager roles
        selectedManagers.forEach((manager) => {
          roles[manager.value] = "client-manager";
        });

        await updateDoc(userDocRef, { roles });
        toast.success("Client managers updated successfully");
      } catch (error) {
        console.error("Error updating client managers:", error);
        toast.error("Failed to update client managers.");
      }
    } else {
      console.error("No company selected or company has no id");
      toast.error("No company selected or company has no ID.");
    }
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="component-container w-[100%] lg:w-[75%] block">
      <div className="component-icon-container bg-blue-500">
        <UserAddIcon className="w-8 h-8" />
      </div>

      <p className="text-2xl p-1 m-1">Set Client Manager</p>
      <div className="mt-3">
        <Select isMulti options={users} value={selectedManagers} onChange={handleManagerSelection} placeholder="Select Client Managers" className="basic-multi-select" classNamePrefix="select" />
      </div>
      <button onClick={handleSave} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors">
        Save Changes
      </button>
    </div>
  );
}

export default SetClientManager;
