import { ArrowLeftIcon, BellIcon, ChevronDownIcon, ClockIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline'
import { collection, doc, documentId, getDoc, getDocs, limit, orderBy, query, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useFetchYears, useOutsideClick } from '../../firebase'
import EmailDetails from './EmailDetails'
import NotificationButton from './NotificationButton'
import IdDropdown from '../../components/IdDropdown'

function NotificationHistory({ selectedCompany  }) {

    // States

    const years = useFetchYears(selectedCompany)
    const [subsidiaries, setSubsidiaries] = useState([])
    const [users, setUsers] = useState([])

    const [yearsVisible, setYearsVisible] = useState(false)
    const [subsidiariesVisible, setSubsidiariesVisible] = useState(false)
    const [usersVisible, setUsersVisible] = useState(false)


    const [selectedYear, setSelectedYear] = useState()
    const [selectedSubsidiary, setSelectedSubsidiary] = useState()
    const [selectedUser, setSelectedUser] = useState()

    const [notifications, setNotfications] = useState([])

    const [showMode, setShowMode] = useState("standard")
    const [selectedEmail, setSelectedEmail] = useState()
    const [emailTemplate, setEmailTemplate] = useState()
    const [emailContent, setEmailContent] = useState()
    const [prevNotifications, setPrevNotifications] = useState([])

    const subsidiariesRef = useRef()
    useOutsideClick(subsidiariesRef, () => setSubsidiariesVisible(false))

    const emailsRef = useRef()
    useOutsideClick(emailsRef, () => setUsersVisible(false))

    // Fetching Most Recent Notifications

    useEffect(() => {
        async function fetchRecentNotifications() {
            const notificationQuery = query(collection(db, "user_data", selectedCompany.id, "notification-log"), orderBy("createdAt", "desc"), limit(16))
            const fetchedNotfications = await getDocs(notificationQuery)
            setNotfications(fetchedNotfications.docs)
        }

        fetchRecentNotifications()

        // reset all states when selected company changes

        setSelectedYear()
        setSelectedUser()
        setSelectedSubsidiary()
        setSearchEmail("")
    }, [selectedCompany])


    // Loading
    // When loading additional notifications use where(documentId, "not in", [list of loaded documents])

    async function handleSelectYear(year) {
        setSelectedYear(year)
        setYearsVisible(false)

        // Fetching Notifications
        const q = query(collection(db, "user_data", selectedCompany.id, "notification-log"), where("year", "==", year.id), orderBy("createdAt"), limit(50)) 
        const fetchedNotifications = await getDocs(q)
        setNotfications(fetchedNotifications.docs)

        // Fetching Documents
        const fetchedSubs = await getDocs(collection(db, "user_data", selectedCompany.id, "years", year.id, "subsidaries"))
        setSubsidiaries(fetchedSubs.docs)
    }

    async function handleSelectSubsidiary(subsidiary) {
        setSelectedSubsidiary(subsidiary)
        setSubsidiariesVisible(false)
        setNotfications(prev => prev.filter(notification => notification.data().subsidiary === subsidiary.id))

        // User die in der Client sind werden nicht erwähnt  

        const fetchedUsers = await getDocs(query(collection(db, "user_data", selectedCompany.id, "users"), where(documentId(), "in", Object.keys(subsidiary.data().roles))))        
        setUsers(fetchedUsers.docs)
    }

    async function handleSelectUser(user) {
        setSelectedUser(user)
        setSuggestionsVisible(false)
        setSearchEmail(user.data().email)
        setNotfications(prev => prev.filter(notification => notification.data().user === user.data().email))
    }


    const [searchEmail, setSearchEmail] = useState("")
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)
    const [searchSuggestions, setSearchSuggestions] = useState([])

    async function handleSearchEmailChange(e) {
        setSearchEmail(e.target.value)
        setSuggestionsVisible(true)
        
        const userQuery = query(collection(db, "user_data", selectedCompany.id, "users"), where("email", ">=", e.target.value), limit(3))
        const suggestedUsers = await getDocs(userQuery)
        setSearchSuggestions(suggestedUsers.docs)
    }

    async function handleSelectEmail(email) {
        setShowMode("email")
        setSelectedEmail(email)
    }


    // Cancel Selection

    async function cancelSubsidiarySelection() {
        setSelectedSubsidiary()
        setSelectedUser()
        await handleSelectYear(selectedYear)
    }

    async function cancelUserSelection() {
        setSelectedUser()
        setSearchEmail("")

        // Fetching Notifications
        const q = query(
            collection(db, "user_data", selectedCompany.id, "notification-log"), 
            where("year", "==", selectedYear.id), 
            where("subsidiary", "==", selectedSubsidiary.id),
            orderBy("createdAt"), limit(10)
        ) 
        const fetchedNotifications = await getDocs(q)
        setNotfications(fetchedNotifications.docs)
    } 

    async function loadMoreNotifications() {

    }

    // Evaluating

    function convertToDate(ut) {
        const utcMilliseconds = ut.seconds * 1000 + ut.nanoseconds / 1e6
        const utcDate = new Date(utcMilliseconds)
        return utcDate.getDate() + "." + (utcDate.getMonth() + 1) + "." + utcDate.getUTCFullYear()
    }

    function convertToTime(ut) {
        const utcMilliseconds = ut.seconds * 1000 + ut.nanoseconds / 1e6
        const utcDate = new Date(utcMilliseconds)

        var hours = utcDate.getUTCHours()
        var mins = utcDate.getUTCMinutes()

        if (hours < 10) {
            hours = "0" + hours
        }
        if (mins < 10) {
            mins = "0" + mins
        }


        return hours + ":" + mins + ""
    }


    return (
        <div className='h-[95vh] bg-white w-[100%] lg:w-[40%] font-body border-r border-neutral-100 px-3'>
            <div className='bg-white p-4 h-[100%]'>
                <div className='component-icon-container bg-rose-400'>
                    <ClockIcon className='w-8 h-8' />
                </div>
                <p className='text-2xl p-1 m-1'>Notificaton History</p>
                {showMode === "standard" && 
                <>
                    <div className='mt-4 -mx-2.5'>
                        <div className='flex px-3 pb-1'>
                            <IdDropdown 
                                width={"33.3333%"}
                                color={"sky"}
                                options={years}
                                dropdownVisible={yearsVisible}
                                setDropdownVisible={setYearsVisible}
                                selectFunction={handleSelectYear}
                                selectedOption={selectedYear}
                                placeholder={"Document Type"}
                            />
                            <div ref={subsidiariesRef} className='relative w-1/3 mx-2'>
                                <div className='rounded-lg border w-[100%] flex items-center'>
                                    <button className='w-[100%] text-center  p-1 ' onClick={() => setSubsidiariesVisible(prev => !prev)}>{selectedSubsidiary ? selectedSubsidiary.id : "Document"}</button>
                                    {selectedSubsidiary && 
                                        <button onClick={cancelSubsidiarySelection} className='p-0.5 rounded-full hover:bg-red-500 text-red-500 hover:text-white h-max mr-1'>
                                            <XIcon className='w-4 h-4' />
                                        </button>
                                    }
                                </div>                                
                                <div className='bg-white absolute w-[100%] px-1 shadow-basic rounded-lg mt-1 max-h-52 overflow-y-auto'>
                                    <div className={subsidiariesVisible ? "" : "hidden"}>
                                    {subsidiaries.map((subsidiary, index) => (
                                        <button key={index} className='select-btn hover:bg-rose-400' onClick={() => handleSelectSubsidiary(subsidiary)} >{subsidiary.id}</button>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <div ref={emailsRef} className='relative w-1/3'>
                                <div className='rounded-lg border w-[100%] flex items-center overflow-hidden'>
                                    <input value={searchEmail} onChange={(e) => handleSearchEmailChange(e)} placeholder='User' className={'w-[100%] text-center  px-1 focus:outline-none  ' + (selectedUser ? " text-xs py-2" : "text-base py-1")} />
                                    {/* <button className={'w-[100%] text-center  px-1  ' + (selectedUser ? " text-xs py-2" : "text-base py-1")} onClick={() => setUsersVisible(prev => !prev)}>{selectedUser ? (selectedUser.data().firstName + " " + selectedUser.data().lastName) : "User"}</button> */}
                                    {selectedUser &&
                                        <button onClick={cancelUserSelection}  className='p-0.5 rounded-full hover:bg-red-500 text-red-500 hover:text-white h-max mr-1'>
                                            <XIcon className='w-4 h-4' />
                                        </button>
                                    }
                                </div>                                
                                <div className='bg-white absolute w-[100%] px-1 shadow-basic rounded-lg mt-1'>
                                    <div className={suggestionsVisible ? "" : "hidden"}>
                                    {searchSuggestions.map((searchSuggestion, index) => (
                                        <button key={index} className='select-btn hover:bg-rose-400 text-xs' onClick={() => handleSelectUser(searchSuggestion)} >{(searchSuggestion.data().email)}</button>
                                    ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='h-[43rem] overflow-y-auto no-scrollbar px-3 pb-3 border rounded-lg mt-0.5'>
                        {/* {(!selectedYear && !selectedSubsidiary && !selectedUser) && <p className='my-5 text-center text-2xl'>Last Notifications</p>} */}
                        {notifications.map((notification, i) => (
                            <NotificationButton 
                                notification={notification}
                                convertToDate={convertToDate}
                                handleSelectEmail={handleSelectEmail}
                            />
                        ))}
                    </div>
                </>
                }
                {showMode === "email" && 
                <>
                    <EmailDetails 
                        selectedCompany={selectedCompany}
                        email={selectedEmail}
                        convertToDate={convertToDate}
                        convertToTime={convertToTime}
                        setShowMode={setShowMode}
                        selectedEmail={selectedEmail}
                    />
                </>
                }
            </div>
        </div>
    )
}

export default NotificationHistory