import { PresentationChartBarIcon, SearchIcon, TableIcon, TrashIcon } from '@heroicons/react/outline'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { CSVDownload, CSVLink } from 'react-csv'
import { db, shortify, useOutsideClick } from '../../firebase'
import BigIdDropdown from '../../components/BigIdDropdown'


function CreateCsv({ selectedCompany }) {

    const [years, setYears] = useState([])
    const [selectedYear, setSelectedYear] = useState()

    const [yearsVisible, setYearsVisible] = useState(false)

    const [csvData, setCsvData] = useState([])
    const [csvHeaders, setCsvHeaders] = useState([])

    const [categoriesVisible, setCategoriesVisible] = useState()

    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])

    const [rows, setRows] = useState()
    const [selectedRows, setSelectedRows] = useState([])
    const [rowQuery, setRowQuery] = useState("")

    const [allSelected, setAllSelected] = useState()

    const [templateName, setTemplateName] = useState("")

    const [templates, setTemplates] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [templatesVisible, setTemplatesVisible] = useState()

    const categoriesRef = useRef()
    useOutsideClick(categoriesRef, () => setCategoriesVisible(false))

    const templatesRef = useRef()
    useOutsideClick(templatesRef, () => setTemplatesVisible(false))


    /// Title Specification ///

    useEffect(() => {
        document.title = 'Export CSV for Data Analytics - Analyse your data'; 
    }, []);

    /// Loading available Document Types and Templates /// 

    useEffect(() => {
        async function fetchYears() {
            const newYears = await getDocs(collection(db, "user_data", selectedCompany.id, "years")) 
            setYears(newYears.docs)
        }

        async function fetchTemplates() {
            const newTemplates = await getDocs(collection(db, "user_data", selectedCompany.id, "export-csv-templates"))
            setTemplates(newTemplates.docs)
        }

        fetchYears()
        fetchTemplates()
    }, [selectedCompany])


    /// Document Type Selection ///

    async function selectYear(year) {
        setSelectedYear(year)
        setYearsVisible(false)

        const form = await getDoc(doc(db, "user_data", selectedCompany.id, "years", year.id, "forms", "standard_form"))
        setCategories(form.data().categories)
        setRows(form.data().categories.map(category => category.fields).flat())
    }


    /// Template Selection ///

    function selectTemplate(template) {
        setSelectedTemplate(template.data().name)
        setSelectedRows(template.data().selectedRows)
        setTemplatesVisible(false)
    }


    /// Preparing Csv Download /// 

    function formatNumber(number) {
        return parseFloat((number || 0).toString().replace(/,/g , '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"
    }

    async function csvDownload() {

        const formFrame = await getDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "forms", "standard_form"))
        const subsidiaries = await getDocs(query(collection(db, "user_data", selectedCompany.id, "years", selectedYear.id, "subsidaries")))
        const subsidiariesDocs = subsidiaries.docs

        const headers = ["Document"]
        const data = []

        for (const category of formFrame.data().categories.filter(category => (category.type === "information" || category.type === "standard-transactions" || category.type === "numbers"))) {
            for (const field of category.fields) {
                if (selectedRows.includes(field.id)) {    
                    headers.push('"' + field.topic + '"')
                }
            }
        }

        for (const subsidiaryDoc of subsidiariesDocs) {
            const row = [subsidiaryDoc.id]

            for (const category of formFrame.data().categories.filter(category => category.type === "information")) {
                for (const field of category.fields) {
                    if (selectedRows.includes(field.id)) {
                        const matchFields = subsidiaryDoc.data().fieldValues.find(fieldValue => fieldValue.id === field.id) 
                        if (!matchFields) {
                            row.push("")
                        } else {
                            row.push('"' + matchFields.answer.replace("\n", "") + '"')
                        }
                    }
                }
            }

            for (const category of formFrame.data().categories.filter(category => (category.type === "standard-transactions" || category.type === "numbers"))) {
                for (const field of category.fields) {
                    if (selectedRows.includes(field.id)) {
                        const matchFields = subsidiaryDoc.data().fieldValues.find(fieldValue => fieldValue.id === field.id) 
                        if (!matchFields) {
                            row.push("") 
                        } else {
                            row.push(formatNumber(matchFields?.transaction_amount).toString().replace(/,/g , '?').replace(/[.]/g, ',').replace(/[?]/g, '.'))
                        }
                    }
                }
            }

            data.push(row)
        }

        const csvHeaderString = headers.join(";") + "\n"
        const csvDataString = data.map(row => row.join(";").replace(/"\n/g, '""').replace(/\n/g, ' ')).join(";\n")

        // console.log(data.map(row => row.join(";"))[30].replace(/"\n/g, '""').replace(/\n/g, ' '))

        const csvContent = '\uFEFF' + csvHeaderString + csvDataString;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = selectedCompany.id + "_" + selectedYear.id + '.csv';
    
        link.click();
    
        link.remove();
        window.URL.revokeObjectURL(link.href);
    } 
    



    /// Category Selection ///

    function selectCategories(index) {
        if (selectedCategories.includes(index)) {
            setSelectedCategories(selectedCategories.filter(categoryIndex => categoryIndex !== index)) 
            setSelectedRows(selectedRows.filter(rowIndex => !categories[index].fields.map(field => field.id).includes(rowIndex)))
        } else {
            setSelectedCategories([...selectedCategories, index])
            setSelectedRows([...selectedRows, categories[index].fields.map(field => field.id)].flat())
        }
    }

    function selectAllCategories() {
        if (allSelected) {
            setSelectedCategories([])
            setSelectedRows([])
            setAllSelected(false)
        } else {
            setSelectedCategories([...Array(categories.length).keys()])
            setSelectedRows(categories.map(category => category.fields.map(field => field.id)).flat())
            setAllSelected(true)
        }
    }


    /// Row Selection ///

    function selectRows(rowId) {
        if (selectedRows.includes(rowId)) {
            setSelectedRows(currSelectedRows => currSelectedRows.filter(currSelectedRow => currSelectedRow !== rowId))
        } else {
            setSelectedRows(currSelectedRows => [...currSelectedRows, rowId])
        }
    }


    /// Saving Template ///

    async function saveTemplate() {
        if (templateName !== "") {
            await addDoc(collection(db, "user_data", selectedCompany.id, "export-csv-templates"), {
                name : templateName, 
                selectedRows : selectedRows
            })
        }

    }


    /// Deleting Template ///

    async function deleteTemplate(template) {
        setTemplates(prev => prev.filter(prevTemplate => prevTemplate.id !== template.id)) 
        if (selectedTemplate === template.data().name) {
            setSelectedTemplate()

        }
        await deleteDoc(doc(db, "user_data", selectedCompany.id, "export-csv-templates", template.id))
    }


    /// HTML Component ///

    return (
    <div className='py-20'>
        <div className='rounded-lg bg-white drop-shadow-lg p-4 mb-10 mt-10 font-body w-[90%] md:w-[70%] xl:w-[40%] max-w-[45rem] mx-auto'>
            <div className='component-icon-container bg-sky-400'>
                <PresentationChartBarIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Export CSV for Data Analytics</p>
            <div className='flex justify-between mb-2 mt-3 mx-1.5 gap-x-1'>
                <BigIdDropdown 
                    width={"50%"}
                    color={"sky"}
                    options={years}
                    selectedOption={selectedYear}
                    dropdownVisible={yearsVisible}
                    setDropdownVisible={setYearsVisible}
                    placeholder={"Document Type"}
                    selectFunction={selectYear}
                />
                <div ref={templatesRef} className='w-[50%] relative'>
                    <button className='w-[100%] text-center border p-2 rounded-lg' onClick={() => setTemplatesVisible(prev => !prev)}>{selectedTemplate ? selectedTemplate  : "Template"}</button>
                    <div className='bg-white absolute w-[100%] px-1 drop-shadow-lg rounded-b-lg z-10'>
                        <div className={templatesVisible ? "max-h-48 overflow-auto" : "hidden"}>
                        {templates?.map((template, index) => (
                            <button key={index} className={'w-[100%] p-1 my-0.5 hover:bg-sky-400 hover:text-white rounded-md transition relative' + (selectedCategories.includes(index) ? " bg-sky-400 text-white" : "")} onClick={() => selectTemplate(template)}>{template.data().name}
                                <button className=' absolute right-0 top-0 bottom-0 my-auto hover:bg-red-500 rounded-md p-1' onClick={() => deleteTemplate(template)}>
                                    <TrashIcon className='w-6 h-6' />
                                </button>
                            </button>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
            <div ref={categoriesRef} className='w-[100%] px-1 relative'>
                <button className='w-[100%] text-center border p-2 rounded-lg' onClick={() => setCategoriesVisible(prev => !prev)}>{"Categories"}</button>
                <div className='bg-white absolute w-[100%] px-1 drop-shadow-lg rounded-b-lg z-10'>
                    <div className={categoriesVisible ? "max-h-48 overflow-auto" : "hidden"}>
                        <button className={'w-[100%] p-1 my-0.5 hover:bg-sky-400 hover:text-white rounded-md transition' + (allSelected ? " bg-sky-400 text-white" : "")} onClick={selectAllCategories} >All</button>
                    {categories?.map((category, index) => (
                        <button key={index} className={'w-[100%] p-1 my-0.5 hover:bg-sky-400 hover:text-white rounded-md transition' + (selectedCategories.includes(index) ? " bg-sky-400 text-white" : "")} onClick={() => selectCategories(index)} >{category?.name}</button>
                    ))}
                    </div>
                </div>
            </div>
            <div className='border rounded-lg p-1 mx-1 my-2 px-1 max-h-72 overflow-auto'>
                <div className='flex items-center border rounded-lg sticky top-0 bg-white my-1'>
                    <input className='focus:outline-none p-1 w-[100%] mx-1' onChange={(e) => setRowQuery(e.target.value)} />
                    <SearchIcon className='w-6 h-6 inline mx-2' />
                </div>
            {rows?.map((row, index) => (
                rowQuery.length === 0 || row.topic.toLowerCase().includes(rowQuery.toLowerCase())) && 
                <button key={index} onClick={() => selectRows(row.id)} className={'p-1 my-0.5 rounded-lg w-[100%] text-left' + (selectedRows.includes(row.id) ? " bg-sky-400 text-white" : " text-black")}>{row.topic}</button>       
            )}
            </div>
            <div className='flex mb-1'>
                <input value={templateName} onChange={(e) => setTemplateName(e.target.value)} placeholder='Template name' className='focus:outline-none border rounded-lg p-1 mx-1 my-1 w-[70%]' />
                <button onClick={saveTemplate} className='w-[30%] mx-1 rounded-lg my-1 bg-sky-400 text-white'>Save</button>
            </div>
            <div className='w-[100%] flex'>
                {/* <button className='w-[30%] text-center rounded-lg text-white p-2 mx-1 border border-sky-400 text-sky-400 hover:bg-sky-400 hover:text-white transition hover:shadow-md' onClick={prepareCsvDownload}>Create CSV</button> */}
                <button className='w-[100%] text-center rounded-lg bg-sky-400 text-white p-2 mx-1 border border-sky-400 transition-all hover:shadow-md' onClick={csvDownload}>Export CSV</button>
            </div>
        </div>
    </div>

    )
}



export default CreateCsv