import { doc, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase'

function FileAuthButton({ selectedCompany, subfolder, mainfolder, user, saveCall, setSaveCall, switchCall }) {

    const [subfolderData, setSubfolderData] = useState(subfolder.data())

    useEffect(() => {
        async function saveAuthorization() {
            await updateDoc(doc(db, "user_data", selectedCompany.id, "folders", mainfolder.id, "subfolders", subfolder.id), {
                "roles" : subfolderData.roles || {} 
            })

            setSaveCall(false)
        }


        if (saveCall) {
            saveAuthorization()
        }
    }, [saveCall])

    useEffect(() => {
        if (switchCall === "switchNeg") {
            setSubfolderData(prev => {
                const subfolderDataCopy = { ...prev };
                const rolesCopy = { ...(subfolderDataCopy.roles || {}) };
              
                if (rolesCopy[user.id] === "editor") {
                  // Deleting if role exists
                  delete rolesCopy[user.id];
                }
              
                subfolderDataCopy.roles = rolesCopy;
                return subfolderDataCopy;
            })
        } else if (switchCall === "switchPos") {
            setSubfolderData(prev => {
                console.log("Positive Switch")
                const subfolderDataCopy = { ...prev };
                const rolesCopy = { ...(subfolderDataCopy.roles || {}) };
              
                if (rolesCopy[user.id] !== "editor") {
                  rolesCopy[user.id] = "editor";
                }
              
                subfolderDataCopy.roles = rolesCopy;
                return subfolderDataCopy;
            })
        }
    }, [switchCall])

    function getAuthState() {

        if (user) {
            if (subfolderData?.roles?.[user.id] === "editor") {
                return "bg-green-400 "
            } else {
                return "bg-red-400 "
            }
        } else {
            return "bg-amber-400 "
        }
    }

    function changeFileAuth() {
        setSubfolderData(prev => {
            const subfolderDataCopy = { ...prev };
            const rolesCopy = { ...(subfolderDataCopy.roles || {}) };
          
            if (rolesCopy[user.id] === "editor") {
              // Deleting if role exists
              delete rolesCopy[user.id];
            } else {
              // Add if role does not exist
              rolesCopy[user.id] = "editor";
            }
          
            subfolderDataCopy.roles = rolesCopy;
            return subfolderDataCopy;
        })
    }


    return (
        <button onClick={changeFileAuth} className={'auth-button ' + getAuthState()}>{subfolder.data().name}</button>
    )
}

export default FileAuthButton