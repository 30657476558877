import React from 'react'

function NumbersInput({ field, transaction_amount, done, category, handleNumbersChange, numberWithCommas }) {
    return (
        <>
            {(field.formula !== "") &&
                <div className='text-right'>
                    <span className='mx-auto'>{numberWithCommas(Math.round((transaction_amount) * 100) / 100 || "")}</span>
                </div>
                
            }
                                                                    
            {(field.formula === "" && (done || false)) &&
                <div className='text-right'>
                    <span className='w-[100%] p-1 text-right'>{transaction_amount || ""}</span>
                </div>
                
            }                                                        

            {(field.formula === "" && !(done || false)) && 
                <textarea className='w-[100%] border rounded-lg p-1 text-right' value={(transaction_amount || "").toString()} onChange={(e) => handleNumbersChange(e, field.id, category.type)}></textarea>
            }

        </>
    )
}

export default NumbersInput