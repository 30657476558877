import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import { useAuth } from "./firebase";
import Navbar from "./components/Navbar";
import { useState } from "react";
import Welcome from "./components/Welcome";
import Login from "./pages/Login/Login";
import Workflow from "./pages/Workflow/Workflow";
import ImportCsv from "./pages/ImportCsv/ImportCsv";
import EditForm from "./pages/EditFormTemplate/EditForm";
import EditTemplate from "./pages/EditFormTemplate/EditTemplate";
import ManageUsers from "./pages/ManageUsers/ManageUsers";
import ManageCompanies from "./pages/ManageCompanies/ManageCompanies";
import Form from "./pages/SelectClient/Form";
import ManageLocalFileTemplates from "./pages/ManageLocalFiles/ManageLocalFileTemplates";
import Files from "./pages/Files/Files";
import CreateCsv from "./pages/Analytics/CreateCsv";
import ResetPassword from "./pages/Help/ResetPassword";
import CreateProjectCsv from "./pages/Analytics/CreateProjectCsv";
import CreateStatusCsv from "./pages/Analytics/CreateStatusCsv";
import LocalFilePanel from "./pages/CreateLocalFiles/LocalFilePanel";
import ContactUs from "./pages/Help/ContactUs";
import DocumentPanel from "./pages/CreateDocument/DocumentPanel";
import SelectForm from "./pages/SelectClient/SelectForm";
import CreateContentCsv from "./pages/IDRAnalytics/CreateContentCsv";
import NotifyStaff from "./pages/NotifyStaff/NotifyStaff";
import MailingBoard from "./pages/NotifyStaff/MailingBoard";
import EditEmailTemplate from "./pages/NotifyStaff/EditEmailTemplate";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const currentUser = useAuth();
  const [selectedCompany, setSelectedCompany] = useState();
  const [superUser, setSuperUser] = useState();
  const [masterUser, setMasterUser] = useState();
  const [clientManager, setClientManager] = useState();
  const [singleUser, setSingleUser] = useState();

  /// HTML Component ///

  return (
    <Router>
      <div className="w-[100%]">
        <Navbar
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          singleUser={singleUser}
          superUser={superUser}
          masterUser={masterUser}
          clientManager={clientManager}
          setSingleUser={setSingleUser}
          setSuperUser={setSuperUser}
          setMasterUser={setMasterUser}
          setClientManager={setClientManager}
        />

        <div className="dashboard-bg md:block w-[100%] mx-auto relative top-0 min-h-screen z-10 h-[100%] flex flex-col">
          <div className="absolute text-white -z-10 bottom-2 right-3 font-body text-sm">
            <span className="select-none">TPX Editor © 2024</span>
          </div>
          <div className="h-[65px]"></div>
          <div>
            <Routes>
              <Route path="" element={<Welcome />} />
              <Route path="/login" element={<Login />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/welcome" element={currentUser ? <Welcome /> : <PageNotFound />} />
              <Route
                path="/select-client"
                element={currentUser ? <Form selectedCompany={selectedCompany} singleUser={singleUser} superUser={superUser} masterUser={masterUser} /> : <PageNotFound />}
              />
              <Route
                path="/select-form"
                element={currentUser ? <SelectForm selectedCompany={selectedCompany} singleUser={singleUser} superUser={superUser} masterUser={masterUser} /> : <PageNotFound />}
              />
              <Route path="/create-localfile" element={currentUser ? <LocalFilePanel selectedCompany={selectedCompany} /> : <PageNotFound />} />
              <Route path="/create-document" element={currentUser ? <DocumentPanel selectedCompany={selectedCompany} /> : <PageNotFound />} />

              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/files" element={currentUser ? <Files selectedCompany={selectedCompany} masterUser={masterUser} superUser={superUser} /> : <PageNotFound />} />

              {(superUser || masterUser) && (
                <>
                  <Route path="/create-project-csv" element={currentUser ? <CreateProjectCsv selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/create-status-csv" element={currentUser ? <CreateStatusCsv selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/create-csv" element={currentUser ? <CreateCsv selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/workflow" element={currentUser ? <Workflow selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/create-content-csv" element={currentUser ? <CreateContentCsv selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/notify-staff" element={currentUser ? <MailingBoard selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/edit-email-template" element={currentUser ? <EditEmailTemplate selectedCompany={selectedCompany} /> : <PageNotFound />} />
                </>
              )}

              {(masterUser || clientManager) && (
                <>
                  <Route path="/import-csv" element={currentUser ? <ImportCsv selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/edit-template" element={currentUser ? <EditTemplate /> : <PageNotFound />} />
                  <Route path="/edit-form" element={currentUser ? <EditForm selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/manage-users" element={currentUser ? <ManageUsers selectedCompany={selectedCompany} /> : <PageNotFound />} />
                  <Route path="/manage-lf-templates" element={currentUser ? <ManageLocalFileTemplates selectedCompany={selectedCompany} /> : <PageNotFound />} />
                </>
              )}
              {masterUser && (
                <>
                  <Route path="/manage-companies" element={currentUser ? <ManageCompanies setSelectedCompany={setSelectedCompany} selectedCompany={selectedCompany} /> : <PageNotFound />} />
                </>
              )}

              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
        </div>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;
