import { ArrowDownIcon, ArrowSmDownIcon, ChevronDownIcon, InformationCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import React, { useEffect } from 'react'
import ForgotPasswordForm from './ForgotPasswordForm'

function ResetPassword() {

    /// Title Specification ///
    
    useEffect(() => {
        document.title = 'Reset Password - Get an Email to Reset your Passwor'; 
    }, []);
   

    /// HTML Component ///

    return (
        <div className='font-body'>
            <div className='rounded-lg bg-white p-4 m-10 font-body w-[85%] xl:w-2/5 mx-auto shadow-lg'>
                <div className='component-icon-container bg-indigo-400'>
                    <RefreshIcon className='w-8 h-8' /> 
                </div>
                <p className='text-2xl p-1 m-1'>Reset Password</p>
                <div className='mt-3 p-2 text-xl text-center relative'>
                    <div className='p-4 shadow-around rounded-xl mb-3 relative '>
                        <InformationCircleIcon className='w-7 h-7 mx-auto mb-1' />We recommend resetting your password after you logged in for the first time.
                        <div className='absolute rounded-full bg-indigo-500 text-white p-2 left-0 right-0 mx-auto w-max -bottom-7 shadow-lg'><ArrowDownIcon className='w-8 h-8' /></div>
                    </div>


                    <ForgotPasswordForm title="" />
                </div>
            </div>
        </div>
    )
}

export default ResetPassword