import { PresentationChartBarIcon, SearchIcon, TableIcon, TrashIcon } from '@heroicons/react/outline'
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { CSVDownload, CSVLink } from 'react-csv'
import { db, shortify } from '../../firebase'
import BigIdDropdown from '../../components/BigIdDropdown'


function CreateContentCsv({ selectedCompany }) {

    const [entries, setEntries] = useState([])
    const [selectedEntry, setSelectedEntry] = useState()

    const [entriesVisible, setEntriesVisible] = useState(false)

    const [csvData, setCsvData] = useState([])
    const [csvHeaders, setCsvHeaders] = useState([])

    const [categoriesVisible, setCategoriesVisible] = useState()

    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([])

    const [rows, setRows] = useState()
    const [selectedRows, setSelectedRows] = useState([])
    const [rowQuery, setRowQuery] = useState("")

    const [allSelected, setAllSelected] = useState()

    const [templateName, setTemplateName] = useState("")

    const [templates, setTemplates] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [templatesVisible, setTemplatesVisible] = useState()


    /// Title Specification ///

    useEffect(() => {
        document.title = 'Export CSV for Data Analytics - Analyse your data'; 
    }, []);


    /// Loading available Document Types and Templates /// 

    useEffect(() => {
        async function fetchEntries() {
            const fetchedEntries = await getDocs(collection(db, "user_data", selectedCompany.id, "special_entries")) 
            setEntries(fetchedEntries.docs)
        }

        fetchEntries()
    }, [selectedCompany])


    /// Document Type Selection ///

    async function selectEntry(entry) {
        setSelectedEntry(entry)
        setEntriesVisible(false)

    }


    /// Preparing Csv Download /// 

    function formatNumber(number) {
        return parseFloat((number || 0).toString().replace(/,/g , '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"
    }

    async function csvDownload() {

        const formFrame = await getDoc(doc(db, "user_data", selectedCompany.id, "special_forms", selectedEntry.id))

        const headers = ["IDR Form"]
        const data = []

        for (const category of formFrame.data().categories.filter(category => (category.type === "information" || category.type === "standard-transactions" || category.type === "numbers"))) {
            for (const field of category.fields) {
                headers.push('"' + field.topic + '"')
            }
        }


        const row = [selectedEntry.id]

        for (const category of formFrame.data().categories.filter(category => category.type === "information")) {
            for (const field of category.fields) {
                const matchFields = selectedEntry.data().fieldValues.find(fieldValue => fieldValue.id === field.id) 
                if (!matchFields) {
                    row.push("")
                } else {
                    row.push('"' + matchFields.answer.replace("\n", "") + '"')
                }
            }
        }

        for (const category of formFrame.data().categories.filter(category => (category.type === "standard-transactions" || category.type === "numbers"))) {
            for (const field of category.fields) {
                const matchFields = selectedEntry.data().fieldValues.find(fieldValue => fieldValue.id === field.id) 
                if (!matchFields) {
                    row.push("") 
                } else {
                    row.push(formatNumber(matchFields?.transaction_amount).toString().replace(/,/g , '?').replace(/[.]/g, ',').replace(/[?]/g, '.'))
                }
            }
        }

        data.push(row)


        const csvHeaderString = headers.join(";") + "\n"
        const csvDataString = data.map(row => row.join(";").replace(/"\n/g, '""').replace(/\n/g, ' ')).join(";\n")


        const csvContent = '\uFEFF' + csvHeaderString + csvDataString;
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = selectedCompany.id + "_" + selectedEntry.id + '.csv';
    
        link.click();
    
        link.remove();
        window.URL.revokeObjectURL(link.href);
    } 
    



    /// HTML Component ///

    return (
        <div className='py-20'>
            <div className='component-container mb-10 font-body sm:w[80%] md:w-[70%] lg:w-[35%] xl:w[20%] mx-auto mt-40'>
                <div className='component-icon-container bg-sky-400'>
                    <TableIcon className='w-8 h-8' />
                </div>
                <p className='text-2xl p-1 m-1'>Export Content CSV</p>
                <div className='flex justify-between mb-2 mt-3 px-2'>
                    <BigIdDropdown 
                        width={"100%"}
                        color={"sky"}
                        options={entries}
                        selectedOption={selectedEntry}
                        dropdownVisible={entriesVisible}
                        setDropdownVisible={setEntriesVisible}
                        placeholder={"Form"}
                        selectFunction={selectEntry}
                    />
                </div>
                <div className='w-[100%] px-1 flex'>
                    <button onClick={csvDownload} className='w-[100%] text-center rounded-lg bg-sky-400 text-white p-2 mx-1 border border-sky-400 transition-all hover:shadow-md'>Export CSV</button>
                </div>
            </div>
        </div>

    )
}



export default CreateContentCsv