import { CloudUploadIcon } from '@heroicons/react/outline'
import { addDoc, collection } from 'firebase/firestore'
import React, { cloneElement, useState } from 'react'
import { db, saveTemplate, shortify, storage, useAuth } from '../../firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'


function UploadTemplate({ selectedCompany }) {

    const currentUser = useAuth()
    const [templateType, setTemplateType] = useState("Localfile")

    const [file, setFile] = useState(null)
    const [fileName, setFileName] = useState()


    /// Handling File Choose & Upload ///

    function handleFileChoose(e) {
        if (e.target.files[0]) {
            setFile(e.target.files[0])
            setFileName(e.target.files[0].name)
        }
    }



    async function handleUpload() { 
        if (templateType === "Localfile") {
            const idSnippet = Date.now() + Math.random()
    
            const fileRef = ref(storage, selectedCompany.id + "/" + "templates-localfile/" + idSnippet + '.docx'); 
            const snapshot = await uploadBytes(fileRef, file);
            const photoURL = await getDownloadURL(fileRef);
        
            await addDoc(collection(db, "user_data", selectedCompany.id, "templates"), {
                name : fileName,
                url : photoURL,
                storageId: idSnippet + '.docx',
                relatedSubsidaries: []
            });
        } else if (templateType === "Document") {
            const idSnippet = Date.now() + Math.random()
    
            const fileRef = ref(storage, selectedCompany.id + "/" + "templates-idr/" + idSnippet + '.docx'); 
            const snapshot = await uploadBytes(fileRef, file);
            const photoURL = await getDownloadURL(fileRef);
        
            await addDoc(collection(db, "user_data", selectedCompany.id, "special_templates"), {
                name : fileName,
                url : photoURL,
                storageId: idSnippet + '.docx',
                relatedEntries: []
            });
        }
    }


    /// HTML Component ///

    return (
        <div className='component-container w-[80%] lg:w-[55%] xl:w-[40%]'>
            <div className='component-icon-container bg-blue-400'>
                <CloudUploadIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Upload Topic Template</p>
            <div className='mt-3'>
                <div className='flex'>
                    <button onClick={() => setTemplateType("Localfile")} className={'w-[50%] rounded-lg border hover:bg-blue-400 hover:text-white border-blue-400 p-1 mx-2 transition-all ' + (templateType === "Localfile" ? " bg-blue-400 text-white" : " text-blue-400")}>Topic Template</button>
                    <button onClick={() => setTemplateType("Document")} className={'w-[50%] rounded-lg border hover:bg-blue-400 hover:text-white border-blue-400 p-1 mr-2 transition-all ' + (templateType === "Document" ? " bg-blue-400 text-white" : " text-blue-400")}>IDR Template</button>
                </div>
                <div className='flex justify-between'> 
                    <div className='w-2/3 border bg-white text-black rounded-lg m-2 hover:bg-blue-400 hover:text-white hover:border-transparent transition hover:shadow-md'>
                        <label className='w-[100%] text-center bg-transparent flex justify-center px-2 py-4' htmlFor="upload-template">{fileName ? shortify(fileName, 30) : "Browse"}</label>
                        <input id='upload-template' className='hidden' type="file" onChange={handleFileChoose} />
                    </div>
                    <button className='w-1/3 border text-black rounded-lg px-2 py-4 m-2 hover:bg-blue-400 hover:text-white hover:border-transparent transition hover:shadow-md' onClick={handleUpload}>Upload</button>
                </div>
            </div>
        </div>
    )
}

export default UploadTemplate