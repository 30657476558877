import { MinusSmIcon } from '@heroicons/react/outline'
import React from 'react'


const DeleteCategory = React.memo(({ deleteCategoryIndex, setFormData, setDeletedRows, deleteCategoriesVisible}) => {


    function deleteCategory(categoryIndex) {
        setFormData(prev => {
            const updatedFormData = {... prev}

            setDeletedRows(prevDeletedRows => {
                const updatedDeletedRows = prevDeletedRows
    
                for (const field of updatedFormData.categories[categoryIndex].fields) {
                    const deleteId = field.id
                    updatedDeletedRows.push(deleteId)
                }
        
                setDeletedRows(updatedDeletedRows)
            })
    
            updatedFormData.categories.splice(categoryIndex, 1)
    
            return updatedFormData
        })
    }


    return (
        <div className={'absolute right-8 flex items-center ' + (deleteCategoriesVisible ? "" : "hidden")}>
            <button onClick={() => deleteCategory(deleteCategoryIndex)} className='w-max text-red-600 border-2 border-red-600 rounded-full cursor-pointer p-1.5 bg-white hover:bg-red-600 hover:text-white'>
                <MinusSmIcon className='w-5 h-5 ' />
            </button>
        </div>
    )
})

export default DeleteCategory