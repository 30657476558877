import { ClipboardListIcon, TableIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useOutsideClick } from '../../firebase'
import WorkflowPDF from './WorkflowPDF';
import { CSVLink } from 'react-csv';
import ColName from './ColName';
import BigIdDropdown from '../../components/BigIdDropdown';


function Workflow({ selectedCompany }) {

    const [years, setYears] = useState([])
    const [subs, setSubs] = useState([])

    const [selectedYear, setSelectedYear] = useState()
    const [selectedSubs, setSelectedSubs] = useState([])

    const [yearsVisible, setYearsVisible] = useState(false)
    const [subsidiariesVisible, setSubsidiariesVisible] = useState(false)

    const [tableCols, setTableCols] = useState([])
    const [tableVals, setTableVals] = useState([])
    const [tableSubs, setTableSubs] = useState([])
    const [tableProgress, setTableProgress] = useState([])
    const [totalFields, setTotalFields] = useState(0)

    const subsidiariesRef = useRef()
    useOutsideClick(subsidiariesRef, () => setSubsidiariesVisible(false))

    // Table States


    /// Set Webpage Title

    useEffect(() => {
        document.title = 'Workflow - Get an Overview over current Status'; 
    }, []);


    /// Load Document Types

    useEffect(() => {
        async function fetchYears() {
            const newYears = await getDocs(collection(db, "user_data", selectedCompany?.id, "years")) 
            setYears(newYears.docs)
        }

        fetchYears()
    }, [selectedCompany])

    /// Document Type Selection -> Fetch Form and Documents, create Color List ///

    // const answerOpts = useState([])

    async function selectYear(year) {
        setYearsVisible(false)
        setSelectedYear(year)

        var existingFields = 0

        const yearForm = await getDoc(doc(db, "user_data", selectedCompany?.id, "years", year.id, "forms", "standard_form"))        
        
        // Counting Fields
        yearForm.data().categories.forEach(category => {
            existingFields += category.fields.length
        });


        const fetchedSubs = await getDocs(collection(db, "user_data", selectedCompany?.id, "years", year.id, "subsidaries"))
        const subsidiaries = fetchedSubs.docs.map((sub, index) => sub.id)
        const progress = fetchedSubs.docs.map((sub, index) => sub.data().fieldValues.filter(fieldValue => fieldValue.done === true).length)


        const workflowCols = yearForm.data().categories.find(category => category.name === "Workflow")?.fields

        if (workflowCols) {
            const workflowVals = fetchedSubs.docs.map((sub, index) => {
                const row = []
                
                for (const col of workflowCols) {
                    const val = sub.data().fieldValues.find(value => value.id === col.id)
                    if (val) {
                        row.push(val)
                    } else {
                        row.push("")
                    }
                }
    
                return row
            })

            setTableVals(workflowVals)
            setTableCols(workflowCols)
        } else {
            setTableVals([])
            setTableCols([])
        }




        // Calculcate Progress of every subsidiary

        setTableSubs(subsidiaries)
        setSelectedSubs(subsidiaries)
        setTableProgress(progress)
        setTotalFields(existingFields)
    }


    function evalProgressColor(progress) {

        if (progress === 0) {
            return " bg-red-300 border-red-300"
        } else if ((progress / totalFields) < 0.35) {
            return " bg-red-200 border-red-200"
        } else if (progress / totalFields < 0.5) {
            return " bg-orange-200 "
        } else if (progress / totalFields < 0.7) {
            return " bg-yellow-200 border-yellow-200"
        } else if (progress / totalFields < 0.9) {
            return " bg-green-200 border-green-200"
        } else if (progress / totalFields < 1) {
            return " bg-green-300 border-green-300"
        } else {
            return " bg-green-400 border-green-400"
        }
    }


    /// Preparing Csv Download /// 

    const [csvHeaders, setCsvHeaders] = useState([])
    const [csvData, setCsvData] = useState([])


    async function prepCsvDownload() {

        const cols = tableCols
        const vals = tableVals
        const subs = tableSubs
        const progress = tableProgress

        const headers = [
            {label : "Code", key : "Code"}
        ]
        const data = []

        // Setting Headers
        for (const col of cols) {
            const row = {}
            row.label = col.topic
            row.key = col.topic
            headers.push(row)
        }
        headers.push({label: "Progress", key: "Progress"})


        // Setting Data
        for (const [i, valRow] of Object.entries(vals)) {
            if (!selectedSubs.includes(subs[i])) {
                continue;
            }

            const row = {}


            row["Code"] = subs[i]

            for (const [j, val] of Object.entries(valRow)) {
                row[cols[j].topic] = val.answer
            }

            row["Progress"] = progress[i] + "/" + totalFields

            data.push(row)
        }

        setCsvData(data)
        setCsvHeaders(headers)
    }

    useEffect(() => {
        if (selectedSubs) {
            prepCsvDownload()
        }

    }, [selectedSubs])

    const tableElement = useRef()

    return (
        <div className=''>
            <div className='w-[100%] bg-white font-body relative'>
                <div className='flex items-center pb-6 border-b fixed w-[100%] z-20 p-4 bg-white'>
                    <div className='p-1.5 rounded-full bg-teal-400 text-white w-max'>
                        <ClipboardListIcon className='w-8 h-8' />
                    </div>
                    <div>
                        <span className='text-2xl p-1 ml-3'>Workflow</span>
                    </div>
                    <div className='ml-auto mr-0 flex'>

                        {/* Export Buttons */}

                        <CSVLink separator={";"} data={csvData} headers={csvHeaders} className='flex items-center mr-2 rounded-lg bg-teal-400 text-white px-4 transition-all hover:bg-teal-500'>
                            <div className='mr-1'>
                                <TableIcon className='w-5 h-5' />
                            </div>
                            CSV
                        </CSVLink>
                        <WorkflowPDF 
                            tableCols={tableCols}
                            vals={tableVals}
                            subs={tableSubs}
                            progress={tableProgress}
                            totalFields={totalFields}
                            selectedSubs={selectedSubs}
                        />

                        {/* Document Type and Document Selection */}

                        <BigIdDropdown 
                            placeholder={"Document Type"}
                            width={"12rem"}
                            color={"teal"}
                            selectedOption={selectedYear}
                            selectFunction={selectYear}
                            options={years}
                            dropdownVisible={yearsVisible}
                            setDropdownVisible={setYearsVisible}
                        />
                        <div ref={subsidiariesRef} className='relative mx-1 w-36'>
                            <button className='w-[100%] border rounded-lg py-2 hover:bg-neutral-50 transition-all' onClick={() => setSubsidiariesVisible(prev => !prev)}>
                                {selectedYear ? ((tableSubs?.sort().join(',') === selectedSubs?.sort().join(',') && selectedSubs?.length) ? "All Documents" : selectedSubs?.length + " Documents") : "Documents"}
                            </button>
                            <div className='absolute bg-white w-[100%] px-1 drop-shadow-lg rounded-b-lg z-20 max-h-60 overflow-y-auto'>
                                <div className={subsidiariesVisible ? "" : "hidden"}>
                                    <button 
                                        className={'w-[100%] p-1 my-1 hover:bg-teal-400 hover:text-white rounded-md transition' + (tableSubs?.sort().join(',') === selectedSubs?.sort().join(',') ? " bg-teal-400 text-white" : "")}
                                        onClick={() =>{setSelectedSubs(prev => tableSubs?.sort().join(',') === prev?.sort().join(',') ? [] : tableSubs)}}>
                                        All Documents
                                    </button>
                                {tableSubs?.map((sub, index) => (
                                    <button 
                                        onClick={() => setSelectedSubs(prev => prev.includes(sub) ? prev.filter(prevSub => prevSub !== sub) : [ ...prev, sub ])} 
                                        key={index} 
                                        className={'w-[100%] p-1 mb-0.5 hover:bg-teal-400 hover:text-white rounded-md transition ' + (selectedSubs.includes(sub) ? " bg-teal-400 text-white" : "")}>
                                        {sub}
                                    </button>
                                ))}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                
                {/* Table */}

                {(selectedYear && totalFields) &&
                <div className='top-[105px] relative z-10 px-4 pb-4 min-h-screen'>
                    <table ref={tableElement} className='w-[100%]'>
                        <thead>
                            <tr className='border'>

                            {["Code", ...tableCols.map(col => col.topic), "Progress"].map((col, index) => (
                                <ColName key={index} tableElement={tableElement} cellContent={col} index={index} />
                            ))}
                                
                            
                            </tr>
                        </thead>
                        <tbody>
                        {tableSubs.map((tableSub, i) => (
                            selectedSubs.includes(tableSubs[i]) &&
                            <tr key={i} className='border'>
                                <th className='border text-sm bg-neutral-100'>{tableSub}</th>
                            {tableVals[i]?.map((val, j) => (
                                <th key={j} className='border text-sm bg-neutral-100' style={tableCols?.[j]?.answer_possibilities?.find(opt => opt.opt === val.answer)?.color && {"backgroundColor": tableCols?.[j]?.answer_possibilities?.find(opt => opt.opt === val.answer)?.color, "borderColor": tableCols?.[j]?.answer_possibilities?.find(opt => opt.opt === val.answer)?.color}}>
                                    {val.answer}
                                </th>
                            ))}
                                <th className={'border text-sm' + (evalProgressColor(tableProgress[i]))}>{tableProgress[i] + " / " + totalFields}</th>
                                <th className={'border text-sm' + (evalProgressColor(tableProgress[i]))}>{(tableProgress[i] / totalFields).toFixed(2) * 100 + "%"}</th>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                }
            </div>
        </div>

    )
}

export default Workflow