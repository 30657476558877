import { ArrowDownIcon, ArrowUpIcon, CheckIcon, ClockIcon, FilterIcon, MailIcon, MinusSmIcon, PaperAirplaneIcon, PlusSmIcon, SearchIcon, XIcon } from '@heroicons/react/outline'
import { FieldPath, Timestamp, addDoc, collection, doc, documentId, getDoc, getDocs, limit, orderBy, query, serverTimestamp, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { animateButton, db, useFetchYears } from '../../firebase'
import axios from 'axios'
import SubsidiaryButton from './SubsidiaryButton'
import UserButton from './UserButton'
import AddUserButton from './AddUserButton'
import SendEmailButton from './SendEmailButton'
import UserFilter from './UserFilter'
import IdDropdown from '../../components/IdDropdown'

function NotifyStaff({ selectedCompany }) {

    // States

    const [users, setUsers] = useState([])
    const years = useFetchYears(selectedCompany)
    const [subsidiaries, setSubsidiaries] = useState([])

    const [selectedYear, setSelectedYear] = useState()
    const [selectedSubsidiaries, setSelectedSubsidiaries] = useState({})

    const [yearForm, setYearForm] = useState()
    const [order, setOrder] = useState("asc")
    const [yearsVisible, setYearsVisible] = useState(false)

    const [mode, setMode] = useState("selectSub")
    const [emailYear, setEmailYear] = useState()
    const [emailSubsidiary, setEmailSubsidiary] = useState()

    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedSuggestion, setSelectedSuggestion] = useState()

    const [searchSubsidiary, setSearchSubsidiary] = useState("")

    useEffect(() => {

        // reset all states when selected company changes

        setMode("selectSub")
        setSubsidiaries([])
        setSelectedYear()
        setSearchSubsidiary("")
        setSelectedSuggestion()
    }, [selectedCompany])



    async function fetchSubsidiaries(year, fetchedForm) {
        const fetchedSubsidiares = await getDocs(collection(db, "user_data", selectedCompany.id, "years", year.id, "subsidaries"))

        // Sort new array based based on progress
        const sortedSubsidiaries = fetchedSubsidiares.docs
        sortedSubsidiaries.sort((a, b) => (getPercentageDirect(a, fetchedForm)) - (getPercentageDirect(b, fetchedForm)))
        setSubsidiaries(sortedSubsidiaries)
    }


    async function fetchYearForm(yearId) {
        const fetchedForm = await getDoc(doc(db, "user_data", selectedCompany.id, "years", yearId, "forms", "standard_form"))
        setYearForm(fetchedForm)
        return fetchedForm
    }


    // Selection

    async function selectYear(year) {
        setSelectedYear(year)
        setYearsVisible(false)
        const fetchedYearForm = await fetchYearForm(year.id)
        fetchSubsidiaries(year, fetchedYearForm)   
    }


    async function selectSubsidiary(subsidiary) {

        var users = []

        if (Object.keys(subsidiary.data().roles).length > 0) {
            const q = query(collection(db, "user_data", selectedCompany.id, "users"), where(documentId(), "in", Object.keys(subsidiary.data().roles)))
            const fetchedUsers = await getDocs(q)
            users = fetchedUsers.docs
        }


        setSelectedSubsidiaries(prev => {
            if (prev?.[selectedYear.id]?.[subsidiary.id]) {
                const yearCopy = {...prev?.[selectedYear.id]}
                delete yearCopy?.[subsidiary.id]
                return {...prev, [selectedYear.id] : yearCopy}
            } else {
                const newSelectedSubsidiaries = {...prev, [selectedYear.id] : {...(prev?.[selectedYear.id] || {}), [subsidiary.id] : users}}
                if (Object.keys(newSelectedSubsidiaries).length === 1 && Object.keys(newSelectedSubsidiaries[Object.keys(newSelectedSubsidiaries)[0]]).length === 1) {
                    setEmailYear(Object.keys(newSelectedSubsidiaries)[0])
                    setEmailSubsidiary(Object.keys(newSelectedSubsidiaries[Object.keys(newSelectedSubsidiaries)[0]])[0])
                }
                return {...prev, [selectedYear.id] : {...(prev?.[selectedYear.id] || {}), [subsidiary.id] : users}}
            }
        })
    }

    function handleChangeOrder() {
        setOrder(prev => prev === "asc" ? "desc" : "asc")
        setSubsidiaries(prev => [...prev].reverse())
    }


    // User related Functionality



    // Evaluation

    function getPercentage(subsidiary) {
        const fieldsDone = subsidiary.data().fieldValues.reduce((count, item) => {
            return count + (item.done === true ? 1 : 0);
        }, 0);

        var fieldsTotal = 0

        yearForm?.data().categories.forEach(category => {
            fieldsTotal += category.fields.length
        });

        return {fieldsDone, fieldsTotal}
    }

    function getPercentageDirect(subsidiary, fetchedYearForm) {
        const fieldsDone = subsidiary.data().fieldValues.reduce((count, item) => {
            return count + (item.done === true ? 1 : 0);
        }, 0);

        var fieldsTotal = 0

        fetchedYearForm?.data().categories.forEach(category => {
            fieldsTotal += category.fields.length
        });

        return fieldsDone / fieldsTotal
    }


    function getPercentageColor(subsidiary) {

        const { fieldsDone, fieldsTotal} = getPercentage(subsidiary)
        const progress = fieldsDone / fieldsTotal

        if (progress === 0) {
            return " bg-red-300 border-red-300"
        } else if (progress < 0.35) {
            return " bg-red-200 border-red-200"
        } else if (progress < 0.5) {
            return " bg-orange-200 "
        } else if (progress < 0.7) {
            return " bg-yellow-200 border-yellow-200"
        } else if (progress < 0.9) {
            return " bg-green-200 border-green-200"
        } else if (progress < 1) {
            return " bg-green-300 border-green-300"
        } else {
            return " bg-green-400 border-green-400"
        }
    }


    function convertToDate(ut) {
        const utcMilliseconds = ut.seconds * 1000 + ut.nanoseconds / 1e6
        const utcDate = new Date(utcMilliseconds)
        return utcDate.getDay() + "." + utcDate.getMonth() + "." + utcDate.getUTCFullYear()
    }


    async function getLastNotficationTime(year, subsidiary, email) {
        const notificationQuery = query(
            collection(db, "user_data", selectedCompany.id, "notfication-log"), 
            where("user", "==", email), 
            where("year", "==", year), 
            where("subsidiary", "==", subsidiary),
            orderBy("createdAt"),
            limit(1)
        )
        const lastNotification = await getDocs(notificationQuery)
        return convertToDate(lastNotification.docs[0].createdAt)
    }


    // Sending Emails

    async function notifyStaff(setAnimation) {
        // Email Addresses, Missing Document Types, Missing Documents, Missing Fields
        // {email_1 : {year_1 : {subsidiary_1 : [fields], subsidiary_2: [fields]}, year_2 : {}}, email_2: {}}

        animateButton(setAnimation, 1000)

        // Create Notification Order

        const notificationOrder = {}
        const nameDescription = {}

        for (const [year, yearSubsidiaries] of Object.entries(selectedSubsidiaries)) {

            // Get Form

            const form = await getDoc(doc(db, "user_data", selectedCompany.id, "years", year, "forms", "standard_form"))

            for (const [subsidiary, subUsers] of Object.entries(yearSubsidiaries)) {

                // Get Document Data

                const fetchedSub = await getDoc(doc(db, "user_data", selectedCompany.id, "years", year, "subsidaries", subsidiary))
                const subsdiaryData = fetchedSub.data()

                // Get Staff Emails

                const q = query(collection(db, "user_data", selectedCompany.id, "users"), where(documentId(), "in", subUsers.map(user => user.id)))
                const users = await getDocs(q)
                const emails = users.docs.map(user => user.data().email)

                for (const user of users.docs) {
                    if (!nameDescription[user.data().email]) {
                        nameDescription[user.data().email] = {firstName: user.data().firstName, lastName: user.data().lastName}
                    }
                }
                

                // Get Missing Fields

                const missingFields = []
                form.data().categories.map(category => {
                    category.fields.map(field => {
                        if (subsdiaryData.fieldValues.map(fieldValue => fieldValue.id).includes(field.id)) {
                            if (subsdiaryData.fieldValues.filter(fieldValue => !fieldValue.done).map(fieldValue => fieldValue.id).includes(field.id)) {
                                missingFields.push(field.topic)
                            }
                        } else {
                            missingFields.push(field.topic)
                        }
                    })
                })

                // Write Notification Order

                for (const email of emails) {

                    notificationOrder[email] = {
                        ...notificationOrder[email] || {}, 
                        [year] : {
                            ...((notificationOrder[email] || {})[year]) || {},
                            [subsidiary] : missingFields
                        }
                    }
                }
            }
        }

        // Send Notification Request

        axios.post("https://tax-mind-tools.de/reminder-email", {
            notificationOrder, 
            nameDescription,
            subject: selectedTemplate.data().subject, 
            content: selectedTemplate.data().content
        })
            .then((response) => {
                console.log('Response:', response.data);
            })
            .catch((error) => {
              console.error('Error:', error);
            });
            
        
        
        // Save used Template
        const usedTemplate = await addDoc(collection(db, "user_data", selectedCompany.id, "email-templates-log"), {
            content: selectedTemplate.data().content,
            subject: selectedTemplate.data().subject,
            name: selectedTemplate.data().name
        })

        
        // Write in Notification History
        for (const [email, emailData] of Object.entries(notificationOrder)) {
            for (const [yearId, yearData] of Object.entries(emailData)) {
                for (const [subsidiaryId, fields] of Object.entries(yearData)) {
                    console.log("SelectedCompany: " + selectedCompany.id)
                    await addDoc(collection(db, "user_data", selectedCompany.id, "notification-log"), {
                        createdAt: serverTimestamp(),
                        template: usedTemplate.id,
                        year: yearId,
                        subsidiary: subsidiaryId,
                        missingGaps: fields,
                        user: email
                    })
                }
            }
        } 
    }


    



    // JSX

    return (
        <div className='h-[95vh] bg-white w-[100%] lg:w-[60%] px-3'>
            <div className='bg-white p-4 mb-10 font-body w-[100%] h-[100%] mx-auto'>
                <div className='component-icon-container bg-sky-400'>
                    <MailIcon className='w-8 h-8' />
                </div>
                <p className='text-2xl p-1 m-1'>Notify via Email</p>
                <div className='mt-4 flex flex-col items-between'>
                    <div className='flex justify-between items-center'>
                        <div className='w-[80%] flex'>
                            <div className='w-[40%] md:w-[25%] relative'>
                                <IdDropdown 
                                    width={"100%"}
                                    color={"sky"}
                                    options={years}
                                    dropdownVisible={yearsVisible}
                                    setDropdownVisible={setYearsVisible}
                                    selectFunction={selectYear}
                                    selectedOption={selectedYear}
                                    placeholder={"Document Type"}
                                />
                            </div>
                            <UserFilter 
                                selectedCompany={selectedCompany} 
                                setSelectedSuggestion={setSelectedSuggestion} 
                            />
                            <div className='relative w-[40%] xl:w-[30%] ml-1.5'>
                                <div className='flex border rounded-lg ml-1.5 px-0.5 p-1.5 w-[100%]'>
                                    <input value={searchSubsidiary} onChange={(e) => setSearchSubsidiary(e.target.value)} className='text-sm focus:outline-none text-center w-[100%]' placeholder='Search Document' />
                                    <button onClick={() => setSearchSubsidiary("")} className='shrink-0 rounded-full hover:bg-red-500 hover:text-white p-0.5 text-red-500 mr-0.5 transition-all'>
                                        <XIcon className='w-4 h-4' />
                                    </button>
                                </div>
                                </div>
                            </div>

                        <div className='h-[32px] w-max relative'>
                            <button onClick={handleChangeOrder} className='rounded-lg p-2 bg-blue-400 text-white'>
                                {order === "asc" && <ArrowDownIcon className='w-5 h-5' />}
                                {order === "desc" && <ArrowUpIcon className='w-5 h-5' />}
                            </button>
                        </div>
                    </div>
                    {mode === "selectSub" &&
                        <div className=' px-1 mt-1.5 pb-1 h-[66vh] overflow-y-auto border rounded-lg flex flex-col no-scrollbar'>
                        {console.log(searchSubsidiary)}
                        {subsidiaries.map((subsidiary, i) => (
                            ((!selectedSuggestion || Object.keys(subsidiary.data().roles).includes(selectedSuggestion.id)) && (searchSubsidiary === "" || subsidiary.id.toLowerCase().includes(searchSubsidiary.toLowerCase()))) &&
                            <>
                                <SubsidiaryButton 
                                    key={i}
                                    subsidiary={subsidiary} 
                                    selectedYear={selectedYear} 
                                    getPercentage={getPercentage} 
                                    getPercentageColor={getPercentageColor} 
                                    selectedSubsidiaries={selectedSubsidiaries}
                                    selectSubsidiary={selectSubsidiary}
                                />
                            </>

                        ))}
                        </div>
                    }
                    {mode === "selectMail" && 
                        <div className='px-3 mt-2 h-[66vh]'>
                            <div className='border-b'>
                                {Object.entries(selectedSubsidiaries).map(([year, yearObj], i) => (
                                    Object.entries(yearObj).map(([sub, emails], j) => (
                                        <button onClick={() => {setEmailYear(year); setEmailSubsidiary(sub)}} className={'p-2 border-b  text-sm -mb-[1px] ' + (emailSubsidiary === sub ? " border-neutral-600 text-neutral-800" : "text-neutral-500")}>
                                            {year} / {sub}
                                        </button>
                                    ))
                                ))}
                            </div>
                            <div className='py-2 '>
                                {/* List of assigned Emails */}
                                {(emailYear && emailSubsidiary) && selectedSubsidiaries?.[emailYear]?.[emailSubsidiary]?.map((user => (
                                    <UserButton 
                                        user={user} 
                                        setSelectedSubsidiaries={setSelectedSubsidiaries}
                                        emailYear={emailYear}
                                        emailSubsidiary={emailSubsidiary}
                                    />
                                )))}

                                {(emailSubsidiary && emailYear) &&
                                    <AddUserButton 
                                        selectedCompany={selectedCompany}
                                        emailSubsidiary={emailSubsidiary} 
                                        emailYear={emailYear}
                                        setSelectedSubsidiaries={setSelectedSubsidiaries}
                                    />
                                }
                            </div>
                        </div>
                    }
                    <div className='mx-0 pb-1 lg:pb-0 lg:mx-3 mt-2 flex text-sm lg:text-base'>
                        <button onClick={() => setMode(prev => prev === "selectSub" ? "selectMail" : "selectSub")} className={'border border-blue-400 rounded-lg px-3 py-1  flex items-center w-[40%] lg:w-max mr-2 ' + (mode === "selectSub" ? "text-blue-400" : "text-white bg-blue-400")}>
                            Manage Mails
                            <MailIcon className='w-6 h-6 ml-3' />
                        </button>
                        <SendEmailButton
                            selectedCompany={selectedCompany}
                            selectedTemplate={selectedTemplate}
                            setSelectedTemplate={setSelectedTemplate}
                            notifyStaff={notifyStaff}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotifyStaff