import { DocumentDownloadIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db, generateSerialLetter, shortify, useAuth } from '../../firebase'
import IdDropdown from '../../components/IdDropdown'
import NameDropdown from '../../components/NameDropdown'


function CreateLocalFile({ selectedCompany }) {

    const currentUser = useAuth()

    const [years, setYears] = useState([])
    const [selectedYear, setSelectedYear] = useState()
    const [subsidiaries, setSubsidiaries] = useState([])
    const [selectedSubsidiary, setSelectedSubsidiary] = useState()

    const [yearsVisible, setYearsVisible] = useState(false)
    const [subsidiariesVisible, setSubsidiariesVisible] = useState(false)

    const [templatesVisible, setTemplatesVisible] = useState(false)
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState()


    /// Loading available Templates and Document Types ///

    useEffect(() => {
        async function fetchYearsTemplates() {
            if (selectedCompany?.id) {
    
                const newYears = await getDocs(query(collection(db, "user_data", selectedCompany?.id, "years")))
                setYears(newYears.docs)
            }

        }

        fetchYearsTemplates()
    }, [selectedCompany])


    /// Document Type, Document and Template Selection ///

    async function selectYear(year) {
        setSelectedYear(year)
        setYearsVisible(false)

        try {
            const newSubsidiaries = await getDocs(query(collection(db, "user_data", selectedCompany.id, "years", year.id, "subsidaries")))
            setSubsidiaries(newSubsidiaries.docs)
        } catch {
            const newSubsidiaries = await getDocs(query(collection(db, "user_data", selectedCompany.id, "years", year.id, "subsidaries"), where(`roles.${currentUser.uid}`, "==", "editor")))
            setSubsidiaries(newSubsidiaries.docs)
        }

        setSubsidiariesVisible(true)
    }

    async function selectSubsidiary(sub) {
        setSelectedTemplate()

        setSelectedSubsidiary(sub)
        setSubsidiariesVisible(false)

        const q = query(collection(db, "user_data", selectedCompany?.id, "templates"), where("relatedSubsidaries", "array-contains", sub.id))
        const newTemplates = await getDocs(q)
        setTemplates(newTemplates.docs)

        setTemplatesVisible(true)
    }

    async function selectTemplate(template) {
        setSelectedTemplate(template)
        setTemplatesVisible(false)
    }

    
    /// Generate Local File ///

    async function createLocalFile() {

        const localFileData = {}

        const formFrame = await getDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "forms", "standard_form")) 

        const subsidiaryData = selectedSubsidiary.data()

        localFileData["FY"] = selectedYear.id
        if (/^\d+$/.test(selectedYear.id)) {
            localFileData["FY-3"] = parseInt(selectedYear.id) - 3
            localFileData["FY-2"] = parseInt(selectedYear.id) - 2
            localFileData["FY-1"] = parseInt(selectedYear.id) - 1
        }

        localFileData["Status"] = "Final"

        for (const category of formFrame.data().categories.filter(category => category.type === "information")) {
            for (const field of category.fields) {
                const matchFields = subsidiaryData.fieldValues.filter(fieldValue => fieldValue.id === field.id) 
                if (matchFields.length === 0) {
                    localFileData[field.topic] = ""
                    if (localFileData["Status"] !== "Draft") {
                        localFileData["Status"] = "Draft"
                    }
                } else {
                    localFileData[field.topic] = matchFields[0].answer
                    
                    if (matchFields[0].done === false) {
                        if (localFileData["Status"] !== "Draft") {
                            localFileData["Status"] = "Draft"
                        }
                    }
                }
            }
        }

        for (const category of formFrame.data().categories.filter(category => (category.type === "standard-transactions" || category.type === "numbers" || category.type === "individual-transactions"))) {
            for (const field of category.fields) {
                const matchFields = subsidiaryData.fieldValues.filter(fieldValue => fieldValue.id === field.id) 
                if (matchFields.length === 0) {
                    localFileData[field.topic] = ""
                    if (localFileData["Status"] !== "Draft") {
                        localFileData["Status"] = "Draft"
                    }
                } else {
                    localFileData[field.topic] = parseFloat(matchFields[0]?.transaction_amount.toString().replace(/,/g , '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"
                    
                    if (matchFields[0].done === false) {
                        if (localFileData["Status"] !== "Draft") {
                            localFileData["Status"] = "Draft"
                        }
                    }
                }
            }
        }

        await generateSerialLetter(selectedTemplate, localFileData, selectedCompany.id, selectedYear.id, selectedSubsidiary.id)
    }

    
    /// HTML Component ///

    return (
        <div className='component-container w-[80%] lg:w-[55%] xl:w-[40%]'>
            <div className='component-icon-container bg-yellow-400'>
                <DocumentDownloadIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Create Local File</p>
            <div className='w-1/1 flex mt-4 px-1 gap-x-1'>

                <IdDropdown 
                    width={"16.66667%"}
                    color={"yellow"}
                    options={years}
                    selectedOption={selectedYear}
                    dropdownVisible={yearsVisible}
                    setDropdownVisible={setYearsVisible}
                    selectFunction={selectYear}
                    placeholder={"Document Type"}
                    
                />

                <IdDropdown 
                    width={"33.3333%"}
                    color={"yellow"}
                    options={subsidiaries}
                    selectedOption={selectedSubsidiary}
                    dropdownVisible={subsidiariesVisible}
                    setDropdownVisible={setSubsidiariesVisible}
                    selectFunction={selectSubsidiary}
                    placeholder={"Document"}
                />

                <NameDropdown 
                    width={"50%"}
                    color={"yellow"}
                    options={templates}
                    selectedOption={selectedTemplate}
                    dropdownVisible={templatesVisible}
                    setDropdownVisible={setTemplatesVisible}
                    selectFunction={selectTemplate}
                    placeholder={"Template"}
                
                />
                
            </div>

            <div className='w-[100%] px-1 mt-3'>
                <button className='w-[100%] text-center rounded-lg bg-yellow-400 text-white p-2 transition-all hover:shadow-md' onClick={createLocalFile}>Download</button>
            </div>
        </div>
    )
}

export default CreateLocalFile

