import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../firebase'
import { ChevronRightIcon, HomeIcon } from '@heroicons/react/outline'

function Welcome() {

    const currentUser = useAuth()
    const navigate = useNavigate()


    /// Title Specification ///    

    useEffect(() => {
        document.title = 'Welcome!'; 
    }, []);

    /// HTML Component ///

    return (
        <div className='font-body py-16'>
            <div className='w-[80%] lg:w-[28rem] mx-auto bg-white shadow-lg px-10 py-12 rounded-lg text-neutral-700 hover:scale-[1.005] transition-all cursor-pointer'>
                <div className='rounded-full border-2 border-neutral-700 p-2 w-max mx-auto mb-6'>
                    <HomeIcon className="w-10 h-10" />
                </div>
                <div className=' text-2xl text-center flex items-center justify-center'>
                    {currentUser ? "Welcome to the TPX Editor!" : "Please Login"}
                </div>
                {!currentUser && <div className='flex justify-center text-white text-xl mt-4'>
                    <button onClick={() => navigate("/login")} className='w-[100%] p-4 shadow-lg rounded-md bg-gradient-to-r from-emerald-500 to-lime-600'>Login</button>
                </div>}
                <div className='mt-5 flex justify-center text-indigo-700'>
                    {/* <span className='flex items-center hover:ml-2 transition-all'>
                        Dashboard <ChevronRightIcon className='w-4 h-4 inline' />
                    </span> */}

                </div>
            </div>
        </div>

    )
}

export default Welcome