import React, { useEffect, useState } from 'react'
import Papa from "papaparse"
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore'
import { db, shortify } from '../../firebase'
import { ChevronLeftIcon, ChevronRightIcon, DownloadIcon, ExclamationCircleIcon, SearchIcon } from '@heroicons/react/outline'
import BigIdDropdown from '../../components/BigIdDropdown'


function ImportCsv({ selectedCompany }) {

    const [selectedYear, setSelectedYear] = useState()

    const [years, setYears] = useState([])
    const [yearsVisible, setYearsVisible] = useState()

    const [csvData, setCsvData] = useState()
    const [csvFile, setCsvFile] = useState()

    const [formData, setFormData] = useState()
    const [userData, setUserData] = useState()
    const [userDataArray, setUserDataArray] = useState([])
    const [clientNames, setClientNames] = useState([])

    const [displayIndex, setDisplayIndex] = useState()

    
    /// Title Specification ///

    useEffect(() => {
        document.title = 'Import CSV - Add or Recover Data from CSV-Tables'; 
    }, []);


    /// Loading available Document Types /// 

    useEffect(() => {
        async function fetchYears() {
            const newYears = await getDocs(collection(db, "user_data", selectedCompany.id, "years"))
            setYears(newYears.docs)
        }

        fetchYears()
    }, [selectedCompany])


    /// Document Type Selection ///

    function selectYear(year) {
        setYearsVisible(false)
        setSelectedYear(year)
    }



    /// Handling CSV Entry ///

    async function uploadCsv(e) {
        if (e.target.files[0]) {
            setCsvFile(e.target.files[0])
        }
    }



    function formatNumber(number) {
        return number.toString() || "0"
        // return parseFloat(number.toString().replace(/,/g , '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"
    }

    
    /// CSV Parsing ///

    async function parseCsv() {

        setUserDataArray([])

        Papa.parse(csvFile, {
            header: true,
            skipEmptyLines: true,
            complete: function (results) {
                setCsvData(results.data)
            }
        })        
    }

    useEffect(() => {

        // Passing CSV Data in selected year form data
        // Problem: What if no form existent? -> Option for form creation

        async function fetchUserDataArray() {
            if (selectedYear?.id) {
                const form = await getDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "forms", "standard_form"))
                setFormData(form.data())
                
                const tempFormData = form.data()
    
                const newUserDataArray = []
                const newClientNames = []
        
                for (const row of csvData) {
                    newClientNames.push(row["Document"])
            
                    const newFieldValues = []
        
                    for (const category of tempFormData.categories) {
                        for (const field of category.fields) {
                            // Checks if name from CSV table exists in form
                            if (row[field.topic]) {
                                if (category.type === "information") {
                                    newFieldValues.push(
                                        {
                                            answer : row[field.topic],
                                            id : field.id,
                                            comment : "",
                                            done : false
                                        }
                                    )
                                } else if (category.type === "standard-transactions" || category.type === "numbers") {
                                    newFieldValues.push(
                                        {
                                            transaction_amount : formatNumber(row[field.topic]),
                                            id : field.id,
                                            comment : "",
                                            done : false
                                        }
                                    )
                                } 
                            }
                        }
                    }

                    newUserDataArray.push(newFieldValues)
                }

                setUserDataArray(newUserDataArray)  
                setClientNames(newClientNames)
            }
        }

        fetchUserDataArray()
    }, [csvData])

    useEffect(() => {
        setDisplayIndex(0)
        setUserData(userDataArray[0])
    }, [userDataArray])


    /// Switching between Documents /// 

    function nextRow() {
        if (userDataArray[displayIndex + 1]) {
            setDisplayIndex(displayIndex + 1)
            setUserData(userDataArray[displayIndex + 1])
        }
    }

    function previousRow() {
        if (userDataArray[displayIndex - 1]) {
            setDisplayIndex(displayIndex - 1)
            setUserData(userDataArray[displayIndex - 1])
        }
    }


    /// Saving CSV Data ///

    const [showWarning, setShowWarning] = useState(false)
    const [overwriteClients, setOverwriteClients] = useState()


    const [writePercentage, setWritePercentage] = useState()

    async function handleWrite() {

        const newOverwriteClients = []

        for (const [index, row] of Object.entries(csvData)) {
            const existentSubsidiary = await getDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "subsidaries", row["Document"]))

            // Check if Document is already existent
            if (existentSubsidiary?.data()?.fieldValues) {

                const existentSubsidiaryData = existentSubsidiary.data()
                const newFieldValueIDs = userDataArray[index].map(fieldValue => fieldValue.id)
                
                // Filtering all values from existent client that are not in the newly imported CSV
                // e.g. only company name is newly imported -> old value will be filtered out from original
                const filteredData = existentSubsidiaryData.fieldValues.filter(fieldValue => !newFieldValueIDs.includes(fieldValue.id))
                
                // Adding all newly imported values
                // e.g. only company name is newly imported -> new value will be added to new version
                for (const fieldValue of userDataArray[index]) {
                    filteredData.push(fieldValue)
                }

                newOverwriteClients.push({id: row["Document"], data: filteredData})


            } else {
                await setDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "subsidaries", row["Document"]), {
                    fieldValues : userDataArray[index],
                    roles : {}
                })
            }
            setWritePercentage(((index / (Object.entries(csvData).length - 1))*100).toFixed())
        }

        if (newOverwriteClients.length > 0) {
            setShowWarning(true)
            setOverwriteClients(newOverwriteClients)
        } else {
            setWritePercentage()
        }
    }

    async function overwrite() {
        setShowWarning(false)

        for (const overwriteClient of overwriteClients) {
            await updateDoc(doc(db, "user_data", selectedCompany.id, "years", selectedYear.id, "subsidaries", overwriteClient.id), {
                fieldValues : overwriteClient.data,
            })
        }

        setWritePercentage()
    }

    async function cancelOverwrite() {
        setShowWarning(false)
        setWritePercentage()
    }
    
    
    /// HTML Component ///

    return (
        <>
            <div className='rounded-lg bg-white p-2 m-10 font-body mx-auto flex items-center w-max shadow-lg'>
                <div className='pl-3'>
                    <DownloadIcon className='w-12 h-12 bg-indigo-400 text-white p-2 m-1 rounded-full' /> 
                </div>
                <p className='text-2xl p-1 m-1 ml-3'>Import CSV</p>
                <div className='ml-10'>
                    <div className='flex w-40'>
                        <BigIdDropdown 
                            width={"100%"}
                            placeholder={"Document Type"}
                            color={"indigo"}
                            options={years}
                            dropdownVisible={yearsVisible}
                            setDropdownVisible={setYearsVisible}
                            selectFunction={selectYear}
                            selectedOption={selectedYear}
                        />
                    </div>
                </div>
                <div className='flex justify-around'>
                    <label htmlFor='csv-upload' className='h-max whitespace-nowrap rounded-lg text-white bg-indigo-400 px-10 py-2 cursor-pointer w-1/2 mx-1 text-center'>{csvFile ? shortify(csvFile.name, 11) : "Browse"}</label>
                    <input id='csv-upload' className='hidden' type="file" onChange={(e) => uploadCsv(e)} />
                    <button className='w-1/2 h-max mx-1 px-10 py-2 rounded-lg text-white bg-indigo-400 whitespace-nowrap' onClick={parseCsv}>Parse CSV</button>
                </div>
            </div>

            <div className={'mt-5 font-body rounded-lg bg-white w-[85%] mx-auto p-4' + (userData ? '' : ' hidden')}>
                <div className='flex items-center justify-between relative'>
                    <div className='flex items-center my-2 p-1 w-max '>
                        <button onClick={previousRow} className='py-2 px-5 bg-blue-400 text-white mr-6 shadow-lg rounded-lg'><ChevronLeftIcon className='w-6 h-6' /></button>
                        <div className='w-10'>{clientNames[displayIndex]}</div>
                        <button onClick={nextRow} className='py-2 px-5 bg-blue-400 text-white ml-6 shadow-lg rounded-lg'><ChevronRightIcon className='w-6 h-6' /></button>
                    </div>
                    <div className='border rounded-lg p-1 text-xl flex items-center absolute left-0 right-0 mx-auto w-max'>
                        <input className='focus:outline-none ' />
                        <SearchIcon className='w-5 h-5 inline mx-1' />
                    </div>
                    <div className='relative'>
                        <button onClick={handleWrite} className='w-max bg-blue-400 text-white px-10 py-2 shadow-lg hover:bg-blue-600 w-36 transition-all rounded-lg'>
                            {writePercentage ? writePercentage + "%" : "Write"}
                        </button>
                        <div className={'absolute text-center mt-1 bg-white p-6 rounded-lg w-72 shadow-basic right-0 text-red-500 ' + (showWarning ? "" : "hidden")}>
                            <ExclamationCircleIcon className='w-12 h-12 mx-auto mb-3' />
                            <p className='text-2xl'>Warning!</p>
                            <p className=''>Some subsidiaries already exist. Overwrite?</p>
                            <div className='mt-4 flex justify-between'>
                                <button onClick={cancelOverwrite} className='w-[50%] p-1 bg-red-500 rounded-lg text-white mr-2 shadow-lg'>
                                    Cancel
                                </button>
                                <button onClick={overwrite} className='w-[50%] p-1 rounded-lg text-red shadow-lg border border-red-500 hover:bg-red-500 hover:text-white transition-all'>
                                    Overwrite
                                </button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className='flex flex-col'>
                    <div className='w-[100%] my-[2px] text-lg py-2 border-b bg-slate-300'>
                        <div className='w-[95%] px-5 mx-auto flex'>
                            <div className='w-[25%] flex justify-center items-center'>
                                Topic
                            </div>
                            <div className='w-[25%] flex justify-center items-center'>
                                Description
                            </div>
                            <div className='w-[25%] flex justify-center items-center'>
                                Enter in English
                            </div>    
                            <div className='w-[25%] flex justify-center items-center'>
                                Remarks
                            </div>
                        </div>
                    </div>
                
                    {formData?.categories?.map((c, index) => (
                    <div key={index} className='flex flex-col'>
                        <div className='w-1/1 my-[2px] mt-0 text-lg py-2 border-b bg-blue-300 text-center'>
                            {c.name}
                        </div>
                        {c.type === "information" && 
                            <div className='flex flex-col'>
                            {c.fields.map((field, i) => (
                                <div key={i} className='w-[100%] mt-[2px] text-lg py-2 border-b'>
                                    <div className='w-[95%] px-5 mx-auto flex items-center'>
                                        <div className='w-[25%]'>
                                            {field.topic}
                                        </div>
                                        <div className='w-[25%] text-base'>
                                            {field.description}
                                        </div>
                                        <div className='w-[25%] text-base px-2'>
                                            <textarea className='w-[100%] border rounded-lg p-1' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.answer || ""}></textarea>
                                        </div>    
                                        <div className='w-[25%] text-base '>
                                            <textarea className='w-[100%] border rounded-lg p-1' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.comment || ""}></textarea>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div> 
                        }   
                        {c.type === "standard-transactions" &&
                        <div className='flex flex-col'>
                        {c.fields.map((field, ii) => (
                            <div key={ii} className='w-[100%] mt-[2px] text-lg py-2 border-b'>
                                <div className='w-[95%] px-5 mx-auto flex items-center'>
                                    <div className='w-[25%]'>
                                        {field.topic}
                                    </div>
                                    <div className='w-[25%] text-base'>
                                        {field.description}
                                    </div>
                                    <div className='w-[25%] text-base px-2'>
                                        <textarea className='w-[100%] border rounded-lg p-1' placeholder='Transaction amount' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.transaction_amount || ""}></textarea>
                                    </div>    
                                    <div className='w-[25%] text-base'>
                                        <textarea className='w-[100%] border rounded-lg p-1' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.comment || ""}></textarea>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        }    
                        {c.type === "individual-transactions" &&
                        <div className='flex flex-col'>
                        {c.fields.map((field, ii) => (
                            <div key={ii} className='w-[100%] mt-[2px] text-lg py-2 border-b'>
                                <div className='w-[95%] px-5 mx-auto flex items-center'>
                                    <div className='w-[25%]'>
                                        {field.topic}
                                    </div>
                                    <div className='w-[25%] text-base'>
                                        <textarea className='w-[100%] border rounded-lg p-1' placeholder='Transaction partner' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.transaction_partner || ""}></textarea>
                                    </div>
                                    <div className='w-[25%] px-2 text-base'>
                                        <textarea className='w-[100%] border rounded-lg p-1' placeholder='Transaction amount' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.transaction_amount || ""}></textarea>
                                    </div>    
                                    <div className='w-[25%] text-base'>
                                        <textarea className='w-[100%] border rounded-lg p-1' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.comment || ""}></textarea>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        }   
                        {c.type === "numbers" &&
                        <div className='flex flex-col'>
                        {c.fields.map((field, ii) => (
                            <div key={ii} className='w-[100%] mt-[2px] text-lg py-2 border-b'>
                                <div className='w-[95%] px-5 mx-auto flex items-center'>
                                    <div className='w-[25%]'>
                                        {field.topic}
                                    </div>
                                    <div className='w-[25%] text-base'>
                                        {field.description}
                                    </div>
                                    <div className='w-[25%] text-base px-2'>
                                        <textarea className='w-[100%] border rounded-lg p-1' placeholder='Transaction amount' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.transaction_amount || ""}></textarea>
                                    </div>    
                                    <div className='w-[25%] text-base'>
                                        <textarea className='w-[100%] border rounded-lg p-1' value={userData?.filter(fieldValue => fieldValue.id === field.id)[0]?.comment || ""}></textarea>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                        }    
                    </div>
                    ))}
                </div>
                {/* <div className='flex w-[85%] mx-auto mt-4'>
                    <div className='mx-auto w-[75%]'>
                        <button className='w-[100%] p-2 bg-blue-500 text-white text-xl disabled:opacity-60' onClick={handleSaveChanges}>Save Changes</button>
                    </div>
                    <div className='w-[25%] text-blue-500 flex mx-4'>
                        <button className='w-1/2 py-1 border border-blue-500 flex justify-center mx-1 transition-all hover:bg-blue-500 hover:text-white' onClick={previousRow}><ChevronLeftIcon className='w-8 h-8' /></button>
                        <button className='w-1/2 py-1 border border-blue-500 flex justify-center mx-1 transition-all hover:bg-blue-500 hover:text-white' onClick={nextRow}><ChevronRightIcon className='w-8 h-8' /></button>
                    </div>
                </div> */}

            </div>
        </>

    )
}

export default ImportCsv