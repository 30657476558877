import { XIcon } from '@heroicons/react/outline'
import { collection, getDocs, limit, query, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useOutsideClick } from '../../firebase'

function UserFilter({ selectedCompany, setSelectedSuggestion }) {

    const [searchEmail, setSearchEmail] = useState("")
    const [suggestionsVisible, setSuggestionsVisible] = useState(false)
    const [searchSuggestions, setSearchSuggestions] = useState([]) 

    const emailsRef = useRef()
    useOutsideClick(emailsRef, () => setSuggestionsVisible(false))

    async function handleSearchEmailChange(e) {
        setSearchEmail(e.target.value)
        setSuggestionsVisible(true)
        
        const userQuery = query(collection(db, "user_data", selectedCompany.id, "users"), where("email", ">=", e.target.value), limit(3))
        const suggestedUsers = await getDocs(userQuery)
        setSearchSuggestions(suggestedUsers.docs)
    }

    function handleCancelUserSearch() {
        setSearchEmail("")
        setSuggestionsVisible(false)
        setSelectedSuggestion()
    }

    function handleSelectSuggestion(suggestion) {
        setSearchEmail(suggestion.data().email)
        setSuggestionsVisible(false)
        setSelectedSuggestion(suggestion)
    }

    return (
        <div ref={emailsRef} className='relative w-[40%] xl:w-[30%]'>
            <div className='flex border rounded-lg ml-1.5 px-0.5 p-1.5 w-[100%]'>
                <input value={searchEmail} onChange={handleSearchEmailChange} className='text-sm focus:outline-none text-center w-[100%]' placeholder='Search Email' />
                <button onClick={handleCancelUserSearch} className='shrink-0 rounded-full hover:bg-red-500 hover:text-white p-0.5 text-red-500 mr-0.5 transition-all'>
                    <XIcon className='w-4 h-4' />
                </button>
                
            </div>
            {(searchSuggestions.length > 0 && suggestionsVisible) &&
                <div className='absolute bg-white w-[100%] shadow-basic rounded-lg px-1 mt-1 text-xs'>
                    {searchSuggestions.map((suggestion, i) => (
                        <button onClick={() => handleSelectSuggestion(suggestion)} className='select-btn text-black hover:bg-blue-400 '>{suggestion.data().email}</button>
                    ))}
                </div>
            }
        </div>
    )
}

export default UserFilter