import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { collection, deleteDoc, doc, getDocs, onSnapshot, query } from 'firebase/firestore'
import { deleteObject, ref } from 'firebase/storage'
import React, { useEffect, useRef, useState } from 'react'
import { db, storage, shortify, useOutsideClick } from '../../firebase'

function DeleteLocalFileTemplates({ selectedCompany }) {

    const [templates, setTemplates] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [templatesVisible, setTemplatesVisible] = useState()

    const [templateType, setTemplateType] = useState("Localfile")

    const templatesRef = useRef()
    useOutsideClick(templatesRef, () => setTemplatesVisible(false))

    
    /// Handle Loading Data ///

    useEffect(() => {
        async function fetchTemplates() {
            if (selectedCompany?.id) {
                const unsub = onSnapshot(query(collection(db, "user_data", selectedCompany.id, "templates")), (querySnapshot) => {
                    setTemplates(querySnapshot.docs)
                })

            }
        }

        fetchTemplates()
    }, [selectedCompany])

    async function loadLFTemplates() {
        const fetchedTemplates = await getDocs(collection(db, "user_data", selectedCompany.id, "templates"))
        setTemplates(fetchedTemplates.docs)
    }

    async function loadDocumentTemplates() {
        const fetchedTemplates = await getDocs(collection(db, "user_data", selectedCompany.id, "special_templates"))
        console.log(fetchedTemplates.docs)
        setTemplates(fetchedTemplates.docs)
    }

    useEffect(() => {
        setSelectedTemplate()

        if (templateType === "Localfile") {
            loadLFTemplates()
        } else if (templateType === "Document") {
            loadDocumentTemplates()
        }
    }, [templateType])
    

    /// Delete Template ///

    async function deleteTemplate() {
        setSelectedTemplate()
        await deleteDoc(doc(db, "user_data", selectedCompany.id, "templates", selectedTemplate.id))
    }


    function selectTemplate(template) {
        setSelectedTemplate(template)
        setTemplatesVisible(prevState => !prevState)
    }


    /// HTML Component ///

    return (
        <div className='component-container w-[80%] lg:w-[55%] xl:w-[40%] relative z-10'>
            <div className='component-icon-container bg-red-400'>
                <TrashIcon className='w-8 h-8' />  
            </div>
            <p className='text-2xl p-1 m-1'>Delete Topic Template</p>
            <div className='mt-3'>
                <div className='flex'>
                    <button onClick={() => setTemplateType("Localfile")} className={'w-[50%] rounded-lg border hover:bg-red-400 hover:text-white border-red-400 p-1 mx-2 transition-all ' + (templateType === "Localfile" ? " bg-red-400 text-white" : " text-red-400")}>Topic Template</button>
                    <button onClick={() => setTemplateType("Document")} className={'w-[50%] rounded-lg border hover:bg-red-400 hover:text-white border-red-400 p-1 mr-2 transition-all ' + (templateType === "Document" ? " bg-red-400 text-white" : " text-red-400")}>IDR Template</button>
                </div>
                <div className='flex mt-2'>
                    <div ref={templatesRef} className='w-2/3 z-30 relative mx-2'>
                        <button className='w-[100%] border rounded-lg p-2' onClick={() => setTemplatesVisible(prev => !prev)}>{selectedTemplate ? shortify(selectedTemplate.data().name, 20) : "Topic Template"}</button>
                        <div className={'bg-white absolute w-[100%] px-1 drop-shadow-lg rounded-lg z-40 ' + (templatesVisible ? "" : "hidden")}>
                            {templates?.map((template, index) => (
                                <button key={index} className='select-btn hover:bg-red-400' onClick={() => selectTemplate(template)}>{template.data().name ? template.data()?.name : "unnamed"}</button>
                            ))}
                        </div>
                    </div>
                    <button className='w-1/3 text-white bg-red-400 rounded-lg mr-2' onClick={deleteTemplate}>Delete</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteLocalFileTemplates