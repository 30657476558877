import React, { useCallback, useEffect, useRef, useState } from 'react'

function ColName(props) {


    const { tableElement, cellContent } = props

    const [tableHeight, setTableHeight] = useState("auto")

    useEffect(() => {
        if (tableElement.current.offsetHeight) {
            setTableHeight(tableElement.current.offsetHeight);
        }

    }, [tableElement?.current?.offsetHeight])

    const [activeIndex, setActiveIndex] = useState(false)
    const [minCellWidth, setMinCellWidth] = useState(100)
    const [colWidth, setColWidth] = useState("auto")

    const columnElement = useRef()

    const mouseDown = () => {
        setActiveIndex(true);
    };

    const mouseMove = useCallback(
        (e) => {
            if (activeIndex) {

                // Bad solution, because margins or paddings seems to block offeset
                const width = e.clientX - columnElement.current.offsetLeft;
                setColWidth(width)
            }

        },
        [activeIndex, minCellWidth, columnElement]
    );

    const removeListeners = useCallback(() => {
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", removeListeners);
    }, [mouseMove]);

    const mouseUp = useCallback(() => {
        setActiveIndex(false);
        removeListeners();
    }, [setActiveIndex, removeListeners]);

    useEffect(() => {
        if (activeIndex !== null) {
            window.addEventListener("mousemove", mouseMove);
            window.addEventListener("mouseup", mouseUp);
        }
    
        return () => {
            removeListeners();
        };
    }, [activeIndex, mouseMove, mouseUp, removeListeners]);
 


    return (
        <th colSpan={cellContent === "Progress" && 2} className='border px-1 text-sm bg-neutral-200 relative z-10' style={{width : `${colWidth}px`}} ref={columnElement}>
            {cellContent}

            <div onMouseDown={() => mouseDown()} style={{height: tableHeight}} className='cursor-col-resize absolute w-[3px] -mr-[1px] border-l-3 right-0 top-0 z-50 hover:bg-neutral-200'>

            </div>
        </th>
    )
}

export default ColName