import React, { useCallback } from 'react'

const TransactionAmountInput = React.memo(({ field, done, transaction_amount, category, handleTransactionAmountChange }) => {

    return (
        <>
        {((done)) && 
            <span className='w-[100%] p-1 text-right'>{transaction_amount}</span>
        }
        {((!done)) && 
            <textarea className='w-[100%] border rounded-lg p-1 text-right' placeholder='Transaction amount' value={transaction_amount || ""} onChange={(e) => handleTransactionAmountChange(e, field.id, category.type)}></textarea>
        }
        </>
    )
})

export default TransactionAmountInput