import { CogIcon, TrashIcon } from '@heroicons/react/outline'
import { collection, deleteField, doc, getDoc, getDocs, onSnapshot, query, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, useOutsideClick } from '../../firebase'
import AuthButton from './AuthButton'
import SuperAuthButton from './SuperAuthButton'
import DropdownMenu from '../../components/IdDropdown'
import IdDropdown from '../../components/IdDropdown'

function ManageAuth({ selectedCompany }) {

    const [usersVisible, setUsersVisible] = useState()
    const [yearsVisible, setYearsVisible] = useState()

    const [years, setYears] = useState([])
    const [users, setUsers] = useState([])
    const [subsidiaries, setSubsidiaries] = useState([])

    const [selectedYear, setSelectedYear] = useState()
    const [selectedUser, setSelectedUser] = useState()

    const [saveCall, setSaveCall] = useState()

    const [selectedCompanyData, setSelectedCompanyData] = useState()

    const usersRef = useRef()
    useOutsideClick(usersRef, () => setUsersVisible(false))

 
    /// Loading available Document Types and Users ///

    useEffect(() => {
        async function fetchYearsAndUsers() {
            const fetchedYears = await getDocs(collection(db, "user_data", selectedCompany.id, "years"))
            setYears(fetchedYears.docs)

            const fetchedSelectedCompanyData = await getDoc(doc(db, "user_data", selectedCompany.id))
            setSelectedCompanyData(fetchedSelectedCompanyData.data())

            const unsub = onSnapshot(collection(db, "user_data", selectedCompany.id, "users"), (querySnapshot) => {
                setUsers(querySnapshot.docs)
            })

        }   

        fetchYearsAndUsers()
    }, [selectedCompany])


    /// Document Type Selection ///

    async function selectYear(year) {
        const newSubsidiaries = await getDocs(query(collection(db, "user_data", selectedCompany.id, "years", year.id, "subsidaries")))
        setSubsidiaries(newSubsidiaries.docs)
        setSelectedYear(year)
        setYearsVisible(false)
    }


    /// User Selection ///

    function selectUser(user) {
        setSelectedUser(user)
        setUsersVisible(false)
    }


    /// Saving Authorization ///

    function handleSaveCall() {
        setSaveCall(true)
    }


    /// Saving superEditing ///

    useEffect(() => {

        async function saveRoles() {
            await updateDoc(doc(db, "user_data", selectedCompany.id), {
                roles : selectedCompanyData.roles,
                superEditing : selectedCompanyData.superEditing
            })
        }
        if (saveCall) {
            saveRoles()
            setSaveCall(false)
        } 

    }, [saveCall])


    /// Deleting User ///

    async function deleteUser(user) {

        const roleChange = "roles." + user.id
        const superEditingChange = "superEditing." + user.id

        await updateDoc(doc(db, "user_data", selectedCompany.id), {
            [roleChange] : deleteField(),
            [superEditingChange] : deleteField()

        })
    }


    /// HTML Component ///

    return (
        <div className='rounded-lg bg-white drop-shadow-lg p-4 w-[100%] lg:w-[75%] mt-16 flex flex-col justify-between relative z-20'>
            <div className=''>
                <div className='mb-5'>
                    <CogIcon className='w-12 h-12 bg-amber-400 text-white p-2 m-1 rounded-full' />
                </div>
                <p className='text-2xl p-1 m-1'>Manage Client Authorization</p>
                <div className='mt-3'>
                    <div className='flex px-1.5 gap-x-1'>
                        <IdDropdown 
                            width={"30%"}
                            color={"amber"}
                            options={years}
                            selectFunction={selectYear}
                            setDropdownVisible={setYearsVisible}
                            dropdownVisible={yearsVisible}
                            placeholder={"Document Type"}
                            selectedOption={selectedYear}
                        />
                        <div ref={usersRef} className='w-[70%] relative'>
                            <button className='w-[100%] border rounded-lg p-1' onClick={() => setUsersVisible(prev => !prev)}>{selectedUser ? (selectedUser.data().firstName + " " + selectedUser.data().lastName) : "User"}</button>
                            <div className='absolute bg-white w-[100%] max-h-44 overflow-y-auto px-1 drop-shadow-lg rounded-lg rounded-tl-none z-20'>
                                <div className={usersVisible ? "" : "hidden"}>
                                    {users.map((user, index) => (
                                        <button key={index} className='select-btn hover:bg-amber-400 relative flex items-center justify-between' onClick={() => selectUser(user)}>
                                            {(user.data().firstName + " " + user.data().lastName + " - " + user.data().title)}
                                            <button className='border-2 border-red-500 bg-white text-red-500 hover:text-white hover:bg-red-500 rounded-lg relative p-0.5 right-0 ml-1.5 z-20' onClick={() => deleteUser(user)}><TrashIcon className='w-5 h-5' /></button>
                                        </button>
                                    ))}
                                </div>
                            </div>
                        </div>   
                    </div>
                    <div className='w-[100%] px-2'>
            
                        {selectedUser && 
                            <div className={'border rounded-lg p-2 flex justify-center flex-wrap'}>
                                {selectedCompany && 
                                <>
                                    <SuperAuthButton user={selectedUser} selectedCompanyData={selectedCompanyData} selectedCompany={selectedCompany} saveCall={saveCall} setSaveCall={setSaveCall} yearId={selectedYear?.id} rolesObjName="roles" expectedRole="super-user" roleDesc="Super User" setSelectedCompanyData={setSelectedCompanyData}  />                            
                                    <SuperAuthButton user={selectedUser} selectedCompanyData={selectedCompanyData} selectedCompany={selectedCompany} saveCall={saveCall} setSaveCall={setSaveCall} yearId={selectedYear?.id} rolesObjName="superEditing" expectedRole={true} roleDesc="Super Editing" setSelectedCompanyData={setSelectedCompanyData} />
                                </>
                                }
                            {subsidiaries.map((subsidiary, index) => (
                                <AuthButton user={selectedUser} key={index} subsidiary={subsidiary} companyId={selectedCompany?.id} saveCall={saveCall} setSaveCall={setSaveCall} yearId={selectedYear?.id} />
                                
                            ))}
                            </div>
                        }

                    </div>
                </div>
            </div>
            <div className='mt-4 mx-1'>
                <button className='w-[100%] p-2 bg-amber-400 text-white rounded-lg hover:shadow-md transition-all' onClick={handleSaveCall}>Save</button>
            </div>
        </div>                   
    )
}

export default ManageAuth