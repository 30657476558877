import React from 'react'

const DescInput = React.memo(({ setFormData, desc, descCategoryIndex, descRowIndex }) => {

    function changeDescription(e, categoryIndex, rowNumber) {
        setFormData(prev => {
            const updatedFormData = {... prev}

            updatedFormData.categories[categoryIndex].fields[rowNumber].description = e.target.value
    
            return updatedFormData
        })
    }

    return (
        <div className='w-1/2 float-left flex justify-center'>
            <textarea className='border rounded-lg p-1 w-[90%]' value={desc} onChange={(e) => changeDescription(e, descCategoryIndex, descRowIndex)} ></textarea>
        </div>
    )
})

export default DescInput