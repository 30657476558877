import { CalendarIcon, CogIcon, LibraryIcon, OfficeBuildingIcon, PlusSmIcon, UserAddIcon } from '@heroicons/react/outline'
import { initializeApp } from 'firebase/app'
import { createUserWithEmailAndPassword, getAuth, signOut } from 'firebase/auth'
import { collection, deleteDoc, doc, getDoc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { auth, db, useAuth } from '../../firebase'
import AddUser from './AddUser'
import AuthButton from './AuthButton'
import ManageAuth from './ManageAuth'
import ManageFileAuthorization from './ManageFileAuthorization'
import ManageIDRAuth from './ManageIDRAuth'

function ManageUsers({ selectedCompany }) {
    
    /// Title Specification ///

    useEffect(() => {
        document.title = 'Manage Users - Create Users and Manage Authorizations'; 
    }, []);
    
    
    /// HTML Component ///

    return (
        <div className='py-20 w-[90%] md:w-[70%] xl:w-[55%] 2xl:w-[45%] mx-auto'>
            <div className='font-body mx-auto flex flex-col flex-wrap justify-center items-center'>
                <AddUser selectedCompany={selectedCompany} />
                <ManageAuth selectedCompany={selectedCompany} />
                <ManageFileAuthorization selectedCompany={selectedCompany} />
                <ManageIDRAuth selectedCompany={selectedCompany} />
            </div>
        </div>

    )
}

export default ManageUsers