import { FolderAddIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, onSnapshot, updateDoc } from 'firebase/firestore'
import React, { useEffect, useRef, useState } from 'react'
import { db, shortify, useOutsideClick } from '../../firebase'
import FileAuthButton from './FileAuthButton'
import IDRAuthButton from './IDRAuthButton'

function ManageIDRAuth({ selectedCompany }) {

    const [users, setUsers] = useState([])
    const [usersVisible, setUsersVisible] = useState(false)
    const [selectedUser, setSelectedUser] = useState()

    const [entries, setEntries] = useState([])
    

    const [saveCall, setSaveCall] = useState(false)
    const [switchCall, setSwitchCall] = useState()

    const usersRef = useRef()
    useOutsideClick(usersRef, () => setUsersVisible(false))

    useEffect(() => {
        async function fetchEntriesAndUsers() {
            const fetchedEntries = await getDocs(collection(db, "user_data", selectedCompany.id, "special_entries"))
            setEntries(fetchedEntries.docs)

            const unsub = onSnapshot(collection(db, "user_data", selectedCompany.id, "users"), (querySnapshot) => {
                setUsers(querySnapshot.docs)
            })

        }   

        fetchEntriesAndUsers()
    }, [selectedCompany])


    async function selectUser(user) {
        setSelectedUser(user)
        setUsersVisible(false)
    }


    function saveFileAuthorization() {
        setSaveCall(prev => !prev)
    }


    function switchAllAuth() {
        if (switchCall !== "switchPos") {
            setSwitchCall("switchPos")
        } else {
            setSwitchCall("switchNeg")
        }
    }

    

    return (
        <div className='component-container mt-16 w-[100%] lg:w-[75%]'>
            <div className='component-icon-container bg-pink-400'>
                <FolderAddIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Manage IDR Authorization</p>
            <div className='mt-3'>
                <div className='flex'>

                    <div className='w-[100%] px-2'>
                        <div ref={usersRef} className='relative'>
                            <button className='w-[100%] border rounded-lg p-1' onClick={() => setUsersVisible(prev => !prev)}>{selectedUser ? (selectedUser.data().firstName + " " + selectedUser.data().lastName + " - " + selectedUser.data().title) : "User"}</button>
                            <div className='absolute bg-white w-[100%] px-1 drop-shadow-lg rounded-b-lg z-20 max-h-28 overflow-auto'>
                                <div className={usersVisible ? "" : "hidden"}>
                                {users?.map((user, index) => (
                                    <button key={index} className='select-btn hover:bg-pink-400' onClick={() => selectUser(user)}>
                                        {(user.data().firstName + " " + user.data().lastName + " - " + user.data().title)}
                                    </button>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className={'border rounded-lg p-2 flex justify-center flex-wrap mt-2 mx-2' + (selectedUser ? "" : " hidden")}>
                    <button onClick={switchAllAuth} className={'auth-button ' + (switchCall === "switchPos" ? "bg-green-400" : "bg-red-400")}>All</button>
                    {entries?.map((entry, index) => (
                        <IDRAuthButton 
                            selectedCompany={selectedCompany}
                            entry={entry}
                            user={selectedUser} 
                            saveCall={saveCall} 
                            setSaveCall={setSaveCall} 
                            switchCall={switchCall}
                        />
                    ))}
                </div>
                <div className='mt-4 mx-1'>
                    <button onClick={saveFileAuthorization} className='w-[100%] p-2 bg-pink-400 text-white rounded-lg hover:shadow-md transition-all'>Save</button>
                </div>
            </div>
        </div>
    )
}

export default ManageIDRAuth