import React, { useEffect } from 'react'

const TransactionPartnerInput = React.memo(({ field, transaction_partner, done, handleTransactionPartnerChange, category }) => {

    return (
        <>
        {((done || false)) && 
            <span className='w-[100%] p-1'>{transaction_partner || ""}</span>
        }
        {((!done || false)) && 
            <textarea className='w-[100%] border rounded-lg p-1' placeholder='Transaction partner' value={transaction_partner || ""} onChange={(e) => handleTransactionPartnerChange(e, field.id, category.type)}></textarea>
        }
        </>
    )
})

export default TransactionPartnerInput