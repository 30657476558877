import { DocumentTextIcon } from '@heroicons/react/outline'
import React from 'react'
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer'

function WorkflowPDF(props) { 

  const { tableCols, vals, subs, progress, totalFields, selectedSubs } = props

  // Font.register({ family: 'Sora', src: 'https://fonts.googleapis.com/css2?family=Sora:wght@600&display=swap' });

  const cellWidth = (1 / tableCols.length).toFixed(5) * 100 + "%"

  const styles = StyleSheet.create({
    tableContainer: {
      padding: 10,
    },

    table: {
      display: 'table',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRightWidth: 0,
      borderBottomWidth: 0,
    },

    row: {
      flexDirection: 'row',
    },

    cell: {
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 4,
      fontSize: 10,
      width: cellWidth
    },

    red300: {
      backgroundColor: "#fca5a5"
    },
    red200: {
      backgroundColor: "#fecaca"
    },
    orange200: {
      backgroundColor: "#fed7aa"
    },
    yellow200: {
      backgroundColor: "#fef08a"
    },
    green200: {
      backgroundColor: "#bbf7d0"
    },
    green300: {
      backgroundColor: "#86efac"
    },
    green400: {
      backgroundColor: "#4ade80"
    },

    headerCell: {
      borderWidth: 1,
      borderLeftWidth: 0,
      borderTopWidth: 0,
      padding: 4,
      fontSize: 10,
      width: cellWidth,
      textAlign: "center",
      backgroundColor: "lightgray",
    },

    header: {
      marginTop: 6,
      marginBottom: 12,
      // fontFamily: 'Sora'
    }

  });
  
  function evalValColor(val, i) {

    const chosenOpt = tableCols[i]?.answer_possibilities?.find(opt => opt?.opt === val.answer)
    if (chosenOpt) {
      return [styles.cell, StyleSheet.create({
        col: {
          backgroundColor: chosenOpt.color
        }
      }).col] 
    } else {
      return styles.cell
    } 
  }

  function evalProgressColor(progress) {

        if (progress / totalFields === 0) {
            return [styles.cell, styles.red300]
        } else if ((progress / totalFields) < 0.35) {
        return [styles.cell, styles.red200]
        } else if (progress / totalFields < 0.5) {
            return [styles.cell, styles.orange200]
        } else if (progress / totalFields < 0.7) {
            return [styles.cell, styles.yellow200]
        } else if (progress / totalFields < 0.9) {
            return [styles.cell, styles.green200]
        } else if (progress / totalFields < 1) {
            return [styles.cell, styles.green300]
        } else {
            return [styles.cell, styles.green400]
        }
    }

    function splitIntoGroups(list, groupSize) {
        const groups = [];
        for (let i = 0; i < list.length; i += groupSize) {
          groups.push(list.slice(i, i + groupSize));
        }
        return groups;
    }


    const PDFTable = () => (
        <Document>
            {splitIntoGroups(vals, 22).map((valGroup, k) => (
            <Page orientation="landscape" style={styles.tableContainer}>
                {/* <Text style={styles.header}>Gesellschaft CGM im Jahr 2022</Text> */}
                <View style={styles.table}>
                    <View style={styles.row}>
                        <View style={styles.headerCell}>
                            <Text>Code</Text>
                        </View>
                        
                        {tableCols.map((col, colIndex) => (
                        <View key={colIndex} style={styles.headerCell}>
                            <Text>{col.topic}</Text>
                        </View>
                        ))}

                        <View style={styles.headerCell}>
                            <Text>Progress</Text>
                        </View>
                    </View>
                    {valGroup.map((valRow, i) => (
                        selectedSubs.includes(subs[i]) &&
                        <View key={i} style={styles.row}>
                            <View key={splitIntoGroups(subs, 22)[k][i]} style={styles.cell}>
                                <Text>{splitIntoGroups(subs, 22)[k][i]}</Text>
                            </View>
                            {valRow.map((val, j) => (
                            <View key={val} style={evalValColor(val, j)}>
                                <Text>{val.answer}</Text>
                            </View>
                            ))}
                            <View key={i + splitIntoGroups(progress, 22)[k][i]} style={evalProgressColor(splitIntoGroups(progress, 22)[k][i])}>
                                <Text>{(splitIntoGroups(progress, 22)[k][i] / totalFields).toFixed(2) * 100 + "%"}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </Page>
            ))}
        </Document>
    );

 

  return (
    <div className='mr-2'>
        <PDFDownloadLink document={<PDFTable />} fileName="table.pdf">
          <button className='flex items-center rounded-lg bg-teal-400 text-white px-4 transition-all hover:bg-teal-500 h-[100%]'>
            <div className='mr-1'>
              <DocumentTextIcon className='w-5 h-5' />
          </div>
          PDF
          </button>
        </PDFDownloadLink>
    </div>
  )
}

export default WorkflowPDF