import { DocumentAddIcon, FolderAddIcon, MinusSmIcon, PencilAltIcon, PlusCircleIcon, PlusSmIcon, SaveIcon, SwitchVerticalIcon, TrashIcon, XIcon } from '@heroicons/react/outline'
import { CheckCircleIcon, ChevronDownIcon, ChevronUpIcon, DuplicateIcon, InformationCircleIcon, PlusIcon, TrashIcon as TrashIcon2 } from '@heroicons/react/solid'
import React, { useEffect, useRef, useState } from 'react'
import { db, shortify, useAuth } from '../../firebase'
import Toolbar from './Toolbar'
import AdditionalPropsMenu from './AdditionalPropsMenu'
import AddCategories from './AddCategories'
import AddRows from './AddRows'
import ChangeCategoryOrder from './ChangeCategoryOrder'
import ChangeRowOrder from './ChangeRowOrder'
import TopicInput from './TopicInput'
import NumbersInput from './NumbersInput'
import DeleteCategory from './DeleteCategory'
import DeleteRow from './DeleteRow'
import DescInput from './DescInput'

function FormHolder({ formData, setFormData, saveFunction }) {

    const [addRowVisible, setAddRowVisible] = useState(false)
    const [addCategoryVisible, setAddCategoryVisible] = useState(false)
    const [savePossible, setSavePossible] = useState(true)

    const [deleteRowsVisible, setDeleteRowsVisible] = useState(false)
    const [deleteCategoriesVisible, setDeleteCategoriesVisible] = useState(false)

    const [changeRowsVisible, setChangeRowsVisible] = useState(false)
    const [changeCategoriesVisible, setChangeCategoriesVisible] = useState(false)

    const [deletedRows, setDeletedRows] = useState([])

    const [neededFieldVisible, setNeededFieldVisible] = useState(false)
    const [neededFieldException, setNeededFieldException] = useState()

    const [currentCategoryIndex, setCurrentCategoryIndex] = useState()
    const [currentRowNumber, setCurrentRowNumber] = useState()

    const [selectedCategoryI, setSelectedCategoryI] = useState()
    const [selectedRowI, setSelectedRowI] = useState()
    const [selectedField, setSelectedField] = useState()
    const [addPropsVisible, setAddPropsVisible] = useState()
    const [buttonEvent, setButtonEvent] = useState()


    /// Edit Form ///

    /// Change Category Name ///

    function changeCategory(e, categoryIndex) {

        setFormData(prev => {
            const updatedFormData = {... prev} 

            updatedFormData.categories[categoryIndex].name = e.target.value
    
            return updatedFormData
        })

    }


    /// Adding Categories ///

    
    // function addCategory(categoryIndex, categoryName, categoryType) {
    //     setFormData(prev => {
    //         const updatedFormData = {... prev}

    //         var newId = Math.floor(100000 + Math.random() * 900000)
    
    //         const usedIds = updatedFormData.categories.map(category => category.fields.map(field => field.id))
    //         usedIds.flat()
    
    //         while (usedIds.includes(usedIds)) {
    //             newId = Math.floor(100000 + Math.random() * 900000)
    //         }
    
    //         updatedFormData.categories.splice(categoryIndex + 1, 0, {
    //             name : categoryName,
    //             type : categoryType,
    //             fields : []
    //         })
    
    //         // addRow(categoryIndex + 1, 0)
    
    //         return updatedFormData
    //     })

    //     setAddCategoryVisible(false)
    // } 

    function addRow(categoryIndex, row) {

        setFormData(prev => {
            const updatedFormData = {... prev}

            var newId = Math.floor(100000 + Math.random() * 900000)
    
            const usedIds = updatedFormData.categories.map(category => category.fields.map(field => field.id))
            usedIds.flat()
    
            while (usedIds.includes(usedIds)) {
                newId = Math.floor(100000 + Math.random() * 900000)
            }
            
            if (updatedFormData.categories[categoryIndex].type === "information") {
                updatedFormData.categories[categoryIndex].fields.splice(row + 1, 0, {
                    topic : "", 
                    description : "",
                    id: newId
                })
            } else if (updatedFormData.categories[categoryIndex].type === "standard-transactions") {
                updatedFormData.categories[categoryIndex].fields.splice(row + 1, 0, {
                    topic : "", 
                    description : "",
                    id: newId
                })
            } else if (updatedFormData.categories[categoryIndex].type === "individual-transactions") {
                updatedFormData.categories[categoryIndex].fields.splice(row + 1, 0, {
                    topic : "", 
                    id: newId
                })
            } else if (updatedFormData.categories[categoryIndex].type === "numbers") {
                updatedFormData.categories[categoryIndex].fields.splice(row + 1, 0, {
                    topic : "", 
                    formula: "",
                    id: newId
                })
            }
    
            return updatedFormData
        })

        setAddRowVisible(false)
    } 


    /// Delete Rows and Categories ///

    function handleDeleteVisibility() {
        if (!deleteRowsVisible && !deleteCategoriesVisible) {
            setDeleteCategoriesVisible(true)
        } else if (!deleteRowsVisible && deleteCategoriesVisible) {
            setDeleteCategoriesVisible(false)
            setDeleteRowsVisible(true)
        } else {
            setDeleteRowsVisible(false)
        }
    }


    function handleChangeVisibility() {
        if (!changeRowsVisible && !changeCategoriesVisible) {
            setChangeCategoriesVisible(true)
        } else if (!changeRowsVisible && changeCategoriesVisible) {
            setChangeCategoriesVisible(false)
            setChangeRowsVisible(true)
        } else {
            setChangeRowsVisible(false)
        }
    }


    function showSelectableRows(categoryIndex, rowNumber) {
        const updatedFormData = {... formData}

        if (!neededFieldVisible) {
            setCurrentCategoryIndex(categoryIndex)
            setCurrentRowNumber(rowNumber)
    
            setNeededFieldVisible(true)
            setNeededFieldException(updatedFormData.categories[categoryIndex].fields[rowNumber].id)
        } else {
            setCurrentCategoryIndex()
            setCurrentRowNumber()

            setNeededFieldVisible(false)
            setNeededFieldException()
        }        
    }

    function selectRow(fieldId) {
        setNeededFieldVisible(false)    
        
        setFormData(prev => {
            const updatedFormData = {... prev}

            updatedFormData.categories[currentCategoryIndex].fields[currentRowNumber].formula = (updatedFormData.categories[currentCategoryIndex].fields[currentRowNumber].formula ? updatedFormData.categories[currentCategoryIndex].fields[currentRowNumber]?.formula : "") + `[${fieldId}]`
            console.log(updatedFormData)
    
            return updatedFormData   
        })
    }


    /// Additional Properties (Placeholder & Answer Possibilities) ///

    function handleShowAddProps(e, i, j, field) {
        setButtonEvent(e)
        setSelectedCategoryI(i)
        setSelectedRowI(j)
        setSelectedField(field)
        setAddPropsVisible(true)
    }

    function handleClickOutside(event, menuRef) {

        if (menuRef.current && 
            !menuRef.current.contains(event.target) && 
            !buttonEvent.target.contains(event.target)) {
            setAddPropsVisible(false);
            setSelectedCategoryI()
            setSelectedRowI()
            setButtonEvent()
            setSelectedField()
          }
    }
    
    // Notes:
    // In order to maximize efficiency you might want to shift the functions changing formData to their visual components 
    // E.g. <AddCategories />

    /// HTML Component /// 

    return (
        <>  
            <Toolbar
                saveFunction={saveFunction}
                handleChangeVisibility={handleChangeVisibility}
                setAddRowVisible={setAddRowVisible}
                setAddCategoryVisible={setAddCategoryVisible}
                handleDeleteVisibility={handleDeleteVisibility} 
                deletedRows={deletedRows}
            />

            <div className='mt-[68px] pb-2 flex flex-col h-[100%] overflow-y-auto grow relative z-[20]'> 

                {/* Titels */}

                {formData &&
                <div className='w-[100%] mb-[1px] text-lg py-2 bg-slate-300 fixed z-10'>
                    <div className='w-[90%] px-5 mx-auto flex'>
                        <div className='w-1/2 flex justify-center items-center'>
                            Topic
                        </div>
                        <div className='w-1/2 flex justify-center items-center'>
                            Description
                        </div>
                    </div>
                </div>
                }

                {addPropsVisible &&
                    <AdditionalPropsMenu 
                        i={selectedCategoryI}
                        j={selectedRowI}
                        field={selectedField}
                        setFormData={setFormData}
                        handleClickOutside={handleClickOutside}
                    />
                }
                
                <div className='overflow-y-auto grow mt-[46px]'>

                    {/* Add Categories Buttons */}

                    <AddCategories 
                        addCategoryVisible={addCategoryVisible}
                        setAddCategoryVisible={setAddCategoryVisible}
                        setFormData={setFormData}
                        index={-1}
                    />


                    {formData?.categories?.map((category, i) => (
                        <div key={i} className='flex flex-col'>
                            <div className='w-1/1 mb-[2px] text-lg py-2 border-b bg-blue-300 text-center flex justify-between items-center relative'>

                                {/* Change Category Order */}
                            
                                <ChangeCategoryOrder 
                                    setFormData={setFormData}
                                    changeCategoryIndex={i}
                                    changeCategoriesVisible={changeCategoriesVisible}
                                />

                                {/* Category Name */}

                                <input className='w-[50%] lg:w-[30%] p-1 bg-blue-300 border border-black focus:outline-none rounded-lg text-center mx-auto' value={category.name} onChange={(e) => changeCategory(e, i)} />
                                
                                {/* Delete Category */}

                                <DeleteCategory 
                                    setFormData={setFormData}
                                    setDeletedRows={setDeletedRows}
                                    deleteCategoriesVisible={deleteCategoriesVisible}
                                    deleteCategoryIndex={i}
                                />
                            </div>

                            {/* Add Rows */}

                            <AddRows 
                                addRowVisible={addRowVisible}
                                addRow={addRow}
                                categoryIndex={i}
                                rowIndex={-1}

                            />

                            {category.fields.map((field, j) => (
                            <div key={j} className=''>
                                <div className=''>
                                    <div className='flex justify-between items-center border-b relative'>
                                        
                                        {/* Change Row Order */}
                                        
                                        <ChangeRowOrder 
                                            setFormData={setFormData}
                                            changeRowsVisible={changeRowsVisible}
                                            changeCategoryIndex={i}
                                            changeRowIndex={j}
                                        />

                                        {/* Field Selection for Formula */}

                                        {(field.id !== neededFieldException && category.type === "numbers") && 
                                            <div className={'pl-5 w-16 absolute' + (neededFieldVisible ? "" : " hidden")}>
                                                <button className='border border-2 border-blue-500 rounded-full p-2 hover:bg-blue-500 transition' onClick={() => selectRow(field.id)}>{/*<CheckCircleIcon className='w-6 h-6 text-red-400' />*/}</button>
                                            </div>
                                        }

                                        <div className='mx-auto w-[90%] mt-2 text-lg pt-2 pb-4 flex flex-col'>
                                            <div className='w-[100%] px-5 mx-auto '>
                                                
                                                {/* Topic Input */}
                                                
                                                <TopicInput 
                                                    topicCategoryIndex={i}
                                                    topicRowIndex={j}
                                                    topic={field.topic}
                                                    setFormData={setFormData}
                                                    setSavePossible={setSavePossible}
                                                />

                                                {/* Formula Input */}
                                              
                                                {category.type === "numbers" &&
                                                <>                                                              
                                                    <NumbersInput 
                                                        numbersCategoryIndex={i}
                                                        numbersRowIndex={j}
                                                        setFormData={setFormData}
                                                        formData={formData}
                                                        showSelectableRows={showSelectableRows}
                                                        numbersField={field}
                                                        neededFieldException={neededFieldException}
                                                        neededFieldVisible={neededFieldVisible}
                                                    /> 
                                                </>
                                                }
                                                
                                                {/* Description */}

                                                {(category.type !== "numbers" && category.type !== "individual-transactions") && 
                                                    <DescInput 
                                                        setFormData={setFormData}
                                                        desc={field.description} 
                                                        descCategoryIndex={i}
                                                        descRowIndex={j} 
                                                    />
                                                }
                                            </div>
                                        </div>

                                        {/* Delete Rows */}
                                        
                                        <DeleteRow 
                                            setFormData={setFormData}
                                            deleteCategoryIndex={i}
                                            deleteRowIndex={j}
                                            setDeletedRows={setDeletedRows}
                                            deleteRowsVisible={deleteRowsVisible}
                                        />
                                            
                                        <button onClick={(e) => handleShowAddProps(e, i, j, field)} className={'absolute right-1 mr-5 p-1 rounded-full border-2 border-blue-500 hover:bg-blue-500  hover:text-white transition-all ' + ((selectedCategoryI === i && selectedRowI === j) ? "bg-blue-500 text-white" : "text-blue-500")}>
                                            <PlusSmIcon className='w-5 h-5' />
                                        </button> 
    
                                    </div>
                                </div>
                                
                                {/* Add Rows */}

                                <AddRows 
                                    addRowVisible={addRowVisible}
                                    addRow={addRow}
                                    categoryIndex={i}
                                    rowIndex={j}
                                />

                            </div>
                            ))}

                            {/* Adding Categories */}

                            <AddCategories 
                                newCategoryIndex={i}
                                addCategoryVisible={addCategoryVisible}
                                setAddCategoryVisible={setAddCategoryVisible}
                                setFormData={setFormData}
                            />
                        </div>
                    ))}
                </div>
        </div>
        </>
    )
}

export default FormHolder