import { MinusSmIcon } from '@heroicons/react/outline'
import React from 'react'

const DeleteRow = React.memo(({ deleteCategoryIndex, deleteRowIndex, setFormData, setDeletedRows, deleteRowsVisible}) => {

    function deleteRow(categoryIndex, rowIndex) {
        setFormData(prev => {
            const updatedFormData = {... prev}

            const deleteId = updatedFormData.categories[categoryIndex].fields[rowIndex].id
    
            setDeletedRows(prevDeletedRows => [...prevDeletedRows, deleteId])
    
            updatedFormData.categories[categoryIndex].fields.splice(rowIndex, 1)
    
            return updatedFormData
        }) 
    }


    return (
        <div className={'inline h-1/1 margin-auto absolute left-1 md:left-5' + (deleteRowsVisible ? "" : " hidden")}>
            <div onClick={() => deleteRow(deleteCategoryIndex, deleteRowIndex)} className='m-1 w-max p-1.5 text-red-600 border-2 border-red-600 rounded-full cursor-pointer hover:bg-red-600 hover:text-white'>
                <MinusSmIcon className='w-5 h-5 ' />
            </div>
        </div>  
    )
})

export default DeleteRow