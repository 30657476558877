import React, { useEffect } from 'react'
import DeleteLocalFileTemplates from './DeleteLocalFileTemplates'
import UploadTemplate from './UploadTemplate'
import LocalFileClassification from './LocalFileClassification';
import DocumentClassification from './DocumentClassification';

function ManageLocalFileTemplates({ selectedCompany }) {

    /// Title Specification ///    

    useEffect(() => {
        document.title = 'Manage Local File Templates - Upload and Delete Local File Templates.'; 
    }, []);

    
    /// HTML Component ///

    return (
        <div className='h-[100%] font-body mx-auto flex flex-col justify-center items-center py-10'>

            <UploadTemplate selectedCompany={selectedCompany} />

            <div className='m-8'></div>  

            <DeleteLocalFileTemplates selectedCompany={selectedCompany} />

            <div className='m-8'></div>  

            <LocalFileClassification selectedCompany={selectedCompany} />
            
            <div className='m-8'></div>  

            <DocumentClassification selectedCompany={selectedCompany} />
        </div>
    )
}

export default ManageLocalFileTemplates