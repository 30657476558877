import { ClockIcon, InformationCircleIcon } from '@heroicons/react/outline'
import React from 'react'

function NotificationButton({ notification, convertToDate, handleSelectEmail }) {

    return (
        <button onClick={() => handleSelectEmail(notification)} className='w-[100%] p-3 hover:scale-[1.01] transition-all shadow-basic mt-2 rounded-lg flex items-center justify-between'>
            <div className='flex items-center w-1/2'>
                <span className='text-xs lg:text-sm'>{notification.data().user}</span>
            </div>


            <div className='flex items-center  cursor-pointer w-1/6'>
                {/* <InformationCircleIcon className='w-5 h-5 mr-1' />     */}
                <span className='text-xs'>{notification.data().year} / {notification.data().subsidiary}</span>
            </div>

            <div className='w-1/6 text-indigo-600 flex justify-center items-center'>
                <InformationCircleIcon className='w-5 h-5' />
            </div>

            <div className='flex items-center justify-end text-neutral-500 w-1/6'>
                <span className='text-xs '>
                    {convertToDate(notification.data().createdAt)}
                    {/* GMT -> UTC */}
                </span> 
                <ClockIcon className='w-4 h-4 ml-1 shrink-0' />
            </div>
        </button>
    )
}

export default NotificationButton