import { PlusSmIcon } from '@heroicons/react/outline'
import { collection, getDocs, limit, query, where } from 'firebase/firestore'
import React, { useState } from 'react'
import { db } from '../../firebase'

function AddUserButton({ setSelectedSubsidiaries, selectedCompany, emailYear, emailSubsidiary}) {

    const [additionalEmail, setAdditionalEmail] = useState("")
    const [selectedSuggestion, setSelectedSuggestion] = useState()
    const [addSuggestions, setAddSuggestions] = useState([])
    const [suggestionsVisible, setSuggestionsVisible] = useState(false) 

    function addUser(year, subsidiary, user) {
        setAdditionalEmail("")
        setSelectedSubsidiaries(prev => {
            console.log({...prev, [year] : {...prev[year], [subsidiary]: [ ...prev[year][subsidiary], user ]}} )
            return {...prev, [year] : {...prev[year], [subsidiary]: [ ...prev[year][subsidiary], user ]}} 
        })
    }

    async function handleAddEmailChange(e) {
        setAdditionalEmail(e.target.value)
        setSuggestionsVisible(true)
        
        const userQuery = query(collection(db, "user_data", selectedCompany.id, "users"), where("email", ">=", e.target.value), limit(3))
        const suggestedUsers = await getDocs(userQuery)
        console.log(suggestedUsers.docs)
        setAddSuggestions(suggestedUsers.docs)
    }

    function handleSelectSuggestion(suggestion) {
        setSelectedSuggestion(suggestion)
        setSuggestionsVisible(false)
        setAdditionalEmail(suggestion.data().email)
    }

    return (
        <div className=' text-white mt-2 rounded-lg shadow-basic flex justify-center items-center w-[100%]'>                                    
            <div className='w-1/2 relative'>
                <input value={additionalEmail} onChange={handleAddEmailChange} className='w-[100%] focus:outline-none bg-transparent px-2 text-black text-center text-xs lg:text-sm' placeholder='Add Email' />
                {(addSuggestions.length > 0 && suggestionsVisible) &&
                    <div className='absolute bg-white w-[100%] shadow-basic rounded-lg px-1 mt-2.5'>
                        {addSuggestions.map((suggestion, i) => (
                            <button onClick={() => handleSelectSuggestion(suggestion)} className='select-btn text-black hover:bg-blue-400 text-xs lg:text-sm '>{suggestion.data().email}</button>
                        ))}
                    </div>
                }
            </div>
            
            <button onClick={() => addUser(emailYear, emailSubsidiary, selectedSuggestion)} className='w-1/2 bg-green-400 hover:bg-green-500 transition-all p-2 flex justify-center items-center rounded-r-lg'>
                <PlusSmIcon className='w-[18px] h-[18px] lg:w-5 lg:h-5' />
            </button>
        </div>
    )
}

export default AddUserButton