import { TableIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { db } from '../../firebase'
import BigIdDropdown from '../../components/BigIdDropdown'

function CreateStatusCsv({ selectedCompany }) {

    const [years, setYears] = useState([])
    const [selectedYear, setSelectedYear] = useState()

    const [yearsVisible, setYearsVisible] = useState(false)

    const [csvData, setCsvData] = useState([])
    const [csvHeaders, setCsvHeaders] = useState([])


    /// Title Specification ///

    useEffect(() => {
        document.title = 'Export Status CSV - Get an Overview'; 
    }, []);


    /// Handling Data Selection /// 

    useEffect(() => {
        async function fetchYears() {
            const newYears = await getDocs(collection(db, "user_data", selectedCompany.id, "years")) 
            setYears(newYears.docs)
        }

        fetchYears()
    }, [selectedCompany])

    async function selectYear(year) {
        setSelectedYear(year)
        setYearsVisible(false)

        const formFrame = await getDoc(doc(db, "user_data", selectedCompany.id, "years", year.id, "forms", "standard_form"))
        const subsidiaries = await getDocs(query(collection(db, "user_data", selectedCompany.id, "years", year.id, "subsidaries")))
        const subsidiariesDocs = subsidiaries.docs

        const headers = [
            {label : "Document", key : "Document"}
        ]
        const data = []


        for (const subsidiaryDoc of subsidiariesDocs) {
            const row = {}

            row["Document"] = subsidiaryDoc.id

            for (const category of formFrame.data().categories) {
                for (const field of category.fields) {
                    const matchFields = subsidiaryDoc.data().fieldValues.filter(fieldValue => fieldValue.id === field.id) 
                    if (matchFields.length === 0) {
                        row[field.topic] = "!"
                    } else {
                        if (matchFields[0].done === true) {
                            row[field.topic] = "done"
                        } else {
                            row[field.topic] = "!"
                        }

                    }
                }
            }
            data.push(row)
        }

        for (const category of formFrame.data().categories) {
            for (const field of category.fields) {
                const row = {}

                row.label = field.topic
                row.key = field.topic

                headers.push(row)
            }
        }

        setCsvData(data)
        setCsvHeaders(headers)

    }

    /// HTML Component ///

    return (
        <div className='py-20'>
            <div className='rounded-lg bg-white drop-shadow-lg p-4 mb-10 font-body sm:w[80%] md:w-[70%] lg:w-[35%] xl:w[20%] mx-auto mt-40'>
                <div className='component-icon-container bg-sky-400'>
                    <TableIcon className='w-8 h-8' />
                </div>
                <p className='text-2xl p-1 m-1'>Export Status CSV</p>
                <div className='flex justify-between mb-2 mt-3 px-2'>
                    <BigIdDropdown 
                        width={"100%"}
                        color={"sky"}
                        options={years}
                        selectedOption={selectedYear}
                        dropdownVisible={yearsVisible}
                        setDropdownVisible={setYearsVisible}
                        placeholder={"Document Type"}
                        selectFunction={selectYear}
                    />
                </div>
                <div className='w-[100%] px-1 flex'>
                    <button className='w-[100%] text-center rounded-lg bg-sky-400 text-white p-2 mx-1 border border-sky-400 transition-all hover:shadow-md'>
                        <CSVLink separator={";"} data={csvData} headers={csvHeaders} filename={selectedCompany?.id + "_" + selectedYear?.id + "_Status" + ".csv"}>
                            Export CSV
                        </CSVLink>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CreateStatusCsv