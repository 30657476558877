import { DocumentDownloadIcon } from '@heroicons/react/outline'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db, generateIDRDocument, generateSerialLetter, shortify, useAuth } from '../../firebase'
import IdDropdown from '../../components/IdDropdown'
import NameDropdown from '../../components/NameDropdown'


function CreateDocument({ selectedCompany }) {

    const currentUser = useAuth()

    const [forms, setForms] = useState([])
    const [selectedForm, setSelectedForm] = useState()
    const [selectedSubsidiary, setSelectedSubsidiary] = useState()

    const [formsVisible, setFormsVisible] = useState(false)

    const [templatesVisible, setTemplatesVisible] = useState(false)
    const [templates, setTemplates] = useState([])
    const [selectedTemplate, setSelectedTemplate] = useState()


    /// Loading available Templates and Document Types ///

    useEffect(() => {
        async function fetchForms() {
            if (selectedCompany?.id) {
    
                const fetchedForms = await getDocs(collection(db, "user_data", selectedCompany?.id, "special_forms"))
                setForms(fetchedForms.docs)
            }

        }

        fetchForms()
    }, [selectedCompany])


    /// Document Type, Document and Template Selection ///

    async function selectForm(form) {
        setSelectedTemplate()
        setSelectedForm(form)

        setFormsVisible(false)

        const q = query(collection(db, "user_data", selectedCompany?.id, "special_templates"), where("relatedEntries", "array-contains", form.id))
        const newTemplates = await getDocs(q)
        setTemplates(newTemplates.docs)

        setTemplatesVisible(true)
    }

    async function selectTemplate(template) {
        setSelectedTemplate(template)
        setTemplatesVisible(false)
    }

    
    /// Generate Local File ///

    async function createLocalFile() {

        const documentData = {}

        const formFrame = selectedForm
        
        const entryData = await getDoc(doc(db, "user_data", selectedCompany.id, "special_entries", selectedForm.id))
        console.log(entryData.data())

        documentData["FY"] = selectedForm.id


        documentData["Status"] = "Final"

        for (const category of formFrame.data().categories.filter(category => category.type === "information")) {
            for (const field of category.fields) {
                const matchFields = entryData.data().fieldValues.filter(fieldValue => fieldValue.id === field.id) 
                if (matchFields.length === 0) {
                    documentData[field.topic] = ""
                    if (documentData["Status"] !== "Draft") {
                        documentData["Status"] = "Draft"
                    }
                } else {
                    documentData[field.topic] = matchFields[0].answer
                    
                    if (matchFields[0].done === false) {
                        if (documentData["Status"] !== "Draft") {
                            documentData["Status"] = "Draft"
                        }
                    }
                }
            }
        }

        for (const category of formFrame.data().categories.filter(category => (category.type === "standard-transactions" || category.type === "numbers"))) {
            for (const field of category.fields) {
                const matchFields = entryData.fieldValues.filter(fieldValue => fieldValue.id === field.id) 
                if (matchFields.length === 0) {
                    documentData[field.topic] = ""
                    if (documentData["Status"] !== "Draft") {
                        documentData["Status"] = "Draft"
                    }
                } else {
                    documentData[field.topic] = parseFloat(matchFields[0]?.transaction_amount.toString().replace(/,/g , '')).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"
                    
                    if (matchFields[0].done === false) {
                        if (documentData["Status"] !== "Draft") {
                            documentData["Status"] = "Draft"
                        }
                    }
                }
            }
        }

        // Replace this:
            // Where is the file going to be stored?

        await generateIDRDocument(selectedTemplate.data().url, documentData, selectedCompany.id, selectedForm.id)
    }

    
    /// HTML Component ///

    return (
        <div className='component-container w-[80%] lg:w-[55%] xl:w-[40%]'>
            <div className='component-icon-container bg-yellow-400'>
                <DocumentDownloadIcon className='w-8 h-8' />
            </div>
            <p className='text-2xl p-1 m-1'>Create Document</p>
            <div className='w-1/1 flex gap-x-1 mt-4'>
                <IdDropdown 
                    width={"50%"}
                    color={"yellow"}
                    options={forms}
                    selectFunction={selectForm}
                    selectedOption={selectedForm}
                    dropdownVisible={formsVisible}
                    setDropdownVisible={setFormsVisible}
                    placeholder={"Form"}
                />
                <NameDropdown 
                    width={"50%"}
                    color={"yellow"}
                    options={templates}
                    selectFunction={selectTemplate}
                    selectedOption={selectedTemplate}
                    dropdownVisible={templatesVisible}
                    setDropdownVisible={setTemplatesVisible}
                    placeholder={"Template"}
                />
            </div>

            <div className='w-[100%] px-1 mt-3'>
                <button className='w-[100%] text-center rounded-lg bg-yellow-400 text-white p-2 transition-all hover:shadow-md' onClick={createLocalFile}>Download</button>
            </div>
        </div>
    )
}

export default CreateDocument

