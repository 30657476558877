import { ChevronLeftIcon, ChevronRightIcon, DocumentDownloadIcon, ExclamationCircleIcon, ExclamationIcon, FolderIcon, FolderOpenIcon, PlusIcon, PlusSmIcon, TrashIcon } from "@heroicons/react/outline";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes, rename } from "firebase/storage";
import React, { useEffect, useState } from "react";
import { db, getClientStatus, getDateString, getIDRStatus, storage, useAuth, functions } from "../../firebase";
import AyncLink from "./AyncLink";
import { createPDF, pdfArrayToBlob, mergePDF, pdfArray } from "pdf-actions";
import { PDFDocument, rgb } from "pdf-lib";
import ThreeDotsWave from "./ThreeDotsWave";
import { addDoc, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import axios from "axios";
import FileButton from "./FileButton";
import { httpsCallable } from "firebase/functions";

function Files({ selectedCompany, superUser, masterUser }) {
  const [yearFolders, setYearFolders] = useState();
  const [folders, setFolders] = useState([]);

  const [subsidiaryFolders, setSubsidiaryFolders] = useState();
  const [subFolders, setSubFolders] = useState([]);

  const [subsidiaryContent, setSubsidiaryContent] = useState();
  const [subFolderContent, setSubFolderContent] = useState();

  const [addFolderVisible, setAddFolderVisible] = useState();

  const [newMainFolderName, setNewMainFolderName] = useState();
  const [newSubFolderName, setNewSubFolderName] = useState();

  const [subFile, setSubFile] = useState();
  const [subFileName, setSubFileName] = useState();

  const [addSubFolderVisible, setAddSubFolderVisible] = useState();

  const [newFileName, setNewFileName] = useState();
  const [file, setFile] = useState();

  const [selectedFolder, setSelectedFolder] = useState();
  const [selectedSubFolder, setSelectedSubFolder] = useState();

  const [uploadFormVisible, setUploadFormVisible] = useState();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState("Draft");

  const [deleteFolderVisible, setDeleteFolderVisible] = useState(false);
  const [deleteFolderRef, setDeleteFolderRef] = useState(false);
  const [deleteFolderName, setDeleteFolderName] = useState("");

  const [editFolderVisible, setEditFolderVisible] = useState(false);

  const currentUser = useAuth();

  const [editFolderName, setEditFolderName] = useState("");

  const [editFolder, setEditFolder] = useState();
  const [editSubfolder, setEditSubfolder] = useState();
  const [currentEditMode, setCurrentEditMode] = useState();

  async function saveEditFolderName() {
    if (currentEditMode === "folder") {
      await updateDoc(doc(db, "user_data", selectedCompany.id, "folders", editFolder.id), {
        name: editFolderName,
      });
      loadFolders();
    } else if (currentEditMode === "subfolder") {
      console.log(editFolder);
      console.log(editSubfolder);
      await updateDoc(doc(db, "user_data", selectedCompany.id, "folders", editFolder.id, "subfolders", editSubfolder.id), {
        name: editFolderName,
      });
      loadSubFolders(selectedFolder);
    }

    setEditFolderVisible(false);
  }

  async function deleteFolder() {
    await deleteDoc(deleteFolderRef);
    setDeleteFolderVisible(false);

    // Reload Folders to remove deleted Folder

    await loadFolders();
    await loadSubFolders(selectedFolder);
  }

  useEffect(() => {
    console.log(selectedFolder);

    if (selectedFolder) {
      console.log(selectedFolder.data());
    }
  }, [selectedFolder]);

  /// Title Specification ///

  useEffect(() => {
    document.title = "Files - Upload and Download Files";
  }, []);

  /// Loading available Main Folders ///

  async function loadFolders() {
    const newFolders = await getDocs(collection(db, "user_data", selectedCompany.id, "folders"));
    setFolders(newFolders.docs);
  }

  useEffect(() => {
    if (selectedCompany) {
      loadFolders();
    }
  }, [selectedCompany?.id]);

  /// Main Folder Selection ///

  async function loadSubFolders(folder) {
    setSelectedFolder(folder);

    if (!superUser && !masterUser) {
      const subfoldersRef = ref(collection(db, "user_data", selectedCompany.id, "folders", folder.id, "subfolders"));
      const condition = "roles." + currentUser.uid;
      // console.log(condition)

      const newSubFolders = await getDocs(query(subfoldersRef, where(condition, "==", "editor")));
      console.log(newSubFolders.docs);
      setSubFolders(newSubFolders.docs);
    } else {
      const newSubFolders = await getDocs(collection(db, "user_data", selectedCompany.id, "folders", folder.id, "subfolders"));
      console.log(newSubFolders.docs);
      setSubFolders(newSubFolders.docs);
    }
  }

  /// Sub Folder Selection ///

  async function loadSubFolderContent(subfolder) {
    setSelectedSubFolder(subfolder);

    const loadedFolder = await getDoc(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", subfolder.id));
    setSubFolderContent(loadedFolder.data().files);

    const subsidiaryData = await getDoc(doc(db, "user_data", selectedCompany.id, "years", selectedFolder.id, "subsidaries", subfolder.id));
    if (subsidiaryData.data()) {
      await getClientStatus(selectedCompany.id, selectedFolder.id, subfolder.id, subsidiaryData.data(), setStatus);
    }

    const entryData = await getDoc(doc(db, "user_data", selectedCompany.id, "special_entries", subfolder.id));
    if (entryData.data()) {
      await getIDRStatus(selectedCompany.id, subfolder.id, entryData.data(), setStatus);
    }
  }

  useEffect(() => {
    console.log(status);
  }, [status]);

  /// Reloading Sub Folder Content ///

  async function refreshContent() {
    const refreshFolder = await getDoc(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", selectedSubFolder.id));
    setSubFolderContent(refreshFolder.data().files);
  }

  /// Refreshing available Main- and Sub Folders

  async function refreshMainFolders() {
    const refreshFolders = await getDocs(collection(db, "user_data", selectedCompany.id, "folders"));
    setFolders(refreshFolders.docs);
  }

  async function refreshSubFolders() {
    const refreshSubfolders = await getDocs(collection(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders"));
    setSubFolders(refreshSubfolders.docs);
  }

  /// Uploading File ///

  async function uploadFile() {
    var finalFileName = newFileName;

    const fetchedDoc = await getDoc(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", selectedSubFolder.id));
    if (
      fetchedDoc
        .data()
        .files.map((file) => file.name)
        .includes(newFileName)
    ) {
      finalFileName += " (1)";
    }

    const path = selectedCompany.id + "/" + newFileName;
    const fileRef = ref(storage, path);
    const snapshot = await uploadBytes(fileRef, file);
    const fileURL = await getDownloadURL(fileRef);

    await updateDoc(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", selectedSubFolder.id), {
      files: arrayUnion({
        name: finalFileName,
        url: fileURL,
      }),
    });

    refreshContent();
    setUploadFormVisible(false);
  }

  /// Adding Main Folders ///

  async function addMainFolder() {
    if (newMainFolderName === "") {
      return;
    }

    setAddFolderVisible(false);

    // Add Folder to /files

    await addDoc(collection(db, "user_data", selectedCompany.id, "folders"), {
      name: newMainFolderName,
    });

    setSubFile();
    setSubFileName();

    refreshMainFolders();
  }

  /// Adding Sub Folders ///

  async function addSubFolder() {
    setAddSubFolderVisible(false);

    // Add Folder to /files

    await addDoc(collection(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders"), {
      name: newSubFolderName,
      files: [],
    });

    setSubFile();
    setSubFileName();

    refreshSubFolders();
    refreshContent();
  }

  /// Handling File Input ///

  function handleFileInput(e) {
    setNewFileName(e.target.files[0].name);
    setFile(e.target.files[0]);
  }

  function handleSubFileInput(e) {
    setSubFileName(e.target.files[0].name);
    setSubFile(e.target.files[0]);
  }

  /// Managing Selected List ///

  async function addToList(e, item) {
    if (e.target.checked) {
      setSelectedFiles([...selectedFiles, item]);
    } else {
      setSelectedFiles(selectedFiles.filter((selectedFile) => selectedFile.name !== item.name));
    }
  }

  /// Deleting Files ///

  async function deleteFile() {
    for (const file of selectedFiles) {
      // Remove from Storage
      // try {
      //     const path = "/" + selectedCompany.id + "/" + file.name
      //     const deleteRef = ref(storage, path)
      //     await deleteObject(deleteRef)
      // } catch {
      //     console.log("Cant be found using Method 1")
      // }

      // Remove from Firestore Database
      try {
        const path2 = "/" + selectedCompany.id + "/" + selectedFolder.id + "/" + selectedSubFolder.id + "/" + file.name;
        const deleteRef2 = ref(storage, path2);
        await deleteObject(deleteRef2);
      } catch {
        console.log("Cant be found using Method 2");
      }
    }

    const remainingFiles = subFolderContent.filter((file) => !selectedFiles.map((selectedFile) => selectedFile.name).includes(file.name));
    console.log(remainingFiles);
    await updateDoc(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", selectedSubFolder.id), {
      files: remainingFiles,
    });

    setSelectedFiles([]);
    refreshContent();
    refreshSubFolders();
    refreshMainFolders();
  }

  /// Converting .docx to .pdf and Merging ///

  function toArrayBuffer(buf) {
    const ab = new ArrayBuffer(buf.length);
    const view = new Uint8Array(ab);
    for (let i = 0; i < buf.length; ++i) {
      view[i] = buf[i];
    }
    return ab;
  }

  async function convertToPdfCustom() {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    const pdfArray = [];
    const convertToPdf = httpsCallable(functions, "convert_to_pdf");

    for (const file of selectedFiles) {
      // const path = "/" + selectedCompany.id + "/" + selectedFolder.data().name + "/" + selectedSubFolder.data().name + "/" + file
      console.log(file.url);

      if (file.name.split(".")[file.name.split(".").length - 1] === "pdf") {
        const response = await axios.get(file.url, { responseType: "arraybuffer" });
        const pdfDoc = await PDFDocument.load(response.data);
        pdfArray.push(pdfDoc);
        continue;
      }

      try {
        // http://localhost:4000/pdf-convert
        // await fetch('https://tax-mind-tools.de/convert-pdf', {
        //     method: 'POST',
        //     headers: {
        //       'Accept': 'application/json',
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({
        //       url: file.url,
        //     })
        //   })
        //   .then(res => res.json())
        //   .then(async (result) => {
        //     console.log(result.file)
        //     const arrayBufferFile = toArrayBuffer(result.file.data);
        //     console.log(arrayBufferFile)
        //     var bytes = new Uint8Array(arrayBufferFile);
        //     const pdfDoc = await PDFDocument.load(arrayBufferFile)
        //     pdfArray.push(pdfDoc)
        //   })

        const result = await convertToPdf({ url: file.url });
        const arrayBufferFile = Uint8Array.from(atob(result.data.file), (c) => c.charCodeAt(0)).buffer;
        const pdfDoc = await PDFDocument.load(arrayBufferFile);
        pdfArray.push(pdfDoc);
      } catch (error) {
        console.log("Files could not be converted.");
        setIsLoading(false);
      }
    }

    pdfArray.forEach(async (file) => await createPDF.PDFDocumentFromFile(file));
    const mergedPDFDocument = await mergePDF(pdfArray);
    const mergedPdfFile = await mergedPDFDocument.save();
    const pdfBlob = pdfArrayToBlob(mergedPdfFile);

    const url = window.URL.createObjectURL(pdfBlob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", selectedFiles[0].name.replace(".docx", ".pdf"));
    document.body.appendChild(link);
    link.click();

    const currentDate = getDateString();

    console.log(selectedFiles[0]);

    const path = selectedCompany.id + selectedFiles[0].name.replace(".docx", ".pdf");
    const fileRef = ref(storage, path);
    const snapshot = await uploadBytes(fileRef, pdfBlob);
    const fileURL = await getDownloadURL(fileRef);

    await updateDoc(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", selectedSubFolder.id), {
      files: arrayUnion({ name: selectedFiles[0].name.replace(".docx", ".pdf"), url: fileURL }),
    });

    setSubFolderContent((prev) => [...prev, { name: selectedFiles[0].name.replace(".docx", ".pdf"), url: fileURL }]);

    setIsLoading(false);
  }

  /// Check if Folder is Document Type (Numeric) ///

  function isNumeric(value) {
    return /^-?\d+$/.test(value);
  }

  useEffect(() => {
    setSelectedSubFolder();
  }, [selectedFolder]);

  /// HTML Component ///

  return (
    <>
      <div
        onClick={() => setUploadFormVisible((prev) => !prev)}
        className={"w-[100%] h-screen bg-slate-800/50 absolute z-10 flex items-center justify-center font-body " + (uploadFormVisible ? "" : "hidden")}
      ></div>
      <div
        onClick={() => setAddFolderVisible((prev) => !prev)}
        className={"w-[100%] h-screen bg-slate-800/50 absolute z-10 flex items-center justify-center font-body " + (addFolderVisible ? "" : "hidden")}
      ></div>
      <div
        onClick={() => setAddSubFolderVisible((prev) => !prev)}
        className={"w-[100%] h-screen bg-slate-800/50 absolute z-10 flex items-center justify-center font-body " + (addSubFolderVisible ? "" : "hidden")}
      ></div>
      <div
        onClick={() => setDeleteFolderVisible((prev) => !prev)}
        className={"w-[100%] h-screen bg-slate-800/50 absolute z-10 flex items-center justify-center font-body " + (deleteFolderVisible ? "" : "hidden")}
      ></div>
      <div
        onClick={() => setEditFolderVisible((prev) => !prev)}
        className={"w-[100%] h-screen bg-slate-800/50 absolute z-10 flex items-center justify-center font-body " + (editFolderVisible ? "" : "hidden")}
      ></div>

      {/* Adding File */}

      <div
        className={
          "rounded-xl shadow-xl bg-white w-[80%] sm:w-[60%] md:w-[50%] lg:w-[32rem] font-body absolute z-50 left-0 right-0 top-0 bottom-40 m-auto h-max" + (uploadFormVisible ? "" : " hidden")
        }
      >
        <div className="border-b text-center text-2xl p-1.5">Upload</div>
        <div className="p-5">
          <div className="flex items-center mb-1">
            <div className="flex flex-col mb-1 w-[100%]">
              <p className="text-sm px-1 text-neutral-700">File Name:</p>
              <div className="flex w-[100%]">
                <input
                  value={newFileName}
                  onChange={(e) => setNewFileName(e.target.value)}
                  className="focus:outline-none border rounded-lg text-lg text-neutral-600 p-1 shrink grow min-w-0 w-[100%]"
                  placeholder="e.g. Annual Report.pdf"
                />
                <label for="fileinput" className="cursor-pointer text-blue-400 rounded-lg p-1 border border-blue-400 hover:bg-blue-400 hover:text-white transition-all ml-1.5">
                  <FolderOpenIcon className="w-7 h-7 inline" />
                </label>
                <input onChange={(e) => handleFileInput(e)} id="fileinput" className="hidden" type="file" />
              </div>
            </div>
          </div>
          <button onClick={() => uploadFile()} className="w-[100%] bg-blue-400 rounded-lg text-center text-white p-1.5 text-lg hover:bg-blue-500 transition-all">
            Upload
          </button>
        </div>
      </div>

      {/* Adding Main Folder */}

      <div
        className={"rounded-xl shadow-xl bg-white w-[80%] sm:w-[60%] md:w-[50%] lg:w-[32rem] font-body absolute z-50 right-0 left-0 top-0 bottom-40 m-auto h-max" + (addFolderVisible ? "" : " hidden")}
      >
        <div className="border-b text-center text-2xl p-1.5">Add Folder</div>
        <div className="p-5 pt-4">
          <div className="flex flex-col mb-1">
            <p className="text-sm px-1 text-neutral-700">Main Folder Name:</p>
            <input
              value={newMainFolderName}
              onChange={(e) => setNewMainFolderName(e.target.value)}
              className="focus:outline-none border rounded-lg text-lg text-neutral-600 p-1 shrink grow min-w-0 w-[100%]"
              placeholder="e.g. Transfer Pricing"
            />
          </div>
          <button onClick={() => addMainFolder()} className="w-[100%] bg-blue-400 rounded-lg text-center text-white p-1 text-lg mt-1 hover:bg-blue-500 transition-all">
            Add
          </button>
        </div>
      </div>

      {/* Adding Subfolder */}

      <div
        className={
          "rounded-xl shadow-xl bg-white w-[80%] sm:w-[60%] md:w-[50%] lg:w-[32rem] font-body absolute z-50 right-0 left-0 top-0 bottom-40 m-auto h-max" + (addSubFolderVisible ? "" : " hidden")
        }
      >
        <div className="border-b text-center text-2xl p-1.5">Add Folder</div>
        <div className="p-5 pt-4">
          <div className="flex flex-col mb-1">
            <p className="text-sm px-1 text-neutral-700">Sub Folder Name:</p>
            <input
              value={newSubFolderName}
              onChange={(e) => setNewSubFolderName(e.target.value)}
              className="focus:outline-none border rounded-lg text-lg text-neutral-600 p-1 shrink grow min-w-0 w-[100%]"
              placeholder="e.g. TF Analysis"
            />
          </div>
          <button onClick={() => addSubFolder()} className="w-[100%] bg-blue-400 rounded-lg text-center text-white p-1.5 mt-1 text-lg hover:bg-blue-500 transition-all">
            Add
          </button>
        </div>
      </div>

      {/* Delete Folder */}

      <div
        className={
          "rounded-xl shadow-xl bg-white w-[80%] sm:w-[60%] md:w-[50%] lg:w-[32rem] font-body absolute z-50 right-0 left-0 top-0 bottom-40 m-auto h-max" + (deleteFolderVisible ? "" : " hidden")
        }
      >
        {/* <div className='border-b text-center text-2xl p-1.5'>Delete Folder</div> */}
        <div className="text-red-500 mt-6">
          <ExclamationCircleIcon className="w-12 h-12 mx-auto" />
        </div>
        <div className="px-10 pt-5 pb-3 flex flex-col items-center ">
          <p className="text-center">
            Are you sure you want to delete folder <b className="underline cursor-pointer">{deleteFolderName}</b> with all of its files and subfolders?
          </p>
        </div>
        <div className="flex justify-between py-3 px-4">
          <button onClick={() => setDeleteFolderVisible((prev) => !prev)} className="text-indigo-500 hover:text-indigo-600 p-1.5">
            Cancel
          </button>
          <button onClick={deleteFolder} className="text-red-500 hover:text-red-600 transition-all p-1.5">
            Delete
          </button>
        </div>
      </div>

      {/* Edit Folder */}

      <div
        className={
          "rounded-xl shadow-xl bg-white w-[80%] sm:w-[60%] md:w-[50%] lg:w-[32rem] font-body absolute z-50 right-0 left-0 top-0 bottom-40 m-auto h-max" + (editFolderVisible ? "" : " hidden")
        }
      >
        <div className="border-b text-center text-xl p-1.5">Edit Folder Name</div>
        <div className="p-4">
          <div className="flex flex-col mb-1">
            <p className="text-sm px-1 text-neutral-700">New Folder Name:</p>
            <input
              value={editFolderName}
              onChange={(e) => setEditFolderName(e.target.value)}
              className="focus:outline-none border rounded-lg text-base text-neutral-600 p-1 shrink grow min-w-0 w-[100%]"
              placeholder="e.g. TF Analysis"
            />
          </div>
          <button onClick={() => saveEditFolderName()} className="w-[100%] bg-blue-400 rounded-lg text-center text-white p-1.5 mt-1 text-base hover:bg-blue-500 transition-all">
            Save
          </button>
        </div>
      </div>

      {/* Filesystem */}

      <div className="font-body bg-white shadow-xl flex flex-col h-screen -mt-[65px] relative">
        <div className="h-[65px] shrink-0 grow-0 w-[100%] "></div>
        <div className="flex items-center px-4 py-3 bg-neutral-50 border-b">
          <div className="p-1.5 rounded-full bg-teal-400 text-white w-max">
            <FolderOpenIcon className="w-8 h-8" />
          </div>
          <div>
            <span className="text-2xl p-1 ml-3">Files</span>
          </div>
        </div>

        <div className="flex h-[100%] overflow-y-auto">
          <div className="min-w-[200px] md:w-[17.5%] h-[100%] p-0.5 px-3 border-r relative pt-6 bg-neutral-50">
            <div className="p-1 w-[100%] text-xl rounded-lg mt-1 mb-3 ">Main Folder</div>
            <div className=" relative">
              {folders?.map((folder, index) => (
                <FileButton
                  index={index}
                  folder={folder}
                  selectedFolder={selectedFolder}
                  loadSubFolders={loadSubFolders}
                  setDeleteFolderVisible={setDeleteFolderVisible}
                  setEditFolderVisible={setEditFolderVisible}
                  setDeleteFolderName={() => setDeleteFolderName(folder.data().name)}
                  masterUser={masterUser}
                  setDeleteFolderRef={() => setDeleteFolderRef(doc(db, "user_data", selectedCompany.id, "folders", folder.id))}
                  editFunction={() => {
                    setEditFolder(folder);
                    setCurrentEditMode("folder");
                    setEditFolderName(folder.data().name);
                  }}
                />
              ))}
              <button
                onClick={() => setAddFolderVisible((prev) => !prev)}
                className={"p-1.5 w-[100%] rounded-lg my-2 flex items-center justify-center text-neutral-400 hover:bg-blue-500 hover:text-white transition-all"}
              >
                <PlusIcon className="w-5 h-5" />
              </button>
            </div>
          </div>
          <div className="min-w-[200px] w-[22.5%] h-[100%] p-0.5 px-3 flex flex-col border-r pt-6 ">
            <div className="p-1 w-[100%] text-xl rounded-lg mt-1 mb-3">Sub Folder</div>
            <div className={"w-[100%] grow-0" + (subFolders.length > 15 ? " overflow-y-auto" : " overflow-visible")}>
              {subFolders?.map((subFolder, index) => (
                <FileButton
                  index={index}
                  folder={subFolder}
                  selectedFolder={selectedFolder}
                  loadSubFolders={() => loadSubFolderContent(subFolder)}
                  setDeleteFolderVisible={setDeleteFolderVisible}
                  setEditFolderVisible={setEditFolderVisible}
                  setDeleteFolderName={() => setDeleteFolderName(selectedFolder.data().name + "/" + subFolder.data().name)}
                  masterUser={masterUser}
                  setDeleteFolderRef={() => setDeleteFolderRef(doc(db, "user_data", selectedCompany.id, "folders", selectedFolder.id, "subfolders", subFolder.id))}
                  editFunction={() => {
                    setEditFolder(selectedFolder);
                    setEditSubfolder(subFolder);
                    setCurrentEditMode("subfolder");
                    setEditFolderName(subFolder.data().name);
                  }}
                />
                // <button key={index} onClick={() => loadSubFolderContent(subFolder)} className={'px-1 py-2 w-[100%] hover:bg-blue-400 rounded-lg hover:text-white my-1 ' + (subFolder.id === selectedSubFolder?.id ? " bg-blue-400 text-white" : "")}>{subFolder?.data()?.name}</button>
              ))}
            </div>
            {selectedFolder?.data()?.name && !isNumeric(selectedFolder?.data()?.name) && (
              <button
                onClick={() => setAddSubFolderVisible((prev) => !prev)}
                className={"p-1.5 w-[100%] rounded-lg my-2 flex items-center justify-center text-neutral-400 hover:bg-blue-500 hover:text-white transition-all"}
              >
                <PlusIcon className="w-5 h-5" />
              </button>
            )}
          </div>
          <div className="w-[60%] h-[100%] p-0.5 px-2 flex flex-col">
            <div className="px-1 py-2 w-[100%] bg-blue-500 text-white text-center rounded-lg my-1 relative flex items-center justify-center">
              {selectedFiles.length > 0 && (
                <div className="absolute left-1 flex items-center">
                  <button onClick={deleteFile} className="rounded-full p-1.5 bg-red-500 text-white hover:scale-105 transition-all">
                    <TrashIcon className="w-5 h-5" />
                  </button>
                  <button onClick={() => convertToPdfCustom()} className="text-sm rounded-full py-1.5 px-4 bg-blue-700 text-white hover:scale-105 transition-all text-center ml-2 flex ">
                    {!isLoading && "PDF"}
                    {isLoading && <ThreeDotsWave />}
                  </button>
                </div>
              )}

              {selectedSubFolder?.data()?.name ? selectedSubFolder?.data()?.name : "Files"}

              {selectedSubFolder?.data()?.name && (
                <div className="flex items-center absolute right-1">
                  <span
                    className={
                      "mx-2 px-2 py-1 text-sm " +
                      (status === "Final" ? "bg-[#82f240] text-white rounded-full transition-all" : "bg-[#ff0000] text-white rounded-full hover:scale-[1.025] transition-all") +
                      (!isNumeric(selectedFolder?.data()?.name) && selectedFolder?.data()?.name !== "IDR" ? " hidden" : "")
                    }
                  >
                    Form Status: {status}
                  </span>
                  <button onClick={() => setUploadFormVisible(!uploadFormVisible)} className="rounded-full p-1 bg-blue-800 text-white hover:scale-105 transition-all">
                    <PlusSmIcon className="w-5 h-5" />
                  </button>
                </div>
              )}
            </div>
            <div className=" overflow-auto">
              {subFolderContent?.map((file, index) => (
                <button key={index} className="relative px-1 py-2 w-[100%] hover:bg-blue-400 rounded-lg hover:text-white my-1 flex items-center ">
                  <input onChange={(e) => addToList(e, file)} className="w-4 h-4 mx-1.5 text-red-500 shrink-0" type="checkbox" />
                  <div className="grow overflow-hidden">
                    <span className="text-center">{file.name}</span>
                  </div>
                  <a href={file.url} className="inline ml-auto">
                    <DocumentDownloadIcon className="w-6 h-6 inline hover:scale-125 transition-all" />
                  </a>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Files;
