import { CogIcon, TrashIcon } from '@heroicons/react/outline'
import { collection, deleteField, doc, getDoc, getDocs, onSnapshot, query, updateDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { db } from '../../firebase'
import NameDropdown from '../../components/NameDropdown'

function DocumentClassification({ selectedCompany }) {

    const [templatesVisible, setTemplatesVisible] = useState()
    const [yearsVisible, setYearsVisible] = useState()

    const [years, setYears] = useState()
    const [subsidiaries, setSubsidiaries] = useState()

    const [templates, setTemplates] = useState([])
    const [entries, setEntries] = useState([])

    const [selectedYear, setSelectedYear] = useState()
    const [selectedTemplate, setSelectedTemplate] = useState()

    const [activeTemplate, setActiveTemplate] = useState()

    const [groups, setGroups] = useState([])


    /// Loading available Document Types and Templates ///

    useEffect(() => {
        async function fetchTemplatesAndEntries() {
            const unsub = onSnapshot(query(collection(db, "user_data", selectedCompany.id, "special_templates")), (newTemplates) => {
                setTemplates(newTemplates.docs)
            })

           const fetchedEntries = await getDocs(collection(db, "user_data", selectedCompany.id, "special_entries"))
           setEntries(fetchedEntries.docs)
        }   

        fetchTemplatesAndEntries()
    }, [selectedCompany])


    /// Template Selection ///

    function selectTemplate(template) {
        setActiveTemplate(template.data())
        setSelectedTemplate(template)
        setTemplatesVisible(false)
    }


    const [counter, setCounter] = useState(0)

    /// Changing Classification /// 

    function changeClassification(entry) {
        setActiveTemplate(prev => {
            const updatedTemplate = { ...prev };
            const { relatedEntries } = updatedTemplate;
        
            if (relatedEntries?.includes(entry.id)) {
                updatedTemplate.relatedEntries = relatedEntries.filter(entryId => entryId !== entry.id);
            } else {
                updatedTemplate.relatedEntries = [...relatedEntries, entry.id];
            }
        
            return updatedTemplate;
        });
    } 


    /// Changing Language Classification for All Documents ///

    function changeAllClassification() {
        const updatedTemplate = {... activeTemplate}
        
        const all = entries.map(entry => entry.id)

        const check = all.every(entryId => updatedTemplate?.relatedEntries.includes(entryId))
        if (check) {
            updatedTemplate.relatedEntries = updatedTemplate.relatedEntries.filter(entryId => !all.includes(entryId)) 
        } else {
            for (const entryId of all) {
                if (!updatedTemplate?.relatedEntries.includes(entryId)) {
                    updatedTemplate.relatedEntries.push(entryId)
                }
            }
        }
        setActiveTemplate(updatedTemplate)        
    }



    /// Saving Template Classification ///

    // Speichern wäre auch über query-Abfrage mit WHERE möglich (vlt)
    async function saveTemplateClassification() {
        await updateDoc(doc(db, "user_data", selectedCompany.id, "special_templates", selectedTemplate.id), {
            relatedEntries: activeTemplate.relatedEntries
        })

        const newTemplates = await getDocs(collection(db, "user_data", selectedCompany.id, "special_templates"))
        setTemplates(newTemplates.docs)
    }


    /// HTML Component ///

    return (
        <div className='component-container w-[80%] lg:w-[55%] xl:w-[40%] flex flex-col justify-between '>
            <div className=''>
                <div className='component-icon-container bg-purple-400'>
                    <CogIcon className='w-8 h-8' />
                </div>
                <p className='text-2xl p-1 m-1'>Manage IDR Classification</p>
                <div className='mt-3'>
                    <div className='flex px-2'>  
                        <NameDropdown 
                            width={"100%"}
                            color={"purple"}
                            placeholder={"Topic Template"}
                            options={templates}
                            dropdownVisible={templatesVisible}
                            setDropdownVisible={setTemplatesVisible}
                            selectFunction={selectTemplate}
                            selectedOption={selectedTemplate}
                        />
                    </div>
                    <div className='w-[100%] mt-2 px-2'>
                        <div className={'border rounded-lg p-2 flex justify-center flex-wrap' + (activeTemplate ? "" : " hidden")}>
                            <button className={'auth-button ' + (entries?.map(subsidiary => subsidiary.id).every(subsidiaryId => activeTemplate?.relatedEntries?.includes(subsidiaryId)) ? "bg-green-400" : "bg-red-400")} onClick={() => changeAllClassification()}>All</button>

                            {entries?.map((entry, index) => (
                                <button className={'auth-button ' + (activeTemplate?.relatedEntries?.includes(entry.id) ? "bg-green-400" : "bg-red-400")} onClick={() => changeClassification(entry)}>{entry.id}</button>                            
                            ))}
                        </div>

                    </div>
                </div>
            </div>
            <div className='mt-4 mx-1'>
                <button className='w-[100%] p-2 bg-purple-400 text-white rounded-lg hover:shadow-md transition-all' onClick={saveTemplateClassification}>Save</button>
            </div>
        </div>                   
    )
}

export default DocumentClassification